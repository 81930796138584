<template>
  <div class="admin-incidents-management">
    <!-- Toolbar -->
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      </v-btn>

      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Admin Incidents Management' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <Loader :loading="showLoader" />

    <v-container fluid>
      <!-- Search and Expand Button -->
      <v-row align="center" class="mb-2">
        <v-col cols="auto">
          <v-btn icon @click="menu = !menu" color="primary">
            <v-icon>{{ menu ? 'mdi-menu-open' : 'mdi-menu' }}</v-icon>
          </v-btn>
        </v-col>

        <v-col>
          <v-text-field v-model="searchQuery" label="Search" variant="outlined" prepend-inner-icon="mdi-magnify" dense
            class="mx-4"></v-text-field>
        </v-col>
      </v-row>

      <!-- Expandable Filters Section -->
      <v-expand-transition>
        <v-row dense v-if="menu" class="mt-2">
          <v-col cols="12" sm="6" md="3">
            <v-select v-model="selectedDateRange" :items="dateOptions" label="Date Range" solo dense
              prepend-inner-icon="mdi-calendar-range" @change="applyFilters"></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select v-model="selectedCategory" :items="categories" item-value="id" item-title="name"
              label="Select Category" placeholder="Categories" solo dense prepend-inner-icon="mdi-filter-variant"
              @change="applyFilters"></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select v-model="selectedFloor" :items="floors" item-value="id" item-title="name" label="Select Floor"
              solo dense prepend-inner-icon="mdi-elevator" @change="applyFilters"></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select v-model="selectedLocation" :items="locations" item-value="id" item-title="name"
              label="Select Location" solo dense prepend-inner-icon="mdi-map-marker" @change="applyFilters"></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Incidents Display Component -->
      <AdminIncidents :incidents="searchResults" :clickHandler="handleIncidentClick" />

      <!-- Edit Incident Dialog -->
      <v-dialog v-model="createDialog" max-width="600px">
        <v-card>
          <v-card-title>Edit Incident</v-card-title>
          <v-card-subtitle>Manage and update incident details.</v-card-subtitle>
          <v-card-text>
            <v-form>
              <v-text-field variant="underlined" label="Location" prepend-icon="mdi-map-marker" readonly
                @click="openMap" v-model="locationText" />
              <v-select variant="underlined" label="Category" :items="categories"
                prepend-icon="mdi-format-list-bulleted" v-model="SELECTED_CATEGORY" />
              <v-textarea variant="underlined" label="Description" prepend-icon="mdi-text" v-model="description"
                required />

              <v-select v-model="status" :items="statusOptions" item-title="text" item-value="value" return-object
                variant="underlined" prepend-icon="mdi-checkbox-marked-circle-outline" label="Status" class="mb-4"
                ref="selectStatusRef">
                >
                <!-- Selected item chip display -->
                <template v-slot:selection="{ item }">
                  <v-chip
                    :color="item?.value === 'open' ? 'red lighten-2' : item?.value === 'pending' ? 'orange lighten-2' : 'green lighten-4'"
                    label style="font-weight: 500; padding: 0 8px; color: #333">
                    {{ item?.value === 'open' ? 'Open' : item?.value === 'pending' ? 'In-Progress' : 'Done' }}
                  </v-chip>
                </template>

                <!-- Dropdown items with colored chips and hover effect -->
                <template v-slot:item="{ item, attrs }">
                  <v-chip
                    :color="item.value === 'open' ? 'red lighten-2' : item.value === 'pending' ? 'orange lighten-2' : 'green lighten-4'"
                    v-bind="attrs" @click.stop="selectItem(item)" class="ma-1" label dark style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      font-weight: 500;
                      color: black;
                      cursor: pointer;
                      transition: all 0.3s ease;
                      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    " @mouseover="e => (e.currentTarget.style.boxShadow = '0px 5px 10px rgba(0, 0, 0, 0.2)')"
                    @mouseleave="e => (e.currentTarget.style.boxShadow = '0px 3px 6px rgba(0, 0, 0, 0.16)')">
                    {{ item.value === 'open' ? 'Open' : item.value === 'pending' ? 'In-Progress' : 'Done' }}
                  </v-chip>
                </template>
              </v-select>

              <v-file-input counter show-size variant="underlined" v-model="imageFiles" label="Upload Image"
                prepend-icon="mdi-camera" multiple accept="image/png, image/jpeg">
                <template v-slot:selection="{ fileNames }">
                  <template v-for="fileName in fileNames" :key="fileName">
                    <v-chip class="me-2" color="primary" size="small" label>
                      {{ fileName?.substring(0, 30) }}
                    </v-chip>
                  </template>
                </template>
              </v-file-input>
              <v-row v-if="imageFiles.length" class="mt-3">
                <v-col v-for="(file, index) in imageFiles" :key="index" cols="4"
                  class="d-flex align-center justify-center">
                  <v-img :src="getImageURL(file)" alt="Image preview" max-height="100" max-width="100"
                    class="thumbnail-image" contain />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="cancelIncident">Cancel</v-btn>
            <v-btn variant="tonal" color="red" @click="deleteIncident">Delete</v-btn>
            <v-btn color="primary" :disabled="!isFormModified" @click="editIncident">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Map Dialog -->
    <MapView ref="mapView" :dragMarker="true" @onPositionSelected="setLocation" />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import Swal from 'sweetalert2'
import Loader from '@/components/general/Loader.vue'
import MapView from '@/components/general/IncidentsMapView.vue'
import { getStoredItem } from '@/services/utils.js'

import AdminIncidents from '@/components/general/ChildComponents/AdminIncidents.vue'
import {
  getAllIncidents,
  getIncidentCategories,
  getFloors,
  getLocations,
  getIncidentById,
  updateIncidentById,
  getBuildings,
  deleteIncidentsFull,
  deleteIncidentById,
  moveIncidentsToCategory,
  getFileInfo,
  getFile,
  getPoisByCategoryId,
  getPoiCategories,
  deleteIncidentFiles,
} from '@/controllers/incidentsAxiosBypasser'
import { isStaingOrQa, postMultipleFiles, linkIncidentToImage } from '@/controllers/BaseController'

import moment from 'moment'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default {
  name: 'AdminIncidentsManagement',
  components: {
    Loader,
    AdminIncidents,
    MapView,
  },
  setup() {
    const logo = ref('')
    const mapView = ref(null)
    const showLoader = ref(true)
    const incidents = ref([])
    const searchQuery = ref('')
    const selectedDateRange = ref('This Month')
    const selectStatusRef = ref(null)
    const selectedCategory = ref(0)
    const selectedFloor = ref(0)
    const selectedLocation = ref(0)
    const categories = ref([])
    const floors = ref([])
    const locations = ref([])
    const menu = ref(false)

    const createDialog = ref(false)
    const locationText = ref('')
    const location = ref(null)
    const SELECTED_CATEGORY = ref(null)
    const SELECTED_FLOOR = ref(null)
    const SELECTED_BUILDING = ref(null)
    const description = ref('')
    const selectedIncident = ref(null)
    const status = ref('open')
    const statusOptions = [
      { text: 'Open', value: 'open' },
      { text: 'In Progress', value: 'pending' },
      { text: 'Done', value: 'done' },
    ]
    const imageFiles = ref([])
    const originalValues = ref({})
    const router = useRouter()

    function selectItem(item) {
      status.value = item // Update the v-model
      selectStatusRef.value.blur() // Close the dropdown by blurring the component
    }

    const getImageURL = file => {
      if (file instanceof File) {
        return URL.createObjectURL(file)
      }
      return null
    }
    const canSubmit = computed(() => {
      return locationText.value && SELECTED_CATEGORY.value && description.value && status.value
    })

    const dateOptions = ['Today', 'This Week', 'This Month']
    const dates = ref({ start: moment().startOf('day').toDate(), end: moment().endOf('day').toDate() })

    // Computed properties to detect changes
    const isLocationTextChanged = computed(() => originalValues.value.locationText !== locationText.value)
    const isCategoryChanged = computed(() => originalValues.value.SELECTED_CATEGORY !== SELECTED_CATEGORY.value)
    const isDescriptionChanged = computed(() => originalValues.value.description !== description.value)
    const areImagesChanged = () => {
      if (originalValues.value.imageFiles.length !== imageFiles.value.length) {
        return true
      }
      return !originalValues.value.imageFiles.every((file, index) => {
        return file.name === imageFiles.value[index].name && file.size === imageFiles.value[index].size
      })
    }

    const isFormModified = computed(() => {
      return (
        isLocationTextChanged.value ||
        isCategoryChanged.value ||
        isDescriptionChanged.value ||
        areImagesChanged() ||
        status.value !== selectedIncident.value.state
      )
    })
    const openMap = async () => {
      try {
        showLoader.value = true
        let CATEGORIES = await getPoiCategories()
        let POIS = []
        for (let category of CATEGORIES) {
          let categoryPois = await getPoisByCategoryId(category.id)
          Array.prototype.push.apply(POIS, categoryPois)
        }
        let selectedLocation = JSON.parse(JSON.stringify(location.value))
        if (location.value) {
          mapView.value.openMap(SELECTED_BUILDING.value, POIS, CATEGORIES, selectedLocation)
        } else {
          mapView.value.openMap(SELECTED_BUILDING.value, POIS, CATEGORIES)
        }
      } catch (error) {
        showLoader.value = false
        console.error('Error opening map:', error)
      } finally {
        showLoader.value = false
      }
    }

    const fetchInitialData = async () => {
      Swal.fire({
        title: 'Loading...',
        text: 'Fetching incidents data',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        const [incidentsList, isPIA, categoryList, floorList, buildings, locationList] = await Promise.all([
          getAllIncidents(),
          isStaingOrQa(),
          getIncidentCategories(),
          getFloors(),
          getBuildings(),
          getLocations(),
        ])
        const selectedBuildingName = isPIA ? buildings.find(b => b.name === 'Teichhaus')?.name : getStoredItem('building')
        const selectedBuilding = buildings.find(b => b.name === selectedBuildingName)
        SELECTED_BUILDING.value = selectedBuilding

        incidents.value = incidentsList
        categories.value.push(...categoryList)
        floors.value.push(...floorList)
        locations.value.push(...locationList)

        applyFilters()
      } catch (error) {
        console.error('Error loading initial data:', error)
      } finally {
        showLoader.value = false
        Swal.close()
      }
    }

    const handleIncidentClick = async incident => {
      try {
        incident = JSON.parse(JSON.stringify(incident))
        console.log(incident.location)
        selectedIncident.value = incident
        locationText.value = incident.location.locationName
        location.value = { lat: incident.location.latitude, long: incident.location.longitude }
        SELECTED_CATEGORY.value = selectedIncident.value.incidentCategoryName
        description.value = selectedIncident.value.description
        status.value = incident.state

        // Fetch images if any exist
        if (selectedIncident.value.files?.entities) {
          const imageFileIds = selectedIncident.value.files.entities.filter(file => file.type === 'image')
          imageFiles.value = await Promise.all(
            imageFileIds.map(async file => {
              const fileBlob = await getFile(file.id)
              const fileInfo = await getFileInfo(file.id)
              return new File([fileBlob], fileInfo.fileName, { type: fileInfo.mimeType })
            })
          )
        }

        // Store original values for comparison
        originalValues.value = {
          locationText: locationText.value,
          location: location.value,
          SELECTED_CATEGORY: SELECTED_CATEGORY.value,
          description: description.value,
          imageFiles: [...imageFiles.value], // Clone image files array
        }

        createDialog.value = true
      } catch (error) {
        console.error('Error loading incident:', error)
      }
    }

    const resizeImage = (file, maxWidth = 750, maxHeight = 498) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          const canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          }
          if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }

          canvas.width = width
          canvas.height = height

          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          canvas.toBlob(blob => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type })) // Pass original file name
            } else {
              reject(new Error('Image resize failed.'))
            }
          }, 'image/png')
        }
        img.onerror = () => reject(new Error('Image load failed.'))
      })
    }

    const deleteIncident = async () => {
      const incidentId = selectedIncident.value?.id

      if (!incidentId) return

      createDialog.value = false

      // Confirmation dialog with a description of the incident
      const confirmation = await Swal.fire({
        title: 'Delete Incident',
        text: `Are you sure you want to delete the incident: "${selectedIncident.value.description}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d2f8d2',
        cancelButtonColor: '#fbe5e3',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
      })

      // If the user cancels, reopen the dialog and exit
      if (!confirmation.isConfirmed) {
        createDialog.value = true
        return
      }

      showLoader.value = true

      try {
        // Show a loading indicator during deletion
        Swal.fire({
          title: 'Deleting Incident...',
          text: 'Please wait while the incident is being deleted.',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        })

        // Call the function to delete the incident by its ID
        await deleteIncidentById(incidentId)

        // Display a success message and refresh data
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Incident Deleted',
          text: 'The incident has been successfully deleted.',
          confirmButtonColor: '#d2f8d2',
          confirmButtonText: 'OK',
        }).then(() => {
          refresh() // Refresh the incident list
        })
      } catch (error) {
        console.error('Error deleting incident:', error)
        Swal.fire({
          icon: 'error',
          title: 'Delete Failed',
          text: 'An error occurred while deleting the incident. Please try again.',
          confirmButtonColor: '#d2f8d2',
          confirmButtonText: 'OK',
        }).then(() => {
          refresh() // Refresh the incident list
        })
      } finally {
        showLoader.value = false
      }
    }

    const refresh = async () => {
      // Display Swal loader while deleting incidents
      Swal.fire({
        title: 'Refreshing...',
        text: 'Please wait while we refresh the incidents list.',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        // Call the function to delete all stored incidents and incident categories
        await deleteIncidentsFull()

        // Reload the current page to refresh the incidents list
        router.go(0)
      } catch (error) {
        console.error('Error refreshing incidents:', error)
        Swal.fire({
          icon: 'error',
          title: 'Refresh Failed',
          text: 'There was an issue refreshing the incidents. Please try again.',
        })
      }
    }

    const editIncident = async () => {
      if (!isFormModified.value) {
        Swal.fire({
          icon: 'info',
          title: 'No Changes Detected',
          text: 'No changes were made to the incident.',
        })
        return
      }

      showLoader.value = true
      createDialog.value = false

      Swal.fire({
        title: 'Updating Incident...',
        text: 'Please wait while we update the incident details.',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        const latestIncident = await getIncidentById(selectedIncident.value?.id)
        const VERSION = latestIncident.version

        console.log(latestIncident)
        const payload = {
          description: description.value,
          version: VERSION,
          location: {
            floorId: SELECTED_FLOOR.value,
            locationName: locationText.value,
            latitude: location.value.lat,
            longitude: location.value.long,
          },
          incidentCategoryId: categories.value.find(category => category.name === SELECTED_CATEGORY.value)?.id,
          state: status.value?.['value'],
        }

        const updatedIncident = await updateIncidentById(selectedIncident.value.id, payload)

        if (updatedIncident && updatedIncident.id) {
          if (isCategoryChanged.value) {
            const destinationCategoryId = categories.value.find(category => category.name === SELECTED_CATEGORY.value)?.id
            await moveIncidentsToCategory(latestIncident.incidentCategory.id, destinationCategoryId)
          }

          if (areImagesChanged()) {
            const existingImages = latestIncident.files?.entities?.filter(file => file.type === 'image') || []
            const existingImageIds = existingImages.map(file => file.id)

            if (existingImageIds.length > 0) {
              await deleteIncidentFiles(selectedIncident.value.id, existingImageIds)
            }

            const imageFileBlobs = await Promise.all(imageFiles.value.map(file => resizeImage(file)))
            const imageResponse = await postMultipleFiles(
              imageFileBlobs,
              imageFiles.value.map(file => file.name)
            )

            const fileIdsArray = imageResponse.map(file => file.id)
            await linkIncidentToImage(selectedIncident.value.id, fileIdsArray)
          }

          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Incident Updated',
            text: 'The incident has been successfully updated.',
            confirmButtonColor: '#d2f8d2',
            confirmButtonText: 'OK',
          }).then(() => refresh()) // Refresh on OK
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Incident Update Failed',
            text: 'Incident update failed. Please try again.',
          }).then(() => refresh()) // Refresh on OK
        }
      } catch (error) {
        console.error('Error in editIncident:', error)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again.',
        }).then(() => refresh()) // Refresh on OK
      } finally {
        showLoader.value = false
      }
    }

    const setLocation = ({ coordinates, floor, locationName }) => {
      // Set the coordinates in the location field
      if (!coordinates) return
      floor = JSON.parse(JSON.stringify(floor))
      locationName = JSON.parse(JSON.stringify(locationName))
      location.value = { lat: coordinates[0], long: coordinates[1] }
      locationText.value = locationName
      SELECTED_FLOOR.value = floor
    }

    const cancelIncident = () => {
      createDialog.value = false
    }

    const onDateRangeChange = () => {
      switch (selectedDateRange.value) {
        case 'Today':
          dates.value = { start: moment().startOf('day').toDate(), end: moment().endOf('day').toDate() }
          break
        case 'This Week':
          dates.value = { start: moment().startOf('week').toDate(), end: moment().endOf('week').toDate() }
          break
        case 'This Month':
          dates.value = { start: moment().startOf('month').toDate(), end: moment().endOf('month').toDate() }
          break
      }
      applyFilters()
    }

    const searchResults = computed(() => {
      const query = searchQuery.value.toLowerCase()
      return filteredIncidents.value.filter(incident => {
        const descriptionMatch = incident.description?.toLowerCase().includes(query)
        const dateTimeMatch = moment(incident.creationTime).format('DD.MM.YYYY HH:mm').includes(query)
        const categoryMatch = incident.incidentCategoryName?.toLowerCase().includes(query)
        const locationMatch = incident.location.locationName?.toLowerCase().includes(query)
        const creatorMatch = incident.creatorName?.toLowerCase().includes(query)
        const statusMatch = incident.state?.toLowerCase().includes(query)

        return descriptionMatch || dateTimeMatch || categoryMatch || locationMatch || creatorMatch || statusMatch
      })
    })

    const filteredIncidents = computed(() => {
      return incidents.value.filter(incident => {
        const matchesCategory = selectedCategory.value === 0 || incident.incidentCategory?.id === selectedCategory.value
        const matchesFloor = selectedFloor.value === 0 || incident.location.floorId === selectedFloor.value
        const matchesLocation =
          selectedLocation.value === 0 ||
          incident.location.locationName.includes(locations.value.find(loc => loc.id === selectedLocation.value)?.name)
        const matchesDate = selectedDateRange.value === 'This Month' && moment(incident.creationTime).isSame(moment(), 'month')

        return matchesCategory && matchesFloor && matchesLocation && matchesDate
      })
    })

    const applyFilters = () => {
      searchQuery.value = searchQuery.value.trim()
    }

    const goBack = () => {
      router.go(-1)
    }

    const fetchCategories = async () => {
      let CATEGORIES = await getPoiCategories()
      categories.value.push(...CATEGORIES)
    }
    const fetchFloors = async () => {
      let FLOORS = await getFloors()
    }
    const fetchLocations = async () => {
      let LOCATIONS = await getLocations()
    }

    onMounted(async () => {
      fetchInitialData()
      logo.value = await getToolbarLogoImage()
    })

    return {
      logo,
      showLoader,
      searchQuery,
      selectedDateRange,
      selectedCategory,
      selectedFloor,
      goBack,
      selectedLocation,
      router,
      categories,
      floors,
      locations,
      menu,
      applyFilters,
      onDateRangeChange,
      handleIncidentClick,
      createDialog,
      locationText,
      SELECTED_CATEGORY,
      description,
      status,
      statusOptions,
      refresh,
      editIncident,
      cancelIncident,
      imageFiles,
      isFormModified,
      dates,
      searchResults,
      dateOptions,
      filteredIncidents,
      setLocation,
      openMap,
      SELECTED_FLOOR,
      mapView,
      SELECTED_BUILDING,
      selectItem,
      selectStatusRef,
      deleteIncident,
      getImageURL,
    }
  },
}
</script>

<style scoped>
.admin-incidents-management {
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
}

@supports (-webkit-touch-callout: none) {
  .custom-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

.toolbar-icon {
  height: 30px;
  align-self: center;
}
</style>
<!--
This component, AdminIncidentsManagement, provides a comprehensive interface for managing incidents within an administrative context. 

The toolbar at the top includes a back button, a title, and a logo. The back button allows users to navigate to the previous page, while the title clearly indicates the current section of the application.

A loader component is used to indicate loading states, ensuring users are aware when data is being fetched or processed.

The main container includes a search bar and a button to toggle the visibility of filter options. Users can search for specific incidents using the search bar, which filters the displayed incidents based on the query.

The expandable filters section allows users to refine their search by date range, category, floor, and location. These filters help users quickly find relevant incidents.

The AdminIncidents component displays a list of incidents that match the search and filter criteria. Users can click on an incident to open a dialog for editing.

The edit incident dialog allows users to update incident details such as location, category, description, status, and images. The location field is read-only and can be set using a map dialog. The status field includes a custom dropdown with colored chips to indicate different statuses. Users can upload multiple images, which are displayed as thumbnails.

The dialog includes buttons to cancel, delete, or update the incident. The update button is disabled unless the form has been modified. Deleting an incident triggers a confirmation dialog to prevent accidental deletions.

Visual feedback is provided using Swal alerts. These alerts inform users about loading states, successful updates, deletions, and errors. For example, when an incident is being deleted, a loading alert is shown, followed by a success or error alert based on the outcome.

Overall, this component enhances the user experience by providing a clear, interactive, and responsive interface for managing incidents. Users can efficiently search, filter, view, and edit incidents, with visual feedback ensuring they are informed about the status of their actions.
-->
