export const pois = [
  {
    id: 'ed40fd39-1a2b-418e-89b2-1b21df5d3368',
    version: '7047f024-8209-4190-9f0c-5eadced68068',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'e7d5666c-c2cd-43e2-a089-33a15fbaf726',
          type: 'attachment',
        },
        {
          id: '2061ae79-a406-4f4e-8b8f-78140ea0c3d8',
          type: 'image',
        },
        {
          id: 'e15462e5-4e24-447b-9385-b4822f6e188e',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.42 FH Team',
    locationDisplayName: '2.42 FH Team',
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54531002866162,
            long: 10.008343642322775,
          },
          {
            lat: 53.54528082545947,
            long: 10.008312796919103,
          },
          {
            lat: 53.54529995026996,
            long: 10.008261362560491,
          },
          {
            lat: 53.54532835659252,
            long: 10.008294890173177,
          },
        ],
        center: {
          lat: 53.54530459103001,
          long: 10.008306525755154,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: 'support@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-11-10T14:04:33Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '20b2704c-f40c-4d70-8e31-a728df241501',
    version: 'a22bcf3c-2f41-4cfd-b3ac-fda4819f4350',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.31 Lager',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.545169037795915,
            long: 10.008672745550923,
          },
          {
            lat: 53.54515517423972,
            long: 10.008656317020707,
          },
          {
            lat: 53.54516254528339,
            long: 10.008638410274822,
          },
          {
            lat: 53.54517660805446,
            long: 10.008653832976657,
          },
        ],
        center: {
          lat: 53.54516348463563,
          long: 10.00865525957078,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['135f7873-5ced-4c6c-b5b6-95388e4292f6'],
    },
    email: null,
    description: '',
    lastModified: '2024-01-08T15:10:48Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '83e6cf4c-d5d5-4441-9bb0-00276357eff1',
    version: '91de0255-e127-4be6-ad47-129e1d72bc61',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/83e6cf4c-d5d5-4441-9bb0-00276357eff1',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Level 4 Ost',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54526255504994,
            long: 10.008456219559987,
          },
          {
            lat: 53.545227972948986,
            long: 10.008538362211068,
          },
          {
            lat: 53.545203070807815,
            long: 10.008507379696194,
          },
          {
            lat: 53.54523864901432,
            long: 10.008426242873494,
          },
        ],
        center: {
          lat: 53.54523360980493,
          long: 10.008481464351963,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T12:16:43Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '821e64e4-a7c3-402a-96c0-ae5b1c8c00c2',
    version: '9ab50854-4fa6-44c4-aec4-ec0898334370',
    tags: {
      'presence-topics': '["presence/poi/821e64e4-a7c3-402a-96c0-ae5b1c8c00c2"]',
    },
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '9901f4fe-b810-458b-9c32-892821edbedf',
          type: 'logo',
        },
        {
          id: 'f13419f8-fad3-4ec0-b712-37cca355daea',
          type: 'image',
        },
        {
          id: 'e9f1b0e4-1965-40dd-ab17-ff483535e4eb',
          type: 'attachment',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.13 Lab',
    locationDisplayName: '4.13 Lab',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54528347282001,
            long: 10.00840458703645,
          },
          {
            lat: 53.54526064453583,
            long: 10.008375418013413,
          },
          {
            lat: 53.54528614429341,
            long: 10.008314931199376,
          },
          {
            lat: 53.54530957021387,
            long: 10.008343764946286,
          },
        ],
        center: {
          lat: 53.54528441012047,
          long: 10.00836747046883,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '4.13@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-07T13:30:34Z',
    availableFrom: '08:00',
    availableUntil: '18:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '3262f6be-c114-4339-8f24-d2b9e29ff501',
    version: '6fc183c3-1bda-4b0a-8d7f-02e43a0c2dba',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '2699e363-0b42-45ca-bd2b-50bc21eda4ed',
          type: 'image',
        },
        {
          id: 'db492566-fb6d-4514-8e77-d69f43195680',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.72 Lab',
    locationDisplayName: '0.72 Lab',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54524932952785,
            long: 10.008487721027741,
          },
          {
            lat: 53.545202993615725,
            long: 10.00843340629519,
          },
          {
            lat: 53.54522650123728,
            long: 10.008377278070801,
          },
          {
            lat: 53.54527283712368,
            long: 10.008431592803353,
          },
        ],
        center: {
          lat: 53.54523273573853,
          long: 10.00843048789251,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 12,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:30:34Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '1f0ed459-c9e3-4fc1-899c-c66ef37550a2',
    version: 'd6836ae8-813f-4144-865a-f69203f8bbe3',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Level 1',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54522950606647,
            long: 10.008539974015317,
          },
          {
            lat: 53.545196318452746,
            long: 10.008497058671079,
          },
          {
            lat: 53.54522899006402,
            long: 10.008414778908836,
          },
          {
            lat: 53.545263771387376,
            long: 10.008457694253075,
          },
        ],
        center: {
          lat: 53.545227255891916,
          long: 10.008477376462057,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-11-22T10:53:27Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '759b3008-fb81-4a06-b9eb-33363eaa0e64',
    version: '14ae2f65-f9c2-438e-9b02-66a791bb9681',
    tags: {
      'presence-topics': '["presence/poi/4ff91bf2-0f19-48f2-bd5e-75d4678b2176"]',
    },
    configs: {
      'presence-topic': 'presence/poi/4ff91bf2-0f19-48f2-bd5e-75d4678b2176',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'c5bd9a15-53ad-4420-9042-b1bd5be36302',
          type: 'image',
        },
        {
          id: 'd2cba0cb-9303-45b3-89bb-2f643deda920',
          type: 'logo',
        },
        {
          id: '4d2e07f9-abc4-487c-b2a4-f02a48ebf051',
          type: 'attachment',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.02 Auditorium',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54509782338322,
            long: 10.008636198263726,
          },
          {
            lat: 53.54505965522007,
            long: 10.008720352571569,
          },
          {
            lat: 53.54500028825546,
            long: 10.008645448884117,
          },
          {
            lat: 53.54503666351057,
            long: 10.008561629852402,
          },
        ],
        center: {
          lat: 53.54506310070526,
          long: 10.008642332316473,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '1.02@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T14:55:51Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'b14c4453-e5fd-4ce0-9d14-5df67accbdd2',
    version: '229d7c82-f04c-49b5-8cd6-ab287374e461',
    tags: {},
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'e031711c-c688-4bcb-81e8-4321d0fb50fe',
          type: 'logo',
        },
        {
          id: '3ec0fd54-34d3-40f2-854c-312d4a09b878',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.02-b West',
    locationDisplayName: '4.02-b West',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54541256511511,
            long: 10.007956658130963,
          },
          {
            lat: 53.54532718293354,
            long: 10.008154471045811,
          },
          {
            lat: 53.54527140224794,
            long: 10.008083590671841,
          },
          {
            lat: 53.54535837827374,
            long: 10.0078871188615,
          },
        ],
        center: {
          lat: 53.54533102682695,
          long: 10.00801811274464,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 200,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '',
    description: '',
    lastModified: '2021-09-17T09:34:22Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '32656a1c-631e-411f-8240-03784fc2b2fd',
    version: '2b39e0ce-5fb3-438c-b69e-ef13b1ba18d1',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'a4438f62-7351-4819-a9ab-ffdd6a751f9b',
          type: 'logo',
        },
        {
          id: 'bb8f54d8-dc5e-4492-a0e9-7c0ade629723',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.06 RedPinata',
    locationDisplayName: '0.06 RedPinata',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.545548552358056,
            long: 10.00777425342978,
          },
          {
            lat: 53.545451615935356,
            long: 10.007657577337632,
          },
          {
            lat: 53.545513970502824,
            long: 10.007513961058523,
          },
          {
            lat: 53.545546044222206,
            long: 10.0075554172014,
          },
          {
            lat: 53.54557991085603,
            long: 10.007531041126603,
          },
          {
            lat: 53.545627441646666,
            long: 10.007588708620423,
          },
        ],
        center: {
          lat: 53.54553514613616,
          long: 10.007624484083534,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 24,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:54:44Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'e190b91c-8843-4296-a8b1-8b819c80038e',
    version: '58332698-9346-4ea8-a0ba-d704dfeaeac3',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/e190b91c-8843-4296-a8b1-8b819c80038e',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Level 2 Townhall',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54547298759512,
            long: 10.007935276000257,
          },
          {
            lat: 53.54544976098658,
            long: 10.007905771701093,
          },
          {
            lat: 53.545474463766595,
            long: 10.007846625991563,
          },
          {
            lat: 53.54549788957734,
            long: 10.007876465566854,
          },
        ],
        center: {
          lat: 53.54546994057556,
          long: 10.00789346556686,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T12:22:22Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '443ee6f9-7cef-420b-a8d7-91dec848df4e',
    version: '837fe992-8d4a-4ab6-a208-0823a69e33ed',
    tags: {
      'presence-topics': '["presence/poi/443ee6f9-7cef-420b-a8d7-91dec848df4e"]',
    },
    configs: {
      'presence-topic': 'presence/poi/443ee6f9-7cef-420b-a8d7-91dec848df4e',
      'poi-locks':
        'c7f66a20-cca6-4ba0-99a8-a7c14d65bc99,bc5ce2f8-936e-42c2-b21a-581bfe7690c1,bd0196ae-8a0d-4533-bf57-4e50e6572318,af2010f0-944f-4a67-9fda-665ace93c9e9,b4e2fad6-3195-4573-9b15-1c4a4e49357b',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'f7e7c599-a903-4990-8e45-f245fbbac825',
          type: 'logo',
        },
        {
          id: '3fd623c3-0ef9-4e21-9255-ca5fa4c50279',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.23 Junico',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54507371803684,
            long: 10.00889536670979,
          },
          {
            lat: 53.54504092873684,
            long: 10.008855133574567,
          },
          {
            lat: 53.54508674884057,
            long: 10.008745361169922,
          },
          {
            lat: 53.5451191396699,
            long: 10.008784923752891,
          },
        ],
        center: {
          lat: 53.54508939745274,
          long: 10.0088274047385,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 8,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.23@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T15:27:30Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'cba5c05a-37af-4bfd-92df-43da68292a07',
    version: '2981b523-a52f-457c-a4cc-b32ed5f6fe87',
    tags: {
      'presence-topics': '["presence/poi/cba5c05a-37af-4bfd-92df-43da68292a07"]',
    },
    configs: {
      'presence-topic': 'presence/poi/cba5c05a-37af-4bfd-92df-43da68292a07',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,2f0cc4ce-8c82-4be4-8592-bd7ab9cd3f56',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '36a6d776-5c1c-426a-8466-c2e1d0c37989',
          type: 'logo',
        },
        {
          id: '433ce5ed-c368-420d-9a47-70ad585fddc1',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.08 PTC',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54528005254385,
            long: 10.008411382367427,
          },
          {
            lat: 53.54525343913741,
            long: 10.008471732070262,
          },
          {
            lat: 53.54522933387959,
            long: 10.008444102316698,
          },
          {
            lat: 53.545255947301236,
            long: 10.008382411509356,
          },
        ],
        center: {
          lat: 53.545254095565866,
          long: 10.0084265688756,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.08@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T11:21:50Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '8d6599db-dd94-4544-a36c-8d9e68ac6954',
    version: '7d77fdda-2301-4d60-96e5-4ca647d67a7d',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/8d6599db-dd94-4544-a36c-8d9e68ac6954',
      'poi-locks':
        'b95a8ad8-9369-4245-9fd3-6d8cd95b922a,b06d3357-b30b-4aeb-ae3e-ecf62464381d,29102584-7710-4f9a-a2b6-9deea5fcd2aa,43ef3817-60ee-4771-8059-c6178520f9bf,25ca6416-ce64-49a0-b84c-90ee48016353',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Townhall / Community Area',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54541123061589,
            long: 10.007976179687734,
          },
          {
            lat: 53.54530379416581,
            long: 10.008243059484716,
          },
          {
            lat: 53.54529835653607,
            long: 10.008236353962179,
          },
          {
            lat: 53.54527121324673,
            long: 10.00830704055332,
          },
          {
            lat: 53.54527594464703,
            long: 10.008313354128102,
          },
          {
            lat: 53.54524078285942,
            long: 10.008395718712094,
          },
          {
            lat: 53.54522464627863,
            long: 10.008375375458014,
          },
          {
            lat: 53.54523062279082,
            long: 10.00835614662193,
          },
          {
            lat: 53.54517882632423,
            long: 10.008290866859655,
          },
          {
            lat: 53.545429605053656,
            long: 10.007688710935811,
          },
          {
            lat: 53.54547479197941,
            long: 10.007741014011602,
          },
          {
            lat: 53.545530103746756,
            long: 10.007609585769872,
          },
          {
            lat: 53.54555466604215,
            long: 10.007636407860021,
          },
        ],
        center: {
          lat: 53.54534204375475,
          long: 10.008023552704083,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: '',
    lastModified: '2021-02-18T12:25:54Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '964fd8a7-af3c-406a-b1f3-1b1227cdff23',
    version: '5156d6e6-1ac3-440b-aaae-2feaad800aab',
    tags: {
      'presence-topics': '["presence/poi/964fd8a7-af3c-406a-b1f3-1b1227cdff23"]',
    },
    configs: {
      'presence-topic': 'presence/poi/964fd8a7-af3c-406a-b1f3-1b1227cdff23',
      'poi-locks':
        'c7f66a20-cca6-4ba0-99a8-a7c14d65bc99,bc5ce2f8-936e-42c2-b21a-581bfe7690c1,bd0196ae-8a0d-4533-bf57-4e50e6572318,af2010f0-944f-4a67-9fda-665ace93c9e9,4a64d5b9-1930-4e8b-b0c0-d7321d903c85',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '8f86bb75-5226-4ba0-8f24-96b03fac98aa',
          type: 'image',
        },
        {
          id: '3b3ece91-2393-4a76-9f71-d4a6df61717f',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.06 StockRepublic',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54542394147302,
            long: 10.008076287131868,
          },
          {
            lat: 53.54539433990502,
            long: 10.008039071481786,
          },
          {
            lat: 53.54540768739415,
            long: 10.008005406757938,
          },
          {
            lat: 53.54543828503317,
            long: 10.00804262240802,
          },
        ],
        center: {
          lat: 53.54541491795885,
          long: 10.00804218804939,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.06@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T14:49:21Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f263d457-444f-48da-83f9-eed411146ecd',
    version: 'f6eefcdf-38c8-4008-b415-e48cdf0ee294',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Flur Townhall / Auditorium',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.5451466706658,
            long: 10.008646731941457,
          },
          {
            lat: 53.54512463918357,
            long: 10.008627956478353,
          },
          {
            lat: 53.5452250447128,
            long: 10.008376697548131,
          },
          {
            lat: 53.54524388867097,
            long: 10.008400837429265,
          },
        ],
        center: {
          lat: 53.545179482751735,
          long: 10.008519761371838,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: '',
    lastModified: '2021-02-18T12:27:31Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '6c693d68-01d8-4acc-9867-8dbba2d04d94',
    version: '31667748-17aa-401d-8f83-a87750e233c0',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.32 PuMi',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54547571588901,
            long: 10.007859550239235,
          },
          {
            lat: 53.54547002028726,
            long: 10.007852844716698,
          },
          {
            lat: 53.54547559833296,
            long: 10.007840972941095,
          },
          {
            lat: 53.54548089550224,
            long: 10.007849690120393,
          },
        ],
        center: {
          lat: 53.545476254756025,
          long: 10.007849255761805,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['135f7873-5ced-4c6c-b5b6-95388e4292f6'],
    },
    email: null,
    description: '',
    lastModified: '2024-01-08T15:13:39Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '57dcd420-cbc8-408c-a455-d4081bd5b87d',
    version: '78c37a62-2d9c-42bb-a01c-61eee4f5259a',
    tags: {
      'presence-topics': '["presence/poi/57dcd420-cbc8-408c-a455-d4081bd5b87d"]',
    },
    configs: {
      'presence-topic': 'presence/poi/57dcd420-cbc8-408c-a455-d4081bd5b87d',
      'poi-locks':
        'b95a8ad8-9369-4245-9fd3-6d8cd95b922a,b06d3357-b30b-4aeb-ae3e-ecf62464381d,29102584-7710-4f9a-a2b6-9deea5fcd2aa,43ef3817-60ee-4771-8059-c6178520f9bf,7a56df13-48d9-401b-8c27-d9b1323455d6',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'ca73f3a1-c7b8-4c8e-8e03-c4ca9d994938',
          type: 'attachment',
        },
        {
          id: 'ffc05a3a-8856-4165-94e7-82bd7587a377',
          type: 'image',
        },
        {
          id: '6cf86981-1d4e-4410-b603-80adcc920666',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.05 acitoflux',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54530484902751,
            long: 10.008343642322775,
          },
          {
            lat: 53.54528028658718,
            long: 10.008402986197229,
          },
          {
            lat: 53.545269669316,
            long: 10.008392592637296,
          },
          {
            lat: 53.54525612256641,
            long: 10.008376697548131,
          },
          {
            lat: 53.54528094301583,
            long: 10.008315677293043,
          },
        ],
        center: {
          lat: 53.5452802865845,
          long: 10.008360169935452,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '2.05@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T14:34:02Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'ddf52128-9ff3-405a-bbbe-8033c1390f2f',
    version: '96662bdd-a0b8-4334-b4f7-3f0d9d74be21',
    tags: {},
    configs: {
      'poi-locks': 'bedf07f4-7841-470e-9ece-3587ad986430',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.09',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54535381733105,
            long: 10.008063881915174,
          },
          {
            lat: 53.54528078646923,
            long: 10.00797671012219,
          },
          {
            lat: 53.54531385644869,
            long: 10.007894666553572,
          },
          {
            lat: 53.54535887939113,
            long: 10.007948074540245,
          },
          {
            lat: 53.545387285686004,
            long: 10.007981602152931,
          },
        ],
        center: {
          lat: 53.54532766117772,
          long: 10.007978485585287,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:41:26Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'cadb8d4b-7cde-462a-b2b1-1748414fa35a',
    version: 'f896d887-8096-4b98-959b-0825718171f5',
    tags: {
      'presence-topics': '["presence/poi/cadb8d4b-7cde-462a-b2b1-1748414fa35a"]',
    },
    configs: {
      'presence-topic': 'presence/poi/cadb8d4b-7cde-462a-b2b1-1748414fa35a',
      'poi-locks':
        'c7f66a20-cca6-4ba0-99a8-a7c14d65bc99,bc5ce2f8-936e-42c2-b21a-581bfe7690c1,bd0196ae-8a0d-4533-bf57-4e50e6572318,af2010f0-944f-4a67-9fda-665ace93c9e9,10b352d3-90e6-45d0-8615-9843ba183cae',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'e0513590-0029-4da6-bdde-5b94f06912c7',
          type: 'logo',
        },
        {
          id: 'f18f6e3a-c15a-4dd5-9e64-1eeac34ccc79',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.04b NCA',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.545476335287354,
            long: 10.007950223308168,
          },
          {
            lat: 53.54544533924374,
            long: 10.007916025143228,
          },
          {
            lat: 53.5454786083105,
            long: 10.007840115627396,
          },
          {
            lat: 53.54550761217212,
            long: 10.007876660725223,
          },
        ],
        center: {
          lat: 53.54547269061448,
          long: 10.00789801931484,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.04b@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T14:44:28Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'd1409ea3-0303-4011-825d-d573d1cf0815',
    version: 'b1d53697-5136-4cc0-8407-fd8eb78209a5',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.11',
    locationDisplayName: '4.11',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54545418101592,
            long: 10.00800887563952,
          },
          {
            lat: 53.545413821801596,
            long: 10.008109458477579,
          },
          {
            lat: 53.545386728390376,
            long: 10.008080822895632,
          },
          {
            lat: 53.54543027508854,
            long: 10.007978898953066,
          },
        ],
        center: {
          lat: 53.545422446875534,
          long: 10.00804015540182,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['135f7873-5ced-4c6c-b5b6-95388e4292f6'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:22:20Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'e5295b4a-41e9-4f4a-948f-4805699c5cf7',
    version: '5be471ed-3c53-4fb9-92c2-ef30a56d483c',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '4e2a3472-9310-40d3-871d-84caa4da9bb5',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Aufzug B / Etage 2',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54538891839204,
            long: 10.008136106400247,
          },
          {
            lat: 53.5453684807728,
            long: 10.008111631242986,
          },
          {
            lat: 53.54538142983737,
            long: 10.008076960690717,
          },
          {
            lat: 53.54540166823409,
            long: 10.008098753638963,
          },
        ],
        center: {
          lat: 53.54538507450403,
          long: 10.008106701183545,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: "<p>Hier geht's rauf und runter!</p>",
    lastModified: '2020-10-04T11:18:29Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '31c6dce9-d111-486a-ace9-fe7eebe2b176',
    version: '7fe21bf5-137e-49fb-9ffe-8818f81d01fb',
    tags: {
      'presence-topics': '["presence/poi/31c6dce9-d111-486a-ace9-fe7eebe2b176"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '1abbb129-2de7-416f-9c7b-bc322bc1c6e1',
          type: 'image',
        },
        {
          id: '77ce5617-b154-45a2-b5ff-e6c2ff7a06e6',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.44 Lab',
    locationDisplayName: '3.44 Lab',
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545255150433434,
            long: 10.008475755383785,
          },
          {
            lat: 53.54523232213543,
            long: 10.008531075944717,
          },
          {
            lat: 53.545204033304984,
            long: 10.008498081773123,
          },
          {
            lat: 53.54522706083532,
            long: 10.008444772868952,
          },
        ],
        center: {
          lat: 53.54522580674994,
          long: 10.008486918578434,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.44@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T11:19:40Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f73cdf85-4ce4-4a19-9257-1b0990bee123',
    version: '34e752c2-d834-47ed-94da-7acd2c3c5e37',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'ff0c0d7f-46bd-490e-9ef2-32b7de073438',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Pinestack test POI',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.545423406726435,
            long: 10.007912442394929,
          },
          {
            lat: 53.54539340672644,
            long: 10.007912442394929,
          },
          {
            lat: 53.54539340672644,
            long: 10.00787844239493,
          },
          {
            lat: 53.545423406726435,
            long: 10.00787844239493,
          },
        ],
        center: {
          lat: 53.54540840672644,
          long: 10.00789544239493,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: 'meri189799@gmail.com',
    description: '',
    lastModified: '2023-07-14T16:30:33Z',
    availableFrom: '00:00',
    availableUntil: '23:59:59',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'a617c9a6-5ae4-42b4-9ec5-9c8d22f84267',
    version: '1591af62-585f-467f-b9d4-2069ab045c80',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'e7294c56-8188-40c1-a6d9-71d3f8ebcb95',
          type: 'image',
        },
        {
          id: 'a9e13e7a-6be9-4ec8-9592-bc2800f99ddc',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.70  FH Operations',
    locationDisplayName: '0.70  FH Operations',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.545296327840404,
            long: 10.008375236211,
          },
          {
            lat: 53.545272761482714,
            long: 10.008347073016344,
          },
          {
            lat: 53.54526792150296,
            long: 10.008355119643388,
          },
          {
            lat: 53.54524610724877,
            long: 10.008330417799657,
          },
          {
            lat: 53.54525990302266,
            long: 10.008296273929567,
          },
          {
            lat: 53.54527369879208,
            long: 10.008262130059435,
          },
          {
            lat: 53.545295811857166,
            long: 10.008288498776604,
          },
          {
            lat: 53.54530218679271,
            long: 10.008272839881105,
          },
          {
            lat: 53.54532621034206,
            long: 10.008303685284776,
          },
        ],
        center: {
          lat: 53.54527775876728,
          long: 10.0083118001436,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '<p><a href="http://www.factoryberlin.com">www.factoryberlin.com</a></p>',
    lastModified: '2022-02-28T10:28:11Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'c3969a1e-8af6-4bd1-90c6-38012a28cd6a',
    version: 'eca46419-2dbf-41b5-8b35-5c354987f090',
    tags: {
      'presence-topics': '["presence/poi/c3969a1e-8af6-4bd1-90c6-38012a28cd6a"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '04412d9c-b0c0-472c-987c-9ed20ec09e98',
          type: 'image',
        },
        {
          id: 'f1aa6137-a35e-4391-a148-6afcf619b266',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.05 Lab',
    locationDisplayName: '3.05 Lab',
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54544858964196,
            long: 10.008012403776462,
          },
          {
            lat: 53.545423171638475,
            long: 10.008074094583804,
          },
          {
            lat: 53.5453996641261,
            long: 10.00804545900186,
          },
          {
            lat: 53.54542528135994,
            long: 10.00798343291839,
          },
        ],
        center: {
          lat: 53.54542263276738,
          long: 10.008028260836888,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.05@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T07:08:37Z',
    availableFrom: '07:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '1aec822c-9da3-4ae8-8ec3-40b435250d37',
    version: 'ae227a40-215f-4203-b673-32b99422c278',
    tags: {
      'presence-topics': '["presence/poi/1aec822c-9da3-4ae8-8ec3-40b435250d37"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '3b7c23fa-fd56-4912-a677-da82c58f810b',
          type: 'image',
        },
        {
          id: 'ec0bbcd2-f4ee-412c-a674-312ceb57af2d',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.06 know.haus',
    locationDisplayName: '3.06 know.haus',
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54540814876832,
            long: 10.008104269435222,
          },
          {
            lat: 53.54538452368751,
            long: 10.008077447345073,
          },
          {
            lat: 53.5453972735309,
            long: 10.008048811763128,
          },
          {
            lat: 53.54541930487526,
            long: 10.008076974957785,
          },
        ],
        center: {
          lat: 53.545403508012285,
          long: 10.00807922280817,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: 'besprecher@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T09:47:02Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '8aabac39-9dd3-4ee6-9870-3abff2ee19a5',
    version: '9e9df145-126a-46ac-968f-3b8fa0fc0f91',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '335f6f9a-af5d-4421-859b-cae9745ccb14',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Aufzug B / Etage 3',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545392609897085,
            long: 10.008140479256923,
          },
          {
            lat: 53.54537316835813,
            long: 10.008116339375789,
          },
          {
            lat: 53.545387312719214,
            long: 10.0080826746519,
          },
          {
            lat: 53.54540635581919,
            long: 10.008105808704654,
          },
        ],
        center: {
          lat: 53.54538996130557,
          long: 10.008110068211842,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: "<p>Hier geht's rauf und runter!</p>",
    lastModified: '2020-10-04T11:19:31Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '2658ac2b-1273-4632-9e40-04e53f87cf88',
    version: 'e6961789-ce76-4769-8f7a-cf5dc765931d',
    tags: {
      'presence-topics': '["presence/poi/2658ac2b-1273-4632-9e40-04e53f87cf88"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '35af1d5d-2efb-45e4-a324-db3f0e2dda3b',
          type: 'logo',
        },
        {
          id: 'f09c08f3-3cb4-4302-a88d-006b74537655',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.10 Meeting Room',
    locationDisplayName: '2.10 Meeting Room',
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.545418402398774,
            long: 10.00806737479424,
          },
          {
            lat: 53.545395972625336,
            long: 10.008039882151838,
          },
          {
            lat: 53.5454206754368,
            long: 10.007979730613927,
          },
          {
            lat: 53.54544310519707,
            long: 10.008006552704076,
          },
        ],
        center: {
          lat: 53.545417347537224,
          long: 10.008027576017605,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['bfcaea7c-17a5-4434-a729-dbcbccdc58ba'],
    },
    email: '2.10@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T14:37:50Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f0656377-7a5f-4bcd-b6fd-0c8092036014',
    version: '304a544a-87d6-46ee-bad1-350ee2e89023',
    tags: {
      'presence-topics': '["presence/poi/f0656377-7a5f-4bcd-b6fd-0c8092036014"]',
    },
    configs: {
      'presence-topic': 'presence/poi/f0656377-7a5f-4bcd-b6fd-0c8092036014',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,7b86103f-35a8-4c14-8f9f-92696ec2a09d',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '70b59309-62b0-44d1-b733-7f1f20e39134',
          type: 'image',
        },
        {
          id: '07a9fc69-6ea5-4aa2-998b-3d4071ca106b',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.04 Lab',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54547946811563,
            long: 10.007941325237567,
          },
          {
            lat: 53.54545624150662,
            long: 10.007911820938403,
          },
          {
            lat: 53.54547536623787,
            long: 10.00786709210237,
          },
          {
            lat: 53.5454981944048,
            long: 10.00789592584928,
          },
        ],
        center: {
          lat: 53.54547602266286,
          long: 10.007907561431217,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.04@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T12:01:30Z',
    availableFrom: '07:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'bca835cb-a88e-461e-8b71-04dbd4938c89',
    version: 'a9f2b868-9d87-4441-84e9-1b48fa425d43',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/bca835cb-a88e-461e-8b71-04dbd4938c89',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Level 2 Auditorium',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.545224763835144,
            long: 10.008538102476354,
          },
          {
            lat: 53.5452003417872,
            long: 10.008512621490713,
          },
          {
            lat: 53.54523460713172,
            long: 10.008429000623922,
          },
          {
            lat: 53.54525783385831,
            long: 10.008458504923086,
          },
        ],
        center: {
          lat: 53.5452288886128,
          long: 10.008490257072674,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T12:31:21Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '961a2864-237b-4eca-802e-10275721a6b3',
    version: 'f912e2fa-23a2-45c4-85ad-a10df3c48b42',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.71 Enteractive',
    locationDisplayName: '0.71 Enteractive',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54527363399113,
            long: 10.008432735742936,
          },
          {
            lat: 53.54522650123728,
            long: 10.008377079905877,
          },
          {
            lat: 53.54524562607237,
            long: 10.00833033941304,
          },
          {
            lat: 53.54526759871009,
            long: 10.008354479294175,
          },
          {
            lat: 53.545270815567605,
            long: 10.008348109047764,
          },
          {
            lat: 53.545296344706436,
            long: 10.008376607518548,
          },
        ],
        center: {
          lat: 53.54525943081446,
          long: 10.00838019647348,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 8,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:29:14Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'b857902a-9f6a-4414-b03b-7bd7118f4f02',
    version: '6d1dfffb-5b14-44b9-846a-689784c07058',
    tags: {
      'presence-topics': '["presence/poi/b857902a-9f6a-4414-b03b-7bd7118f4f02"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '14f123e6-029f-46e5-bf88-8d53a19409bd',
          type: 'image',
        },
        {
          id: '868f4fee-58b4-4731-a8c3-343c9401ac2d',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.07 Lab',
    locationDisplayName: '3.07 Lab',
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545330454370514,
            long: 10.008292694618518,
          },
          {
            lat: 53.545310016713366,
            long: 10.008339968552406,
          },
          {
            lat: 53.5452863099214,
            long: 10.008310662418207,
          },
          {
            lat: 53.54530614993984,
            long: 10.0082643943127,
          },
        ],
        center: {
          lat: 53.54530947784084,
          long: 10.008303857813168,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.07@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T11:16:01Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '286eb731-0703-4c95-a547-0205a5025fea',
    version: 'f29ba6c6-c545-4e19-84ae-f22578ceca94',
    tags: {
      'presence-topics': '["presence/poi/286eb731-0703-4c95-a547-0205a5025fea"]',
    },
    configs: {
      'presence-topic': 'presence/poi/286eb731-0703-4c95-a547-0205a5025fea',
      'poi-locks':
        'c7f66a20-cca6-4ba0-99a8-a7c14d65bc99,bc5ce2f8-936e-42c2-b21a-581bfe7690c1,bd0196ae-8a0d-4533-bf57-4e50e6572318,af2010f0-944f-4a67-9fda-665ace93c9e9,03f48589-3050-4252-b0dd-41ab5548d59f',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '74ac85f7-9b54-4d9d-93af-91d4727be4d9',
          type: 'logo',
        },
        {
          id: 'f1d19aff-2641-41a2-ab4f-d164ad949a4c',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.05 HR Heroes',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.545472749400986,
            long: 10.007959946315847,
          },
          {
            lat: 53.54543737060111,
            long: 10.008042424243055,
          },
          {
            lat: 53.54540469915087,
            long: 10.00799769540702,
          },
          {
            lat: 53.545441472490346,
            long: 10.007915888032066,
          },
        ],
        center: {
          lat: 53.545438823894905,
          long: 10.007980497242048,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.05@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T14:47:09Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '4ff91bf2-0f19-48f2-bd5e-75d4678b2176',
    version: 'a9a0cca0-a97b-4774-a823-f9d6b20cddf9',
    tags: {
      'presence-topics': '["presence/poi/4ff91bf2-0f19-48f2-bd5e-75d4678b2176"]',
    },
    configs: {
      'presence-topic': 'presence/poi/4ff91bf2-0f19-48f2-bd5e-75d4678b2176',
      'poi-locks':
        'b95a8ad8-9369-4245-9fd3-6d8cd95b922a,b06d3357-b30b-4aeb-ae3e-ecf62464381d,29102584-7710-4f9a-a2b6-9deea5fcd2aa,43ef3817-60ee-4771-8059-c6178520f9bf,07d63920-c2d7-42d7-941c-dff9f894322e',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '7173f993-5ab7-4f0b-ba22-0f777dc05601',
          type: 'attachment',
        },
        {
          id: '950a01c3-984e-4fb7-8eb7-f2a36ebad229',
          type: 'logo',
        },
        {
          id: '8eb65c8b-cdff-41b3-a5f2-0fa64bb20a14',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.02 Auditorium',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54522954504493,
            long: 10.008359735576864,
          },
          {
            lat: 53.545119061091626,
            long: 10.008623262612577,
          },
          {
            lat: 53.54507124884487,
            long: 10.008561769970159,
          },
          {
            lat: 53.54516101431996,
            long: 10.00834585214446,
          },
          {
            lat: 53.54518013918464,
            long: 10.008299820232617,
          },
        ],
        center: {
          lat: 53.54517828744794,
          long: 10.008383639264332,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 180,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '2.02@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T14:27:21Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'a68aa145-3f45-4e8f-8ae7-3d5dcbc2ad97',
    version: '07ce2fab-0c78-46ce-8caf-6ef359ddf809',
    tags: {
      'presence-topics': '["presence/poi/a68aa145-3f45-4e8f-8ae7-3d5dcbc2ad97"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '40f7e282-062b-4a13-b13a-f18a0761c47d',
          type: 'logo',
        },
        {
          id: 'cf5d9ceb-4249-4c69-a461-ba9165641f72',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.09 Lab',
    locationDisplayName: '3.09 Lab',
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54530933740696,
            long: 10.008343991865928,
          },
          {
            lat: 53.545282524802104,
            long: 10.008411382367427,
          },
          {
            lat: 53.5452546344391,
            long: 10.00837771764358,
          },
          {
            lat: 53.54528264236248,
            long: 10.008310997694334,
          },
        ],
        center: {
          lat: 53.54527840002638,
          long: 10.00835280812769,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.09@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T11:29:12Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '45c4ae5d-da4e-474c-9bfd-d502984868e4',
    version: '486d8a71-a9f5-4741-a8ba-c1743038bee8',
    tags: {},
    configs: {
      'poi-locks': '3ad9d3b4-f88e-4c1b-bbbf-cf849c97e0b3',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'd8ec2a4f-a73a-46b1-aed1-32b2d12d5f85',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.77 Ben Evers',
    locationDisplayName: '0.77 Ben Evers',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.545098322795724,
            long: 10.008648653568635,
          },
          {
            lat: 53.54506991632538,
            long: 10.008613784851441,
          },
          {
            lat: 53.54509661150392,
            long: 10.008549610000008,
          },
          {
            lat: 53.54512541639154,
            long: 10.008583808164948,
          },
        ],
        center: {
          lat: 53.545092885140214,
          long: 10.00859812595594,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['64e664f6-0417-4bf1-b4af-f5eda7e2e741'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:43:20Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'a5664723-8330-487b-931d-70d176867c5e',
    version: '65f6bd76-0ead-440d-8ef1-900f74453b9d',
    tags: {
      'presence-topics': '["presence/poi/a5664723-8330-487b-931d-70d176867c5e"]',
    },
    configs: {
      'presence-topic': 'presence/poi/a5664723-8330-487b-931d-70d176867c5e',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,5a23281f-219c-49ed-bba5-234fd7e610c3',
      'room-control': 'true',
      'poi-entrances':
        '6be05a35-5f74-4193-ad6b-3af000d8b6bb,50a63c6a-5de8-4ff0-a40b-5172d9c73e65,9f3f3eb5-ee9d-4696-8964-f363ac9cd9f3,7b4a2ce2-8e5e-4a9f-9703-2cfcc662be24,24b55675-b68f-4514-9de3-c37746a3ac9f',
      'poi-elevators': 'ced0480d-51c2-4320-8c0f-905661c35cc3',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'c7d939c6-8cf8-4ca5-a868-e19ac98c017a',
          type: 'image',
        },
        {
          id: '40b86fda-5fb4-44b8-8838-55c325520a45',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.01a Aurubis',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54523642403673,
            long: 10.008362432052905,
          },
          {
            lat: 53.545209212951406,
            long: 10.008426805069263,
          },
          {
            lat: 53.54515582271935,
            long: 10.008357936352095,
          },
          {
            lat: 53.545183033839,
            long: 10.008296916097006,
          },
        ],
        center: {
          lat: 53.54519791634955,
          long: 10.008360854754734,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.01@hammeerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-10-04T11:23:14Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '5c8b2e76-366c-4dfa-b751-56ca3dc9058a',
    version: '2cb86815-dbbc-4194-ac16-3d837dbdb413',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '69e69f3d-583b-427a-b9e4-182090d026b1',
          type: 'logo',
        },
        {
          id: '7d9e7dcf-aca3-4622-8147-fbc25d821a8e',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.10 Videociety',
    locationDisplayName: '1.10 Videociety',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54531795834986,
            long: 10.008147030394635,
          },
          {
            lat: 53.545247318030036,
            long: 10.008061199706159,
          },
          {
            lat: 53.54528238020379,
            long: 10.007980261048424,
          },
          {
            lat: 53.545352223599174,
            long: 10.008064750632393,
          },
        ],
        center: {
          lat: 53.54529658337663,
          long: 10.008065657378271,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:43:25Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '6344992c-b455-4305-8757-d37c98e170d9',
    version: 'e202985e-46f6-4967-8a09-36caacc6b907',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/6344992c-b455-4305-8757-d37c98e170d9',
      'poi-locks':
        'b14fb049-9c1e-4236-a49d-a280b1a511b8,e30adac7-8cfa-4a58-85f3-4ba3d364350f,9cee37b5-980a-441d-9151-97aac1cca814,ff9b5489-0bff-4068-8530-bfcc014168ce,74239df9-902c-4549-a64a-d66d365fd120',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'c49de899-a110-4cf8-a965-afed88683eba',
          type: 'image',
        },
        {
          id: '919fcd92-29fc-4165-8de8-77d02dda68a6',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.01-b Ost',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54515557543568,
            long: 10.008564178472836,
          },
          {
            lat: 53.54509808322027,
            long: 10.008493770486195,
          },
          {
            lat: 53.54513792673766,
            long: 10.00840210299236,
          },
          {
            lat: 53.54519382516118,
            long: 10.008469158217732,
          },
        ],
        center: {
          lat: 53.54514734873728,
          long: 10.008491522635769,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '4.01b@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-05-01T09:56:10Z',
    availableFrom: '00:00',
    availableUntil: '23:59:59',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '1e98fddb-6250-49c7-8201-1f3a8faf230c',
    version: 'e832f1c6-867a-4018-8dce-8d1e1d2df7a6',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/1e98fddb-6250-49c7-8201-1f3a8faf230c',
      'room-control': 'true',
      'poi-entrances': '24b55675-b68f-4514-9de3-c37746a3ac9f,8688344c-d81e-40c0-a284-efb4e71190b9,fb3044d7-b8ca-423a-a937-603101b9b81c',
      'poi-elevators': '5c1b7d03-9f08-4e7c-8ff7-9d36ccb56c92,ced0480d-51c2-4320-8c0f-905661c35cc3,bdb44408-8613-4d2e-b7c6-7b84d5876908',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.12 ROM Technik',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54531393271593,
            long: 10.00834549287089,
          },
          {
            lat: 53.5452863232447,
            long: 10.00831330636271,
          },
          {
            lat: 53.54530783865311,
            long: 10.008259189795123,
          },
          {
            lat: 53.54533465124405,
            long: 10.008291376303301,
          },
        ],
        center: {
          lat: 53.54530610448139,
          long: 10.00830837630331,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 1,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: 'khizar.alam@pinestack.io',
    description: '',
    lastModified: '2022-05-10T06:56:12Z',
    availableFrom: '06:00',
    availableUntil: '23:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '875d660d-7d76-4b47-9117-c057c023e274',
    version: '5efe90ae-3438-4ec2-9f8a-93ea49f271f4',
    tags: {
      'presence-topics': '["presence/poi/875d660d-7d76-4b47-9117-c057c023e274"]',
    },
    configs: {
      'presence-topic': 'presence/poi/875d660d-7d76-4b47-9117-c057c023e274',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,a798d95e-f025-4950-8ebc-bface4b387b0',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '95804a72-1e15-4abe-a522-30aad61935f0',
          type: 'image',
        },
        {
          id: 'de679c15-b0d2-4460-aaa0-080fca6ff5a8',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.02-b Cloud',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545311927223814,
            long: 10.008148861160095,
          },
          {
            lat: 53.54525782191009,
            long: 10.008276266088302,
          },
          {
            lat: 53.545232122918414,
            long: 10.008244948297342,
          },
          {
            lat: 53.54528662669857,
            long: 10.008117878645262,
          },
        ],
        center: {
          lat: 53.545273618822144,
          long: 10.008194557795811,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 12,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['bfcaea7c-17a5-4434-a729-dbcbccdc58ba'],
    },
    email: 'cloudb@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T11:02:40Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '5b1026f8-a81b-4342-81fb-daf23078835b',
    version: '927cfb4f-377a-4626-b907-4cfe1404ac9a',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'e9d52c9f-69e2-47b1-96c3-fbb974d1bd57',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Aufzug B / Etage 4',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54539483486613,
            long: 10.008137036687215,
          },
          {
            lat: 53.54537499489458,
            long: 10.008113567358334,
          },
          {
            lat: 53.54538894003873,
            long: 10.008079232082192,
          },
          {
            lat: 53.54540798313885,
            long: 10.008103371963326,
          },
        ],
        center: {
          lat: 53.54539119019282,
          long: 10.008107296194385,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: "<p>Hier geht's rauf und runter!</p>",
    lastModified: '2020-10-04T11:20:27Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'd2f149a7-8ddf-434a-860f-9a5d1b732bcc',
    version: 'c61e46a3-9339-48fb-ad85-85ff16cd044f',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'cd33e447-3340-440b-861b-43cdb5fad75a',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Aufzug B / Etage 0',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.545379647653704,
            long: 10.008166838994557,
          },
          {
            lat: 53.54535881160412,
            long: 10.00814303438955,
          },
          {
            lat: 53.5453727567536,
            long: 10.008110040217915,
          },
          {
            lat: 53.54539279593116,
            long: 10.008134180099049,
          },
        ],
        center: {
          lat: 53.54537520611927,
          long: 10.008137769053981,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: "<p>Hier geht's rauf und runter!</p>",
    lastModified: '2020-10-04T11:16:31Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '5c1b5eea-5325-471c-925e-3e1dd08b681f',
    version: 'cf77181c-0827-4603-9107-10d40bf158df',
    tags: {
      'presence-topics': '["presence/poi/5c1b5eea-5325-471c-925e-3e1dd08b681f"]',
    },
    configs: {
      'presence-topic': 'presence/poi/5c1b5eea-5325-471c-925e-3e1dd08b681f',
      'poi-locks':
        'c7f66a20-cca6-4ba0-99a8-a7c14d65bc99,bc5ce2f8-936e-42c2-b21a-581bfe7690c1,bd0196ae-8a0d-4533-bf57-4e50e6572318,af2010f0-944f-4a67-9fda-665ace93c9e9,98f3d249-84e4-485b-94c4-cbef03b7cf83',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'c2213ad3-4780-44f3-b7e6-4d7c35c99e73',
          type: 'logo',
        },
        {
          id: '59c49b1b-96e7-4a69-9d8a-1077fbcb8bff',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.04a NCA',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54551159648709,
            long: 10.007866404276452,
          },
          {
            lat: 53.545481596548704,
            long: 10.007831870835386,
          },
          {
            lat: 53.545513869508305,
            long: 10.007757637700188,
          },
          {
            lat: 53.545542275699134,
            long: 10.007792841693508,
          },
        ],
        center: {
          lat: 53.545512334563554,
          long: 10.007809841693476,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.04a@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T14:42:00Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'db6712f0-6874-4d01-b32b-ee9fc1b7861a',
    version: '654c2eab-e811-4e32-8b61-950cfbd5f9b6',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.07 Iniuva',
    locationDisplayName: '1.07 Iniuva',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54539804336592,
            long: 10.00786338679131,
          },
          {
            lat: 53.54535449662894,
            long: 10.007811754267774,
          },
          {
            lat: 53.54538557438752,
            long: 10.007732156714546,
          },
          {
            lat: 53.54543071482147,
            long: 10.007785800894844,
          },
        ],
        center: {
          lat: 53.54538862141363,
          long: 10.007792072058752,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:36:59Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f155a1a0-e870-473e-abec-97203ef5d979',
    version: 'e6787d18-3937-4358-bd1f-e2568bf13629',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '9a978063-d872-4fbc-8f98-10e6f27a2d35',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Aufzug B / Etage 1',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.545401828470766,
            long: 10.008118531923852,
          },
          {
            lat: 53.545377406521546,
            long: 10.008088692348561,
          },
          {
            lat: 53.545395335990555,
            long: 10.008044298788613,
          },
          {
            lat: 53.54541935949729,
            long: 10.008076820572919,
          },
        ],
        center: {
          lat: 53.545398183795605,
          long: 10.008081080080107,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: [],
    },
    email: '',
    description: "<p>Hier geht's rauf und runter!</p>",
    lastModified: '2020-10-04T11:17:32Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'a0789dce-287f-420a-ba7e-981e8f3efea1',
    version: '6b64e4a8-1264-47dd-adbc-53713583d710',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'caa43682-f5d1-4e97-8fee-43c37dd8df4f',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Printer Epson Level 3',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54513801070953,
            long: 10.008643393447215,
          },
          {
            lat: 53.54512394792186,
            long: 10.008627300193126,
          },
          {
            lat: 53.545133510363655,
            long: 10.008612075656256,
          },
          {
            lat: 53.545144385668834,
            long: 10.008622804492315,
          },
        ],
        center: {
          lat: 53.545134166793936,
          long: 10.008629075656224,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['88482232-8e5d-4598-b885-c026d93fd983'],
    },
    email: '',
    description:
      '<p><a href="https://epson.com/Support/Printers/All-In-Ones/WorkForce-Series/Epson-WorkForce-Enterprise-WF-C20750/s/SPT_C11CH87201" target="_blank">Download Epson Treiber</a><br></p><p>IP-Adresse:&nbsp;10.100.31.13<br></p>',
    lastModified: '2021-11-25T11:05:44Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '532597fd-c0bd-40b3-8e2b-947630bbe3ce',
    version: 'df62afa9-628b-4a57-b3aa-c5ed7294f64a',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'e5a28e0b-2027-4437-aa46-ae8cc4eda116',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Printer Canon Level 2',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.545549469840765,
            long: 10.007663690195452,
          },
          {
            lat: 53.545537000918785,
            long: 10.007661007986437,
          },
          {
            lat: 53.54554178209329,
            long: 10.007635054613466,
          },
          {
            lat: 53.54555743846225,
            long: 10.007640419031496,
          },
        ],
        center: {
          lat: 53.54554604099292,
          long: 10.007623903560864,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['88482232-8e5d-4598-b885-c026d93fd983'],
    },
    email: '',
    description:
      '<p><a href="https://www.canon.de/support/products/imagerunner/imagerunner-advance-c5535i.html?type=drivers&amp;language=&amp;os=macos%2010.15%20(catalina)" target="_blank">Download Canon Treiber</a><br></p><p>IP-Adresse:&nbsp;10.100.31.14&nbsp;<br></p>',
    lastModified: '2021-11-25T11:03:48Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f0a11c45-16a1-4b21-b854-2a3460f439d0',
    version: 'ad5de2c1-0cd1-41c6-9842-9fa7aa295ea0',
    tags: {
      'presence-topics': '["presence/poi/f0a11c45-16a1-4b21-b854-2a3460f439d0"]',
    },
    configs: {
      'presence-topic': 'presence/poi/f0a11c45-16a1-4b21-b854-2a3460f439d0',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,940c2d64-054f-4a21-9882-c4007a228e47',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '82495559-fae1-46d8-b496-644dc097128e',
          type: 'image',
        },
        {
          id: '9a434e32-41bb-4179-9fea-1065c73e70fb',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.45-b Plug and Play',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545147573148114,
            long: 10.008558233310993,
          },
          {
            lat: 53.54512175652867,
            long: 10.008620594670589,
          },
          {
            lat: 53.545069561493214,
            long: 10.008558431475958,
          },
          {
            lat: 53.54509737032133,
            long: 10.008496740668615,
          },
        ],
        center: {
          lat: 53.545110260685775,
          long: 10.008557997117329,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: 'sbahn@hammerbrookly.hamburg',
    description: '',
    lastModified: '2020-12-23T10:38:57Z',
    availableFrom: '07:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'b41280c8-194b-41f1-82e3-31ef7e6f72c0',
    version: 'c125ea3d-a9f9-47c5-b1e4-b652cebd3b6c',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Pantry Level 4 West',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54545479892863,
            long: 10.008006278997739,
          },
          {
            lat: 53.5454295801435,
            long: 10.007978115803082,
          },
          {
            lat: 53.545449501750895,
            long: 10.007923999235455,
          },
          {
            lat: 53.5454755173878,
            long: 10.007956185743634,
          },
        ],
        center: {
          lat: 53.54545175190519,
          long: 10.007969162430118,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['92be7d4e-2321-424c-872a-d02fce970ca6'],
    },
    email: '',
    description: '',
    lastModified: '2021-11-25T11:07:44Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '62091d04-8a16-4d23-b152-ac45fbbc2e63',
    version: 'ff5f000b-8c5e-4b41-8671-8bffb03bba80',
    tags: {
      'presence-topics': '["presence/poi/62091d04-8a16-4d23-b152-ac45fbbc2e63"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'ddf40e88-dcd3-4778-9ceb-fc30b3842111',
          type: 'image',
        },
        {
          id: '877cfd1e-aa12-4abc-901f-fe44ea9fe8d3',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.04 TikTok Creative Lab',
    locationDisplayName: '2.04 TikTok Creative Lab',
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54544669108594,
            long: 10.007999313740488,
          },
          {
            lat: 53.54542226916607,
            long: 10.007971150545831,
          },
          {
            lat: 53.545443585289334,
            long: 10.00792172784398,
          },
          {
            lat: 53.5454672103331,
            long: 10.007950226314763,
          },
        ],
        center: {
          lat: 53.54544364406461,
          long: 10.007962532448994,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '2.04@hammerbrooklyn.hamburg',
    description:
      '<p>The TikTok Creative Lab at Factory Hammerbrooklyn offers startups and small businesses free usage of space and equipment to create content to grow their digital presence via TikTok.</p><p>And the best part is: it is free to book!</p><p><a href="https://factoryberlin.com/tiktok/?utm_source=Factory+Internal+Newsletter&amp;utm_campaign=2eff492636-EMAIL_CAMPAIGN_2024_02_08_COPY_01&amp;utm_medium=email&amp;utm_term=0_74160234a7-2eff492636-188874570&amp;mc_cid=2eff492636&amp;mc_eid=f162839acb" target="_blank">Book here!</a></p>',
    lastModified: '2021-01-04T14:39:41Z',
    availableFrom: '06:00',
    availableUntil: '14:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'b49e36c9-789d-4d77-b3b8-f4cf0f51e38a',
    version: '4c9dfa84-866a-42ad-8b18-91f06f7818b8',
    tags: {},
    configs: {
      'poi-locks': '5166c926-48ca-4b00-9b5e-99f8632f4eb3',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.74 Enteractive',
    locationDisplayName: '0.74 Enteractive',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54519315030521,
            long: 10.008422006906876,
          },
          {
            lat: 53.5451643454637,
            long: 10.008387808741936,
          },
          {
            lat: 53.5451882515413,
            long: 10.008331680517548,
          },
          {
            lat: 53.54521625949801,
            long: 10.008365878682488,
          },
        ],
        center: {
          lat: 53.54518651735826,
          long: 10.008375502607704,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:37:28Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'fd8fcb9c-0a4e-4d5c-a868-526f25c5f57e',
    version: 'fcce3a6d-bff5-4221-91cd-8b85c4ad3b8e',
    tags: {},
    configs: {
      'poi-locks': '33c7c93e-0692-4237-87e9-bedf9ea2f719',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.78 Enteractive',
    locationDisplayName: '0.78 Enteractive',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54508358068488,
            long: 10.00869089836062,
          },
          {
            lat: 53.54505238515406,
            long: 10.008654017986665,
          },
          {
            lat: 53.54506951788976,
            long: 10.008613983016366,
          },
          {
            lat: 53.545099916537175,
            long: 10.00865086339032,
          },
        ],
        center: {
          lat: 53.54507256492934,
          long: 10.008651099583984,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['64e664f6-0417-4bf1-b4af-f5eda7e2e741'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:44:52Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '85def8f2-679a-45f9-9911-91372b8732bd',
    version: 'ebe39ae9-2e3e-4dc6-b253-5d825d53d59a',
    tags: {
      'presence-topics': '["presence/poi/85def8f2-679a-45f9-9911-91372b8732bd"]',
    },
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'd9c25555-0f2b-467b-8f8f-d2e05e5b6126',
          type: 'image',
        },
        {
          id: 'd7832c57-d238-4068-87b9-b910f85aa309',
          type: 'logo',
        },
        {
          id: 'ba5d8e38-59ec-4856-8c80-252713a1b0b8',
          type: 'attachment',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.03 Ost Terrasse',
    locationDisplayName: '4.03 Ost Terrasse',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.5453241564398,
            long: 10.00816221351004,
          },
          {
            lat: 53.54524235998604,
            long: 10.00835667366362,
          },
          {
            lat: 53.545184188581466,
            long: 10.008287804946411,
          },
          {
            lat: 53.54526837575021,
            long: 10.008094685897339,
          },
        ],
        center: {
          lat: 53.5452525354198,
          long: 10.00821994897301,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 20,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '4.03@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-07T13:13:05Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '315586d7-8cb6-49b8-9a50-33700c589f13',
    version: 'c34f2523-fecc-423a-9ae8-0d56db751593',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Pantry Level 1',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54519364698001,
            long: 10.008619099181256,
          },
          {
            lat: 53.545155678120594,
            long: 10.008581548255048,
          },
          {
            lat: 53.54518436541794,
            long: 10.008515361746895,
          },
          {
            lat: 53.545220740514814,
            long: 10.008566323718178,
          },
        ],
        center: {
          lat: 53.5451898030656,
          long: 10.0085752770911,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['92be7d4e-2321-424c-872a-d02fce970ca6'],
    },
    email: '',
    description: '',
    lastModified: '2021-11-25T11:09:45Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'c9a97b81-1e15-486c-9918-bafc9f5bc660',
    version: '0b200346-d5a9-4a8f-aeff-f693519e2b02',
    tags: {},
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '1858ca48-df56-4781-b8c7-8d1fe8c7c38c',
          type: 'logo',
        },
        {
          id: 'f2c061ea-918d-43e8-ac00-22dc8cda0309',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.02-a West',
    locationDisplayName: '4.02-a West',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54548747034055,
            long: 10.00774543417104,
          },
          {
            lat: 53.54540447890487,
            long: 10.007941235429128,
          },
          {
            lat: 53.54535626854755,
            long: 10.007883095547978,
          },
          {
            lat: 53.54544125223833,
            long: 10.007685953185383,
          },
        ],
        center: {
          lat: 53.5454625095687,
          long: 10.007779396142315,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 200,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '',
    description: '',
    lastModified: '2021-09-17T09:31:38Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '40863863-b98c-4180-a2cd-7b283c65d0de',
    version: '0fa24cdc-470b-440c-9e0d-953e0dc7faaf',
    tags: {
      'presence-topics': '["presence/poi/40863863-b98c-4180-a2cd-7b283c65d0de"]',
    },
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.14a Lab',
    locationDisplayName: '1.14a Lab',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.545299630414426,
            long: 10.00836563042935,
          },
          {
            lat: 53.5452700287595,
            long: 10.008330761712156,
          },
          {
            lat: 53.545293536343905,
            long: 10.008275304040062,
          },
          {
            lat: 53.54532273954909,
            long: 10.008310172757255,
          },
        ],
        center: {
          lat: 53.54529738024356,
          long: 10.0083231494437,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.14a@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T15:01:04Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'd395946f-370b-4aa4-b54a-dc828669340a',
    version: 'dbab092a-4be4-4e4b-b981-f30b121b4815',
    tags: {
      'presence-topics': '["presence/poi/d395946f-370b-4aa4-b54a-dc828669340a"]',
    },
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '1680df80-12c4-4523-bbe7-edc4d1584db0',
          type: 'logo',
        },
        {
          id: '123a7786-c62b-4133-8401-7f572b114bb0',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.14 Lab',
    locationDisplayName: '4.14 Lab',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.54517908308354,
            long: 10.008654032474835,
          },
          {
            lat: 53.54512716899162,
            long: 10.008775067156632,
          },
          {
            lat: 53.545099876179236,
            long: 10.008743414089505,
          },
          {
            lat: 53.54515318482666,
            long: 10.008622044131581,
          },
          {
            lat: 53.545165536305,
            long: 10.008637032474827,
          },
        ],
        center: {
          lat: 53.545144958127786,
          long: 10.008694699968647,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 8,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '4.14@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-07T13:25:47Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f8e5af9f-dbd2-4b84-a902-d8b795195eda',
    version: '1924eef5-299d-4f45-8ff9-8bf366f464c7',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '65c0b035-b980-41e4-b2bb-f34085f11a14',
          type: 'logo',
        },
        {
          id: 'fa8c963b-f2e9-4aea-935c-f13e749f879b',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.02 RedPinata',
    locationDisplayName: '0.02 RedPinata',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.545394359233,
            long: 10.008134339990029,
          },
          {
            lat: 53.545344039149356,
            long: 10.00807331973494,
          },
          {
            lat: 53.54538308556996,
            long: 10.007979640584344,
          },
          {
            lat: 53.5454338040394,
            long: 10.00804200194394,
          },
        ],
        center: {
          lat: 53.54538453885641,
          long: 10.00805430807817,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 12,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:56:47Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '0335946c-784a-48fe-96b5-06e214ad7d5e',
    version: '8a4c9dd5-97a7-4c98-9029-db0033c974ae',
    tags: {
      'presence-topics': '["presence/poi/0335946c-784a-48fe-96b5-06e214ad7d5e"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Pantry Level 4 Ost',
    locationDisplayName: 'Pantry Level 4 Ost',
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.545263551134404,
            long: 10.008455549007733,
          },
          {
            lat: 53.54523753536727,
            long: 10.00842604470857,
          },
          {
            lat: 53.54525745706505,
            long: 10.008379974767987,
          },
          {
            lat: 53.54528108221823,
            long: 10.008412161276166,
          },
        ],
        center: {
          lat: 53.54525970722949,
          long: 10.008415750231098,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['92be7d4e-2321-424c-872a-d02fce970ca6'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T14:02:54Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'ef51ec3f-c608-49e7-8543-7a053fa19243',
    version: 'eb79a905-258d-4567-a6cc-c62ff3f8d080',
    tags: {
      'presence-topics': '["presence/poi/ef51ec3f-c608-49e7-8543-7a053fa19243"]',
    },
    configs: {
      preparationTimeInMinutes: '30',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'f009ec9b-063d-4654-82c1-855f34cefd04',
          type: 'image',
        },
        {
          id: '22e9a2d8-d5de-48c6-94c4-6d1eec9dd417',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.03 Meeting Room',
    locationDisplayName: '3.03 Meeting Room',
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54564720755928,
            long: 10.007544358303363,
          },
          {
            lat: 53.54556381800744,
            long: 10.007742171218212,
          },
          {
            lat: 53.54553811920156,
            long: 10.007713535636267,
          },
          {
            lat: 53.545571646190645,
            long: 10.007640445440611,
          },
          {
            lat: 53.54563963738503,
            long: 10.007535168736776,
          },
        ],
        center: {
          lat: 53.5455788179436,
          long: 10.007678232560444,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['bfcaea7c-17a5-4434-a729-dbcbccdc58ba'],
    },
    email: '3.03@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T11:59:15Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'bae09d78-e67c-4580-bc7e-9aa244009c7f',
    version: 'b096eedd-00ba-48da-ad02-03e27129ed25',
    tags: {},
    configs: {
      'poi-locks': 'eff3f022-fffd-4367-9675-64f3ba7ac72f',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '7daaaac7-154a-47df-b138-a756502b4687',
          type: 'image',
        },
        {
          id: '49cfd601-25ab-4319-a316-d8058a4ffc3b',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.12 Blic GmbH',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54522233425163,
            long: 10.008282481949887,
          },
          {
            lat: 53.54517799046464,
            long: 10.008227496665082,
          },
          {
            lat: 53.54521026365576,
            long: 10.008147899111854,
          },
          {
            lat: 53.54525659957832,
            long: 10.008201543292152,
          },
        ],
        center: {
          lat: 53.54521570130011,
          long: 10.008215861083105,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:47:39Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'ec0188f0-9ea9-4559-aa9b-abed6ec33a22',
    version: '0c049d6a-514b-47a1-b3ed-fa92a85416f8',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '1f02b57c-a965-475a-9a47-728d42a3ba82',
          type: 'image',
        },
        {
          id: 'eb59ea43-0973-43d6-a776-c61313f18dfd',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.01 RedPinata',
    locationDisplayName: '0.01 RedPinata',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54543539776817,
            long: 10.00803710991324,
          },
          {
            lat: 53.545357046996195,
            long: 10.007945914806733,
          },
          {
            lat: 53.54531826998913,
            long: 10.008040462674508,
          },
          {
            lat: 53.54529768617186,
            long: 10.008017328621754,
          },
          {
            lat: 53.545337664220135,
            long: 10.007921774925599,
          },
          {
            lat: 53.545391054222804,
            long: 10.007793897610062,
          },
          {
            lat: 53.54548878764757,
            long: 10.007914597015732,
          },
        ],
        center: {
          lat: 53.54540366362005,
          long: 10.007923550388695,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 24,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:53:08Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '6a2354a7-1aa6-4dcf-a3ff-7b7c526ce7c8',
    version: '5b39c096-8972-46a0-8eea-846602ef9fb3',
    tags: {},
    configs: {
      'poi-locks': 'e2bd2fbd-b9cf-41d4-91aa-49de6f508c5e',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'c1d04556-0897-4e60-a9af-213ebf256c7c',
          type: 'image',
        },
        {
          id: 'd94ceb57-62bc-428b-9aa8-fb459f184748',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.76 Art Invest / Youth Innovation Center',
    locationDisplayName: '0.76 Art Invest / Youth Innovation Center',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54512621326173,
            long: 10.008584951104531,
          },
          {
            lat: 53.54509661150392,
            long: 10.008549411835084,
          },
          {
            lat: 53.54511733013845,
            long: 10.008500659685486,
          },
          {
            lat: 53.545146533446825,
            long: 10.00853552840268,
          },
        ],
        center: {
          lat: 53.54511639282296,
          long: 10.008543140671135,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['64e664f6-0417-4bf1-b4af-f5eda7e2e741'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:41:06Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '5a6bc4a9-21be-4110-aa2e-70edafde8371',
    version: 'fde0cadf-bb4e-4a1d-8368-d6642de77431',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/5a6bc4a9-21be-4110-aa2e-70edafde8371',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Pantry Level 2',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54525982602757,
            long: 10.00846165951943,
          },
          {
            lat: 53.545231419659,
            long: 10.008429473011251,
          },
          {
            lat: 53.54524895075608,
            long: 10.008379379757146,
          },
          {
            lat: 53.54528054458217,
            long: 10.008411566265325,
          },
        ],
        center: {
          lat: 53.54525757586052,
          long: 10.008417837429272,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['92be7d4e-2321-424c-872a-d02fce970ca6'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T13:55:33Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '865889fe-eb3c-4adb-b3d4-0414ca378522',
    version: 'ce814cb1-9065-458e-b294-eac6baced0a3',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '051856aa-7d9d-49fc-ab52-e92f0f51eb37',
          type: 'image',
        },
        {
          id: '7d333aac-ba1a-4398-af04-02628e5ee00e',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.80 Studio 5',
    locationDisplayName: '0.80 Studio 5',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54510868211381,
            long: 10.00882970267714,
          },
          {
            lat: 53.54506154917647,
            long: 10.008774717392335,
          },
          {
            lat: 53.54509262715003,
            long: 10.008701825361603,
          },
          {
            lat: 53.545138963182936,
            long: 10.008758151750916,
          },
        ],
        center: {
          lat: 53.54509766636488,
          long: 10.00876375236261,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 15,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['64e664f6-0417-4bf1-b4af-f5eda7e2e741'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:47:42Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '49263f4f-6ce3-41f6-9c32-15e470caa246',
    version: 'f7709ec9-1f35-4fe6-a648-3e0b2927c8d8',
    tags: {
      'presence-topics': '["presence/poi/49263f4f-6ce3-41f6-9c32-15e470caa246"]',
    },
    configs: {
      'presence-topic': 'presence/poi/49263f4f-6ce3-41f6-9c32-15e470caa246',
      'poi-locks':
        '20b9d549-c218-40f4-819b-bda83c031cd3,a8c8bcaf-2b5c-4501-a6de-80cbf174a7a4,a26019b2-bb1d-4808-a523-ef6968987305,29d542ff-bb92-4dbc-9168-cca8c2a27d3c,618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,508eb817-b7a8-41a5-8bc7-14209cdb0cb7',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '1e6a6dd2-a9de-4000-aa41-7b1e974c5a71',
          type: 'image',
        },
        {
          id: '727b9abd-572c-4a6e-8410-6308372af75d',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.43 For Real?!',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545451577882396,
            long: 10.008005698253925,
          },
          {
            lat: 53.54542735517746,
            long: 10.007977199783141,
          },
          {
            lat: 53.54545305405059,
            long: 10.007916712969145,
          },
          {
            lat: 53.54547687830903,
            long: 10.007946217268309,
          },
        ],
        center: {
          lat: 53.54545211674784,
          long: 10.007962546716023,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.43@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T07:06:34Z',
    availableFrom: '07:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '6c6ede9f-1113-4cfb-a42d-12b05ffcd30a',
    version: 'c37be535-b119-4536-84bd-fdb5ad90ae10',
    tags: {
      'presence-topics': '["presence/poi/6c6ede9f-1113-4cfb-a42d-12b05ffcd30a"]',
    },
    configs: {
      'presence-topic': 'presence/poi/6c6ede9f-1113-4cfb-a42d-12b05ffcd30a',
      'poi-locks':
        'c7f66a20-cca6-4ba0-99a8-a7c14d65bc99,bc5ce2f8-936e-42c2-b21a-581bfe7690c1,bd0196ae-8a0d-4533-bf57-4e50e6572318,af2010f0-944f-4a67-9fda-665ace93c9e9,c7fc539d-8712-456c-b993-f7e8ac17a38a',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '3f25271e-985b-4f3c-9d8c-855b10bf483b',
          type: 'logo',
        },
        {
          id: '8d50d04b-e39b-4f7d-a0e1-90f83188a19f',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.14b EnglishBusiness AG',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54526456825495,
            long: 10.008446096699796,
          },
          {
            lat: 53.54523616187776,
            long: 10.008413239639363,
          },
          {
            lat: 53.54526723972323,
            long: 10.00833967705642,
          },
          {
            lat: 53.54529445077907,
            long: 10.00837588687812,
          },
        ],
        center: {
          lat: 53.54526231808228,
          long: 10.00839489853485,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 4,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '1.14b@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-05T15:20:28Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '06e82361-5dd4-4ecc-b381-29d214f49ef8',
    version: '1942dd56-3a8d-42ca-9fe6-d48b6b590520',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.19 Nord Events Storage',
    locationDisplayName: '1.19 Nord Events Storage',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54561757912555,
            long: 10.007621317427716,
          },
          {
            lat: 53.54565292185554,
            long: 10.00754085115727,
          },
          {
            lat: 53.545619453710465,
            long: 10.007494781216728,
          },
          {
            lat: 53.545584110952525,
            long: 10.007580611905205,
          },
        ],
        center: {
          lat: 53.54561931327622,
          long: 10.007565425396994,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['135f7873-5ced-4c6c-b5b6-95388e4292f6'],
    },
    email: '',
    description: '<p>Under construction&nbsp;</p>',
    lastModified: '2021-06-08T12:15:37Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'd4a66b1f-facf-43b6-95c9-796f29b1bc8c',
    version: 'f5dd3cd8-94c7-41af-9b21-d60a6b3bde5e',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'c4069a06-9372-45de-8523-3d2bba0dc01c',
          type: 'logo',
        },
        {
          id: '24d5aa0f-591d-4880-981d-b6fa6596281f',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.11 MHP',
    locationDisplayName: '1.11 MHP',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.545282896205606,
            long: 10.008231519978604,
          },
          {
            lat: 53.54521305269592,
            long: 10.008147030394635,
          },
          {
            lat: 53.54524652116211,
            long: 10.00806072731887,
          },
          {
            lat: 53.54531716148326,
            long: 10.008146558007347,
          },
        ],
        center: {
          lat: 53.545257536876235,
          long: 10.008146123648718,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:45:23Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'ebbd1f67-32d5-464a-b3fd-67f91ac688a4',
    version: '8ec83f71-01e0-422d-9967-237641bbe210',
    tags: {},
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.08 Enteractive',
    locationDisplayName: '1.08 Enteractive',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54536329079398,
            long: 10.007946979011336,
          },
          {
            lat: 53.54531854872139,
            long: 10.007891993726531,
          },
          {
            lat: 53.54535161867128,
            long: 10.007812396173303,
          },
          {
            lat: 53.54539715757404,
            long: 10.007866710905855,
          },
        ],
        center: {
          lat: 53.54535555149259,
          long: 10.007883267165258,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:38:46Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'f57539bf-5038-474c-bff5-a574d8ea20ba',
    version: '4bbc38ac-6c39-49d2-8da5-68ad1d8240eb',
    tags: {
      'presence-topics': '["presence/poi/f57539bf-5038-474c-bff5-a574d8ea20ba"]',
    },
    configs: {
      'presence-topic': 'presence/poi/f57539bf-5038-474c-bff5-a574d8ea20ba',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,4ffce48b-78e4-4708-9df2-1cce113a2039',
      preparationTimeInMinutes: '15',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '25cb2891-7f82-444a-9759-f27adfa2a956',
          type: 'logo',
        },
        {
          id: '778819d9-a4f9-4fbf-9446-012eda99421c',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.02-a Cloud',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.5453675086396,
            long: 10.00801575653773,
          },
          {
            lat: 53.545315196346856,
            long: 10.00814182036143,
          },
          {
            lat: 53.54528929817323,
            long: 10.008113184779486,
          },
          {
            lat: 53.54534400109631,
            long: 10.00798779150804,
          },
        ],
        center: {
          lat: 53.54533059480431,
          long: 10.008064470658589,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 14,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['bfcaea7c-17a5-4434-a729-dbcbccdc58ba'],
    },
    email: 'khizar.alam@pinestack.io',
    description: '',
    lastModified: '2020-12-23T11:00:22Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'bee02ecb-fa87-45de-a02e-9df13b3a6e12',
    version: 'd2ce113f-8fd1-485f-8679-44584f8f0fa8',
    tags: {
      'presence-topics': '["presence/poi/bee02ecb-fa87-45de-a02e-9df13b3a6e12"]',
    },
    configs: {
      'presence-topic': 'presence/poi/bee02ecb-fa87-45de-a02e-9df13b3a6e12',
      'poi-locks':
        '20b9d549-c218-40f4-819b-bda83c031cd3,a8c8bcaf-2b5c-4501-a6de-80cbf174a7a4,a26019b2-bb1d-4808-a523-ef6968987305,29d542ff-bb92-4dbc-9168-cca8c2a27d3c,618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,72770d5f-b815-4319-91f6-9599167af638',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '8a2e3440-0bbb-418f-b101-5f4127c181cb',
          type: 'logo',
        },
        {
          id: '2d287e16-b0f0-442b-b319-3002a6ee46ee',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.10 Lab',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.545165502714674,
            long: 10.008680944373424,
          },
          {
            lat: 53.54511159643139,
            long: 10.008807008197124,
          },
          {
            lat: 53.54508569813319,
            long: 10.00877501985391,
          },
          {
            lat: 53.545139604449446,
            long: 10.008649626582464,
          },
        ],
        center: {
          lat: 53.54512779183308,
          long: 10.008728317389775,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 8,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: 'expedition3@hammerbrooklyn.hamburg',
    description: '<p><i>&nbsp; &nbsp;</i></p>',
    lastModified: '2020-12-23T11:10:56Z',
    availableFrom: '08:00',
    availableUntil: '18:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '88eb9395-36c4-4bff-9353-95c515aa1541',
    version: 'a8cc9709-7dff-48d5-9473-ead43a3eff0c',
    tags: {},
    configs: {
      'poi-locks': '4d07a3d2-0096-4cbc-b2fb-0ab06d9bbbb8',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '9ab19ae2-c27b-4dee-a6d4-3ca4bd801585',
          type: 'logo',
        },
        {
          id: '00407601-7638-4f31-809d-0267920d4fd1',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.75 Meeting Room',
    locationDisplayName: '0.75 Meeting Room',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54511685003591,
            long: 10.008499120416054,
          },
          {
            lat: 53.54514083780766,
            long: 10.008442123474488,
          },
          {
            lat: 53.54516315015947,
            long: 10.008386330526227,
          },
          {
            lat: 53.545194146391374,
            long: 10.008422875624055,
          },
          {
            lat: 53.54514752953423,
            long: 10.00853609987247,
          },
        ],
        center: {
          lat: 53.545155240236156,
          long: 10.008458315811039,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 18,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['bfcaea7c-17a5-4434-a729-dbcbccdc58ba'],
    },
    email: 'lucia.heffner@factoryberlin.com',
    description: '',
    lastModified: '2022-02-28T10:38:58Z',
    availableFrom: '08:00',
    availableUntil: '21:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '1f27a3bd-89e4-49c4-aa91-cdc2f8599151',
    version: '593f1518-5c77-452f-835c-80f1830202a7',
    tags: {},
    configs: {
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '1.13 Enteractive',
    locationDisplayName: '1.13 Enteractive',
    locations: [
      {
        floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
        polygon: [
          {
            lat: 53.54518805591006,
            long: 10.008366187634152,
          },
          {
            lat: 53.54514211834746,
            long: 10.008311202349347,
          },
          {
            lat: 53.5451771806083,
            long: 10.008226910930341,
          },
          {
            lat: 53.545221922830315,
            long: 10.008281896215147,
          },
        ],
        center: {
          lat: 53.54517824846358,
          long: 10.008295656801298,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['baee4f36-eb11-4722-975f-3c3be409d0bd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: null,
    description: '',
    lastModified: '2023-02-13T10:49:19Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '8e920bbe-041f-46e9-a845-0a989a1160cf',
    version: '341bdb58-77e5-4b29-bc08-d3c97f96558c',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/8e920bbe-041f-46e9-a845-0a989a1160cf',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Treppenhaus A',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54541864673959,
            long: 10.00782280565334,
          },
          {
            lat: 53.545396615404606,
            long: 10.007794642458684,
          },
          {
            lat: 53.5454117558389,
            long: 10.007761983563176,
          },
          {
            lat: 53.545432193437144,
            long: 10.007788805653325,
          },
        ],
        center: {
          lat: 53.54541445172725,
          long: 10.007786734949157,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T16:07:10Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'b55a56ac-be16-4164-9816-9730ebe71525',
    version: '9482f161-f31f-4381-8937-992cc41fe1e0',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'a9737859-390c-497f-8972-27dcb9fdb991',
          type: 'image',
        },
        {
          id: 'de234bcd-e769-4eda-b535-8bcc1e9b17b4',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.73 Lab',
    locationDisplayName: '0.73 Lab',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54522622035308,
            long: 10.008544047417054,
          },
          {
            lat: 53.54517948598104,
            long: 10.008490403236756,
          },
          {
            lat: 53.5452029936157,
            long: 10.008434275012368,
          },
          {
            lat: 53.545248931093866,
            long: 10.00848858974492,
          },
        ],
        center: {
          lat: 53.545211220291904,
          long: 10.008491508147598,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 12,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:32:10Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '87d04688-fd5c-4ca7-a25c-5c6002228453',
    version: '0ffcc34c-efcb-4fac-a529-2988309c3d87',
    tags: {
      'presence-topics': '["presence/poi/87d04688-fd5c-4ca7-a25c-5c6002228453"]',
    },
    configs: {},
    files: {
      type: 'files',
      entities: [
        {
          id: 'e04ab7a7-68ee-42a9-bb8d-58866153fba6',
          type: 'logo',
        },
        {
          id: '8dac9e94-ddbf-439f-8142-59810970c261',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.06 FH Fixed Desks',
    locationDisplayName: '2.06 FH Fixed Desks',
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.5451436213506,
            long: 10.008750660030751,
          },
          {
            lat: 53.545112824292765,
            long: 10.00881905636063,
          },
          {
            lat: 53.545080949462914,
            long: 10.00878328089752,
          },
          {
            lat: 53.54514071474926,
            long: 10.008645581491841,
          },
          {
            lat: 53.54517230865611,
            long: 10.00868313241805,
          },
        ],
        center: {
          lat: 53.54512927996291,
          long: 10.008724950361477,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 12,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '2.06@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-07T13:30:35Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '92d2a842-d0fc-45b1-b49b-9134c44c3749',
    version: '0bf0dada-2e5f-4406-ae5b-ad9411b49674',
    tags: {
      'presence-topics': '["presence/poi/92d2a842-d0fc-45b1-b49b-9134c44c3749"]',
    },
    configs: {
      'presence-topic': 'presence/poi/92d2a842-d0fc-45b1-b49b-9134c44c3749',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,56d837f9-1183-42a0-bcee-61f4fe6b36bc',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '7bab2846-5c3a-4fa4-b3c3-d216b53c1434',
          type: 'logo',
        },
        {
          id: '63a6ad62-a5c0-4b5a-bf13-694c931f3f3b',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.45-a Plug and Play',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54518243618718,
            long: 10.008494866123016,
          },
          {
            lat: 53.54515661958904,
            long: 10.008554880549724,
          },
          {
            lat: 53.54510063946099,
            long: 10.008486347108683,
          },
          {
            lat: 53.54512526078796,
            long: 10.008426667958101,
          },
        ],
        center: {
          lat: 53.54513894801393,
          long: 10.008494965205479,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: 's-bahn@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2020-12-23T10:19:44Z',
    availableFrom: '07:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '11df972e-2e3f-4233-8af9-9203b34be8ec',
    version: 'c1414157-1df5-4a42-b109-6e32a17e423a',
    tags: {
      'presence-topics': '["presence/poi/11df972e-2e3f-4233-8af9-9203b34be8ec"]',
    },
    configs: {
      'presence-topic': 'presence/poi/11df972e-2e3f-4233-8af9-9203b34be8ec',
      'poi-locks':
        'b95a8ad8-9369-4245-9fd3-6d8cd95b922a,b06d3357-b30b-4aeb-ae3e-ecf62464381d,29102584-7710-4f9a-a2b6-9deea5fcd2aa,43ef3817-60ee-4771-8059-c6178520f9bf,9ef9ac19-2ab4-44dc-be21-20b9f0642fa0',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '1b67123d-0c03-4091-8629-958178d705e8',
          type: 'logo',
        },
        {
          id: 'aaca50e9-2aa3-4783-8e7b-7bd320225d3b',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.09 shades&contrast GmbH',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.545405453345474,
            long: 10.008099896578546,
          },
          {
            lat: 53.54538123061819,
            long: 10.00807072755551,
          },
          {
            lat: 53.54539198829963,
            long: 10.008044774182538,
          },
          {
            lat: 53.54541680866933,
            long: 10.008074278481702,
          },
        ],
        center: {
          lat: 53.545397824347226,
          long: 10.008074514675366,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 2,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '2.09@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T14:36:14Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'd601270d-21b5-467f-aa7f-379de0c7dd85',
    version: 'b35b8eb2-b806-47d4-9965-93f1559e91ab',
    tags: {},
    configs: {
      'poi-locks': '19db478f-1ac0-4886-87e0-8d1a73018b2a',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Behinderten Toiletten Level 0 Treppenhaus B',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54532187487446,
            long: 10.008089390209252,
          },
          {
            lat: 53.54528709361638,
            long: 10.00804781596952,
          },
          {
            lat: 53.54529904662219,
            long: 10.00802186259655,
          },
          {
            lat: 53.545333827870444,
            long: 10.00806343683628,
          },
        ],
        center: {
          lat: 53.545311656047275,
          long: 10.008060320268676,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '<p>Sie finden unsere Behinderten Toiletten im Erdgeschoss Treppenhaus B<span style="font-size: inherit;">&nbsp;</span></p>',
    lastModified: '2021-01-07T16:08:55Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'ac049029-7f2a-4958-bc7a-6ad1352b7148',
    version: 'ba6d4a9e-e207-4ce4-9dfa-84cd246a6764',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/ac049029-7f2a-4958-bc7a-6ad1352b7148',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Level 4 West',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.545476314251424,
            long: 10.007951964265187,
          },
          {
            lat: 53.545450556611705,
            long: 10.007924136346658,
          },
          {
            lat: 53.54545919311741,
            long: 10.007902511036475,
          },
          {
            lat: 53.54545189234317,
            long: 10.00789295566686,
          },
          {
            lat: 53.54543555662671,
            long: 10.007872366711918,
          },
          {
            lat: 53.54545991976371,
            long: 10.007815034494225,
          },
          {
            lat: 53.545507790352005,
            long: 10.007879072234456,
          },
        ],
        center: {
          lat: 53.54549000136306,
          long: 10.007890707816433,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T14:56:24Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: 'e1757257-feec-40aa-bcb9-73f8a6efab8c',
    version: 'f9f556c6-6c27-46e8-8d6d-7af2109bbf65',
    tags: {
      'presence-topics': '["presence/poi/e1757257-feec-40aa-bcb9-73f8a6efab8c"]',
    },
    configs: {
      'presence-topic': 'presence/poi/e1757257-feec-40aa-bcb9-73f8a6efab8c',
      'poi-locks':
        '618916ee-78f5-44f2-b5cd-c9bfe982095f,dafb91c3-3c95-43d1-b04a-95f97b42a7c8,ec708e31-39c2-4723-a3dd-ecc085c55d50,4d3b0a52-7f8b-447c-bdbe-b282bc197c50,0d9824df-0927-4d1a-a5f2-35db73ba31dc',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '5db35bbe-95eb-4320-8eb1-17f97f98469a',
          type: 'image',
        },
        {
          id: 'ea93f05b-1dc7-4988-8f73-28f200714fce',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '3.01b Aurubis',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'de907753-e052-49d7-a962-c287630367fd',
        polygon: [
          {
            lat: 53.54518343227363,
            long: 10.008489836981113,
          },
          {
            lat: 53.54512773305534,
            long: 10.00842177592736,
          },
          {
            lat: 53.54515383054524,
            long: 10.00836263021787,
          },
          {
            lat: 53.54520853364335,
            long: 10.008430355995497,
          },
        ],
        center: {
          lat: 53.54516803375729,
          long: 10.00843394495039,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 6,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['de907753-e052-49d7-a962-c287630367fd'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '3.01@hammerbrooklyn.pavillon',
    description: '',
    lastModified: '2020-12-23T10:17:09Z',
    availableFrom: '08:00',
    availableUntil: '19:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '941165a6-1ede-47dd-8ae8-c1e78bef1f95',
    version: '28419bf8-8559-433f-82be-bdec6eb4346c',
    tags: {},
    configs: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '0.79 Enteractive',
    locationDisplayName: '0.79 Enteractive',
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.5450622643803,
            long: 10.008775052668462,
          },
          {
            lat: 53.54502150636895,
            long: 10.00872710818232,
          },
          {
            lat: 53.545051986718285,
            long: 10.008654886703843,
          },
          {
            lat: 53.5450921470472,
            long: 10.00870216063773,
          },
        ],
        center: {
          lat: 53.54505682672013,
          long: 10.008714131495834,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['64e664f6-0417-4bf1-b4af-f5eda7e2e741'],
    },
    email: '',
    description: '',
    lastModified: '2022-02-28T10:46:18Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '0a8da651-4563-469c-840f-2fcb913fad90',
    version: '5cd4176a-eff6-4c98-9b44-9568fc84790d',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/0a8da651-4563-469c-840f-2fcb913fad90',
      'poi-locks':
        'b14fb049-9c1e-4236-a49d-a280b1a511b8,e30adac7-8cfa-4a58-85f3-4ba3d364350f,9cee37b5-980a-441d-9151-97aac1cca814,ff9b5489-0bff-4068-8530-bfcc014168ce,2890fd7c-1cd2-42df-beaf-5fac1160a46d,8667f7cd-7549-49fc-8277-e6cddb4e2fa7',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'cd7fdc39-31f1-4696-a7d0-0013d72014ec',
          type: 'image',
        },
        {
          id: 'e12efd67-0ab2-49f6-b02b-0c9591484681',
          type: 'logo',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '4.01-a Ost',
    locationDisplayName: null,
    locations: [
      {
        floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
        polygon: [
          {
            lat: 53.545192629857766,
            long: 10.008470301157315,
          },
          {
            lat: 53.54513633299764,
            long: 10.008398552066167,
          },
          {
            lat: 53.545182949867275,
            long: 10.008289450213734,
          },
          {
            lat: 53.54524084040149,
            long: 10.008359858200375,
          },
          {
            lat: 53.54519243064057,
            long: 10.008471406068157,
          },
          {
            lat: 53.54519332711816,
            long: 10.008470183060503,
          },
        ],
        center: {
          lat: 53.54518719220835,
          long: 10.00837819930489,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 10,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['213d352e-abd0-499d-85ac-16f9aaf0a741'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd'],
    },
    email: '4.01@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-07T13:20:41Z',
    availableFrom: '08:00',
    availableUntil: '20:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '5e306f9c-b4d8-4e47-9ff6-66dc63fd5d56',
    version: '600db07f-1596-405d-b5f6-9f5b57de8760',
    tags: {
      'presence-topics': '["presence/poi/5e306f9c-b4d8-4e47-9ff6-66dc63fd5d56"]',
    },
    configs: {
      'presence-topic': 'presence/poi/5e306f9c-b4d8-4e47-9ff6-66dc63fd5d56',
      'poi-locks':
        'b95a8ad8-9369-4245-9fd3-6d8cd95b922a,b06d3357-b30b-4aeb-ae3e-ecf62464381d,29102584-7710-4f9a-a2b6-9deea5fcd2aa,43ef3817-60ee-4771-8059-c6178520f9bf,6ede1afe-64fb-49aa-b881-ef7833d0f1a2',
      'room-control': 'true',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '49fffff3-6b3a-4e61-a433-97f593fc30b9',
          type: 'logo',
        },
        {
          id: 'bebd4ac3-e292-4c12-8b40-6bad72d85e3a',
          type: 'attachment',
        },
        {
          id: '91dc5360-59ea-491a-b173-dd6093f7d1d0',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: '2.03 camm solutions',
    locationDisplayName: null,
    locations: [
      {
        floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
        polygon: [
          {
            lat: 53.54563873491314,
            long: 10.007530932991262,
          },
          {
            lat: 53.54555654064179,
            long: 10.007732098667379,
          },
          {
            lat: 53.545531240262754,
            long: 10.0077048041899,
          },
          {
            lat: 53.54556038451435,
            long: 10.007633055098752,
          },
          {
            lat: 53.545630766306964,
            long: 10.007522078700761,
          },
        ],
        center: {
          lat: 53.545550822166334,
          long: 10.007693640995251,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 12,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['365a50d0-a10a-4586-b79d-3a22577f85ca'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['cec94c70-0f3b-44a1-b5f9-11e030a6d542'],
    },
    email: '2.03@hammerbrooklyn.hamburg',
    description: '',
    lastModified: '2021-01-04T14:30:25Z',
    availableFrom: '08:00',
    availableUntil: '21:00',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
  {
    id: '57d04fcd-1bbf-4ef3-aefd-9af2daf6cd7d',
    version: 'f698315a-02b6-4ee2-b63c-30ac8f144d3b',
    tags: {},
    configs: {
      'presence-topic': 'presence/poi/57d04fcd-1bbf-4ef3-aefd-9af2daf6cd7d',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Toiletten Treppenhaus B',
    locationDisplayName: null,
    locations: [
      {
        floorId: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
        polygon: [
          {
            lat: 53.54533303100422,
            long: 10.008062568119103,
          },
          {
            lat: 53.54528549988199,
            long: 10.008177903106743,
          },
          {
            lat: 53.5452723515712,
            long: 10.008158003718446,
          },
          {
            lat: 53.5452607969917,
            long: 10.008186384092376,
          },
          {
            lat: 53.545240875295505,
            long: 10.008162678569832,
          },
          {
            lat: 53.54529876573931,
            long: 10.008019180387535,
          },
        ],
        center: {
          lat: 53.54528615630509,
          long: 10.008109941135451,
        },
      },
    ],
    active: true,
    bookable: true,
    capacity: 0,
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    campus: {
      type: 'campuses',
      id: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    },
    floors: {
      type: 'floors',
      ids: ['adca44eb-e1ba-4653-b60d-9c6b44ba5196'],
    },
    bookableType: 'Room',
    poiCategories: {
      type: 'poicategories',
      ids: ['5f175c67-4032-4ec5-a1e8-8c74ce1b0536'],
    },
    email: '',
    description: '',
    lastModified: '2021-01-07T16:03:55Z',
    priceModel: null,
    reverseBookable: false,
    ownerEmail: null,
    buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    type: 'pois',
  },
]

export const getPoiById = id => {
  let poi = pois.find(poi => poi.id === id)
  if (poi) return poi
  else return null
}

export const getPoisByCategoryId = id => {
  let poisByCategory = pois.filter(poi => poi.poiCategories.ids.includes(id))
  if (poisByCategory) return poisByCategory
  else return null
}
