<template>
    <div class="functions">
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon>
                <v-icon size="40" @click="goBack">
                    mdi-arrow-left-bold-circle
                </v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Functions' }}
            </v-toolbar-title>
            <img :src="logo" class="toolbar-icon" />
        </v-toolbar>

        <!-- Pricing Options Section -->
        <div class="plans-container functions-container">
            <div class="plans-header">
                <h1>Function Plans</h1>
                <p style="color: #555;">Select the plan tailored to your business growth</p>
            </div>
            <div class="plans">
                <div class="plan" v-for="plan in plans" :key="plan.id" :class="{ 'business-plan': plan.id === 2 }">
                    <div v-if="plan.id === 2" class="most-popular-badge">Most Popular</div>
                    <h2>{{ plan.name }}</h2>
                    <!-- Highlighted Description -->
                    <p v-if="plan.id === 2" class="plan-description highlight">
                        Includes all Starter features, plus advanced tools for seamless business management.
                    </p>
                    <ul>
                        <li v-for="feature in plan.features" :key="feature">
                            <v-icon left class="feature-tick">mdi-check-circle-outline</v-icon>
                            {{ feature }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getToolbarLogoImage } from "@/assets/Branding/branding";

export default defineComponent({
    name: "Functions",
    setup() {
        const logo = ref("");
        const router = useRouter();

        const plans = ref([
            {
                id: 1,
                name: "Starter",
                features: [
                    "Progressive Web App (PWA)",
                    "User Authentication",
                    "Announcements & Communication Tools",
                    "Basic Booking System",
                    "AI-Assisted Chat Support (Limited)",
                    "Analytics Overview (Limited)",
                    "User Tools: Feedback, Profile Updates, Incident Reporting",
                    "Multilingual App Settings with Privacy Policy",
                    "Admin Tools: Manage Feedbacks & Bookings",
                ],
            },
            {
                id: 2,
                name: "Business",
                features: [
                    "Native Mobile Apps (Android & iOS)",
                    "AI-Driven Motivation Center",
                    "Seamless Outlook Integration",
                    "Advanced Workspace Management Tools",
                    "AI-Powered Full Chat Support",
                    "Detailed Business Analytics",
                    "Enhanced User Tools: Vouchers, Intelligent Chatbot",
                    "Admin Tools: User & Incident Management",
                ],
            },
        ]);

        onMounted(async () => {
            logo.value = await getToolbarLogoImage();
        });

        const goBack = () => {
            router.go(-1);
        };

        return {
            logo,
            plans,
            goBack,
        };
    },
});
</script>

<style scoped>
/* Global Styling */
.functions {
    padding: 1rem;
}

/* Toolbar Styles */
.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}

/* Plans Container */
.plans-container {
    margin-top: 2rem;
    text-align: center;
}

.plans-header {
    margin-bottom: 2rem;
}

/* Plan Cards */
.plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.plan {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    text-align: left;
    position: relative;
}

.business-plan {
    border: 2px solid #007bff;
}

.most-popular-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 0.9rem;
}

.plan h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.plan-description {
    margin: 10px 0;
    color: #333;
    font-weight: 500;
    font-size: 1.1rem;
}

.highlight {
    font-weight: bold;
    color: #007bff;
    border-left: 4px solid #007bff;
    padding-left: 10px;
}

/* Feature List */
.plan ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.plan li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.feature-tick {
    color: lightblue;
    margin-right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
    .plans {
        justify-content: space-around;
    }

    .plan {
        max-width: 280px;
    }
}

@media (max-width: 768px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .plan {
        width: 90%;
    }

    .highlight {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .plan {
        width: 100%;
    }

    .plan h2 {
        font-size: 1.2rem;
    }

    .highlight {
        font-size: 0.9rem;
    }
}
</style>

<!--
Functions Component

This component provides users with a user interface to view and select different function plans for their business needs. The main features of this component include a toolbar, a logo, and a section displaying various plans with their respective features.

Toolbar
The toolbar at the top of the component includes a back button represented by an icon. When users click this icon, they are navigated back to the previous page. The toolbar also displays the title "Functions" and a logo image. The logo is dynamically loaded when the component is mounted.

Plans Section
Below the toolbar, users can see the "Functions Options" section, which includes a header with a title and a description. This section helps users understand that they need to choose a plan that best fits their business requirements.

Plan Cards
The plans are displayed as cards in a responsive layout. Each card represents a different plan and includes the plan name and a list of features. The features are displayed with a checkmark icon to indicate that they are included in the plan. The "Business" plan is highlighted with a "Most Popular" badge to attract users' attention.

Visual Feedback
The component provides visual feedback through the use of icons, badges, and styling. For example, the "Most Popular" badge is styled to stand out, and the checkmark icons next to the features provide a clear indication of what is included in each plan. The toolbar and plan cards have a modern design with shadows and rounded corners to enhance the user experience.

Dynamic Behavior
The logo image is dynamically loaded when the component is mounted using the onMounted lifecycle hook. The back button uses the Vue Router's go method to navigate back to the previous page.

Overall, this component offers a clean and intuitive interface for users to explore and select different function plans, making it easy for them to understand the features and benefits of each plan.
-->
