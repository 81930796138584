<template>
  <div class="profile-container">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Profile' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container fluid class="d-flex justify-center pa-4">
      <v-card class="profile-card" elevation="3">
        <!-- Gradient Header with Profile Info -->
        <div class="header-gradient d-flex align-center pa-4 flex-wrap-on-small">
          <v-avatar class="mr-4 responsive-avatar">
            <v-img :src="user.profileImage" alt="Profile Image" />
            <v-btn v-if="isEditing" class="camera-btn" icon @click="openFileDialog">
              <v-icon class="camera-icon">mdi-camera</v-icon>
            </v-btn>
          </v-avatar>
          <input ref="fileInput" type="file" @change="onFileChange" style="display: none" />
          <div class="profile-info flex-grow-1">
            <h3 class="responsive-name">{{ user.name }}</h3>
            <p class="responsive-email">{{ user.email }}</p>
          </div>

          <div class="text-center pa-4">
            <v-dialog v-model="showCropper" max-width="400" persistent>
              <v-card>
                <cropper :stencil-props="{ aspectRatio: 1 }" :src="selectedImage" class="cropper" @crop="onCropComplete"
                  @change="onCropComplete" />
                <v-card-actions class="d-flex justify-end" style="background-color: black">
                  <v-btn color="red" prepend-icon="mdi-close" slim text="Cancel" variant="tonal" class="me-2 text-none"
                    @click="cancelCrop" flat></v-btn>
                  <v-btn color="success" prepend-icon="mdi-content-save" slim text="Save" variant="tonal"
                    class="me-2 text-none" @click="saveCroppedImage" flat></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <v-btn v-if="!isEditing" prepend-icon="mdi-pencil" class="responsive-btn mt-2-on-small" variant="tonal"
            color="primary" @click="toggleEdit">
            Edit
          </v-btn>
          <v-btn v-else prepend-icon="mdi-content-save" class="responsive-btn mt-2-on-small" variant="tonal"
            color="primary" @click="saveProfile()">
            Save
          </v-btn>
        </div>

        <v-divider class="mt-3"></v-divider>
        <!-- Form Section -->
        <v-card-text>
          <v-form class="mt-4 d-flex">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field class="elegant-input" :variant="getTextBoxVariant()" v-model="user.firstName"
                  label="First Name" :readonly="!isEditing" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field class="elegant-input" :variant="getTextBoxVariant()" v-model="user.lastName"
                  label="Last Name" :readonly="!isEditing" />
              </v-col>
              <v-col cols="12">
                <div class="email-field">
                  <v-btn class="email-icon" icon>
                    <v-icon size="24" class="responsive-icon" color="white">mdi-email</v-icon>
                  </v-btn>
                  <v-text-field class="elegant-input responsive-text" :variant="getTextBoxVariant()"
                    v-model="user.email" label="My Email Address" :rules="[emailRules]" :readonly="!isEditing" />
                </div>
              </v-col>
              <v-col cols="12">
                <div class="email-field">
                  <v-btn class="email-icon" icon style="background-color: brown">
                    <v-icon size="24" class="responsive-icon" color="white">mdi-briefcase-outline</v-icon>
                  </v-btn>
                  <v-text-field class="elegant-input responsive-text" :variant="getTextBoxVariant()"
                    v-model="user.jobTitle" label="Job Position" :readonly="!isEditing" />
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="field-with-icon">
                  <v-btn class="field-icon" icon style="background-color: yellowgreen !important; margin-bottom: 1rem">
                    <v-icon size="24" class="responsive-icon" color="white">mdi-domain</v-icon>
                  </v-btn>
                  <v-text-field class="elegant-input" variant="solo" v-model="user.campus.name" label="Campus Name"
                    readonly disabled />
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="field-with-icon">
                  <v-btn class="field-icon" icon style="background-color: darkcyan !important; margin-bottom: 1rem">
                    <v-icon size="24" class="responsive-icon" color="white">mdi-office-building</v-icon>
                  </v-btn>
                  <v-text-field class="elegant-input" variant="solo" v-model="user.building.name" label="Building Name"
                    readonly disabled />
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-with-icon">
                  <v-btn class="field-icon" icon style="background-color: tomato !important; margin-bottom: 1rem">
                    <v-icon size="24" class="responsive-icon" color="white">mdi-cog</v-icon>
                  </v-btn>
                  <v-text-field class="elegant-input" variant="solo" v-model="user.roles" label="Roles" readonly
                    disabled />
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-with-icon">
                  <v-btn class="field-icon" icon style="background-color: slateblue !important; margin-bottom: 1rem">
                    <v-icon size="24" class="responsive-icon" color="white">mdi-account-group</v-icon>
                  </v-btn>
                  <v-text-field class="elegant-input" variant="solo" v-model="user.groups" label="Groups" readonly
                    disabled />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'
import { useOAuthService } from '@/services/OAuthService'
import lodash from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getStoredItem, setStoredItem } from '@/services/utils'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import {
  getBuildings,
  getCampuses,
  getUserProfiles,
  getDataFromBlob,
  getFile,
  deleteProfileImage,
  postProfileImage,
  postFile,
  createUserProfile,
  getUserProfileById,
  updateUserProfile,
  removeStoredItem,
} from '@/controllers/BaseController'
import { getUsers, updateUser } from '@/controllers/BackboneAPI'

const logo = ref('')
const OAuthService = useOAuthService()
const originalUserProfile = ref({})
const isEditing = ref(false)
const showCropper = ref(false)
const originalImage = ref(null)
const fileInput = ref(null)
const selectedImage = ref(null)
const apiProfiles = ref([])
const apiUsers = ref([])
const croppedImageBlob = ref(null)
const croppedImage = ref(null)
const user = ref({
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  campus: { id: '', name: 'No Campus Assigned' },
  building: { id: '', name: 'No Building Assigned' },
  groups: [],
  roles: [],
})

const router = useRouter()
const showLoader = ref(false)

import Swal from 'sweetalert2'

const openFileDialog = () => {
  fileInput.value.click()
}

const onFileChange = event => {
  const file = event.target.files[0]
  if (file) {
    originalImage.value = user.value.profileImage
    selectedImage.value = URL.createObjectURL(file)
    showCropper.value = true
  }
}

const onCropComplete = ({ canvas }) => {
  // Data URL for display
  croppedImage.value = canvas.toDataURL() // Display in the UI

  // Convert canvas to a Blob for uploading in FormData
  canvas.toBlob(blob => {
    croppedImageBlob.value = blob // Store the Blob for the profile upload API
  }, 'image/png')
}
const saveCroppedImage = () => {
  user.value.profileImage = croppedImage.value // Assign cropped image to user profile
  showCropper.value = false
}

const cancelCrop = () => {
  user.value.profileImage = originalImage.value // Revert to the original image
  showCropper.value = false
}

const saveProfile = async () => {
  try {
    showLoader.value = true

    const profile = { ...user.value }

    // Fetch profiles and users in parallel
    const [apiProfiles, apiUsers] = await Promise.all([getUserProfiles(), getUsers()])

    let apiProfile = apiProfiles.find(user => user.email === profile.email)
    let apiUser = apiUsers.find(user => user.email === profile.email)

    // User payload for updating user entity
    const userPayload = {
      name: profile.name,
      email: profile.email,
      active: true,
      campusId: profile.campus.id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      description: profile.jobTitle,
    }

    // Update user entity if found
    if (apiUser) {
      apiUser = { ...apiUser, ...userPayload }
      await updateUser(apiUser.id, apiUser)
    }

    // Profile payload for creating/updating the user profile
    const profilePayload = {
      name: profile.name,
      displayName: profile.name,
      email: profile.email,
      displayEmail: profile.email,
      job: profile.jobTitle,
      active: true,
      campusId: profile.campus.id,
    }

    let profileId

    if (apiProfile) {
      apiProfile = { ...apiProfile, ...profilePayload, version: apiProfile.version }
      await updateUserProfile(apiProfile.id, apiProfile)
      profileId = apiProfile.id
    } else {
      const newProfile = await createUserProfile(profilePayload)
      profileId = newProfile.id
    }

    if (croppedImageBlob.value) {
      if (user.value.hasProfileImage) {
        await deleteProfileImage(profileId, user.value.hasProfileImage)
      }

      const uploadedFile = await postFile(croppedImageBlob.value, 'image/png')
      const newFileId = uploadedFile.id

      // Post the new profile image ID
      await postProfileImage(profileId, newFileId)

      // Update local user data to reflect the new image
      user.value.hasProfileImage = newFileId
    }

    // Show success message
    Swal.fire({
      icon: 'success',
      title: 'Profile Saved',
      text: 'Your profile has been successfully updated!',
      confirmButtonText: 'OK',
    })
  } catch (error) {
    console.error('Error saving profile:', error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'There was an error saving your profile. Please try again later.',
      confirmButtonText: 'OK',
    })
  } finally {
    showLoader.value = false
    isEditing.value = false

    // Remove stored items after save
    await removeStoredItem('userProfiles')
    await removeStoredItem('users')
    location.reload()
  }
}

const emailRules = value => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return emailRegex.test(value) || 'Invalid email address'
}

const getTextBoxVariant = () => (!isEditing.value ? 'solo' : 'outlined')

function toggleEdit() {
  isEditing.value = !isEditing.value
}

function goBack() {
  router.back()
}

onMounted(async () => {
  try {
    showLoader.value = true
    logo.value = await getToolbarLogoImage()

    let userProfile = await OAuthService.getUserProfile()
    const [userProfiles, users] = await Promise.all([getUserProfiles(), getUsers()])
    apiProfiles.value = userProfiles
    apiUsers.value = users
    let apiProfile = userProfiles.find(user => user.email === userProfile.email)
    if (!apiProfile) {
      originalUserProfile.value = userProfile
    } else {
      let profile = await getUserProfileById(apiProfile.id)
      apiProfile = profile
    }

    let [firstName, lastName] = lodash.split(userProfile.name || lodash.replace(userProfile.email.split('@')[0], /\./g, ' '), ' ')

    firstName = lodash.capitalize(firstName || '')
    lastName = lodash.capitalize(lastName || 'Unknown')

    let fallbackProfileImage = require('@/assets/General/user_avatar_3d.png')
    let profileImageId = lodash.get(apiProfile, 'files.entities', []).find(file => file.type === 'profileImage')?.id
    let image = profileImageId ? await getFile(profileImageId) : null
    let profileImage = image ? await getDataFromBlob(image) : fallbackProfileImage
    let hasProfileImage = image !== null ? profileImageId : null
    originalImage.value = profileImage

    let jobTitle = lodash.get(apiProfile, 'job', 'No Job Title Assigned')

    let selectedBuilding = lodash.find(await getBuildings(), { name: await getStoredItem('selectedBuilding') }) || { name: 'No Building Assigned' }
    let selectedCampus = lodash.find(await getCampuses(), { id: lodash.get(selectedBuilding, 'campus.id') }) || { name: 'No Campus Assigned' }

    user.value = {
      name: `${firstName} ${lastName}`,
      email: lodash.get(userProfile, 'email', ''),
      firstName,
      lastName,
      profileImage,
      files: lodash.get(apiProfile, 'files.entities', []),
      hasUserProfile: apiProfile !== undefined,
      hasProfileImage,
      jobTitle,
      groups: lodash.get(userProfile, 'groups', []).length ? userProfile.groups.join(' - ') : 'No Groups Assigned',
      roles:
        lodash
          .get(userProfile, 'realm_access.roles', [])
          .filter(role => role !== 'uma_authorization')
          .join(' - ') || 'No Roles Assigned',
      building: selectedBuilding,
      campus: selectedCampus,
    }
  } catch (error) {
    console.error('Error loading logo image or user profile:', error)
    showLoader.value = false
  } finally {
    showLoader.value = false
  }
})
</script>

<style scoped>
.cropper {
  height: auto;
  max-width: 100%;
  background: #ddd;
  border-radius: 10px;
}

.camera-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 8px;
}

.camera-icon {
  font-size: 24px;
}

.field-with-icon {
  display: flex;
  align-items: center;
}

.field-icon {
  background-color: #7fbfff;
  border-radius: 12px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.responsive-icon {
  font-size: 24px;
}

.profile-container {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

@supports (-webkit-touch-callout: none) {
  .custom-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.profile-card {
  max-width: 800px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  transition: box-shadow 0.3s;
  position: relative;
}

.header-gradient {
  background: linear-gradient(90deg, rgba(173, 216, 230, 1) 0%, rgba(255, 228, 181, 1) 100%);
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  padding: 20px;
}

.flex-wrap-on-small {
  flex-wrap: wrap;
}

.profile-info {
  text-align: left;
  flex-grow: 1;
}

.email-field {
  display: flex;
  align-items: center;
}

.email-icon {
  background-color: #7fbfff;
  border-radius: 12px;
  height: 40px;
  width: 40px;
  margin-top: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

/* Elegant Input Styling */
.elegant-input {
  border-radius: 10px;
  font-size: 16px;
}

.elegant-input .v-input__control {
  font-size: 16px;
  padding: 10px;
}

/* Responsive styling */
.responsive-avatar {
  width: 80px;
  height: 80px;
}

.responsive-name {
  font-size: 20px;
}

.responsive-email {
  font-size: 14px;
}

.responsive-btn {
  font-size: 14px;
}

.mt-2-on-small {
  margin-top: 0;
}

@media (min-width: 601px) {
  .cropper {
    height: 400px;
    width: 400px;
  }
}

@media (max-width: 600px) {
  .cropper {
    height: 60vh;
    width: 90vw;
  }

  .responsive-avatar {
    width: 60px;
    height: 60px;
  }

  .responsive-name {
    font-size: 16px;
  }

  .responsive-email {
    font-size: 12px;
  }

  .responsive-btn {
    font-size: 12px;
  }

  .responsive-icon {
    font-size: 18px;
  }

  .responsive-text input {
    font-size: 14px;
  }

  /* Move the button below the profile info */
  .mt-2-on-small {
    margin-top: 2px;
    margin-left: 4.5rem;
  }

  /* Elegant Input on Small Screens */
  .elegant-input .v-input__control {
    font-size: 14px;
    padding: 8px;
  }

  .elegant-input {
    font-size: 14px;
  }
}
</style>


<!-- UserProfile Component
This component provides a user interface for viewing and editing user profile information. It includes various input fields, buttons, and modals to facilitate user interactions.

The main container is styled with a gradient background and contains a toolbar at the top with a back button, a title, and a logo image. The toolbar allows users to navigate back to the previous page by clicking the back button.

The profile card is centered within the container and displays user information such as profile image, name, and email. Users can edit their profile by clicking the "Edit" button, which toggles the editing mode. In editing mode, users can change their profile image by clicking the camera icon, which opens a file dialog. Once a file is selected, a cropper modal appears, allowing users to crop the image before saving it.

The form section contains input fields for first name, last name, email, job position, campus name, building name, roles, and groups. These fields are displayed with icons for better visual representation. The input fields are read-only by default and become editable when the user enters editing mode.

The component provides visual feedback using Swal alerts. When the profile is successfully saved, a success alert is displayed. If there is an error during the save process, an error alert is shown. These alerts help users understand the outcome of their actions.

Overall, this component enhances the user experience by providing a clean and intuitive interface for managing user profiles. Users can easily view and update their profile information, with clear visual feedback and smooth interactions.
-->
