import axios from 'axios'
import { loadConfig } from '@/configLoader'
import { useOAuthService } from '@/services/OAuthService'
import { dbPromise } from '@/services/utils'

const oAuthService = useOAuthService()

// Get Base URL and API Key
const getBaseUrl = async () => (await loadConfig()).apiUrl
const getApiKey = async () => (await loadConfig()).apiKey

// Get File URL and API Key
export const getFileUrl = async () => (await loadConfig()).fileUrl
export const getFileApiKey = async () => (await loadConfig()).fileApiKey

// Get Campus ID
const getCampusId = async () => {
  // const userProfile = await oAuthService.getUserProfile()
  // const campuses = await getCampuses()
  // const selectedCampus = campuses.find(campus => campus.name === 'Main Site' || campus.name === 'Hauptstandort')
  // userProfile['campus-id'] = selectedCampus.id
  // return userProfile['campus-id']
  return '4a289bb3-99df-499f-b6d7-409448fb87f2'
}

// Get API Config (with headers)
const getConfig = async () => {
  const token = await oAuthService.getToken()
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-ps-api-key': await getApiKey(),
    },
  }
}

// Generic Fetch with Cache (checks for both `data` and `data.items`)
async function fetchWithCache(url, storageKey, storeName = 'blobs') {
  const db = await dbPromise
  const cachedData = await db.get(storeName, storageKey)
  if (cachedData) return cachedData

  const config = await getConfig()
  try {
    const { data } = await axios.get(url, config)
    const dataToCache = data.items || data

    const tx = db.transaction(storeName, 'readwrite')
    tx.store.put(dataToCache, storageKey)
    await tx.done

    return dataToCache
  } catch (error) {
    console.error(`Error fetching from ${url}:`, error)
    throw error
  }
}

// Fetch Incident Categories with Cache (Using 'incidents' store)
export const getIncidentCategories = async () => {
  const campusId = await getCampusId()
  const storageKey = `incidentCategories_${campusId}`
  const apiUrl = (await loadConfig()).apiUrl
  const url =
    window.location.hostname === 'localhost'
      ? `http://localhost:8081/${apiUrl}incidentcategories?sort=name|asc&filter=active=true`
      : `${apiUrl}incidentcategories?sort=name|asc&filter=active=true`

  return fetchWithCache(url, storageKey, 'incidents')
}

// Fetch All Incidents (Using 'incidents' store)
export const getAllIncidents = async () => {
  const campusId = await getCampusId()
  const storageKey = `allIncidents_${campusId}`
  const apiUrl = (await loadConfig()).apiUrl
  const url =
    window.location.hostname === 'localhost'
      ? `http://localhost:8081/${apiUrl}incidents?sort=creationTime|desc`
      : `${apiUrl}incidents?sort=creationTime|desc`

  return fetchWithCache(url, storageKey, 'incidents')
}

// Move Incidents to Another Category
export const moveIncidentsToCategory = async (incidentCategoryId, destinationIncidentCategoryId) => {
  const config = await getConfig()
  const url = `${await getBaseUrl()}incidentcategories/${incidentCategoryId}/move-incidents`
  const payload = {
    destinationIncidentCategoryId: destinationIncidentCategoryId,
  }

  try {
    const { data } = await axios.post(url, payload, config)
    return data
  } catch (error) {
    console.error(`Error moving incidents from category ${incidentCategoryId} to ${destinationIncidentCategoryId}:`, error)
    throw error
  }
}

// Fetch Incidents by User Email with Cache (Using 'incidents' store)
export const getIncidentsByUser = async email => {
  const campusId = await getCampusId()
  const storageKey = `incidentsByUser_${campusId}_${email}`
  const apiUrl = (await loadConfig()).apiUrl
  const url =
    window.location.hostname === 'localhost'
      ? `http://localhost:8081/${apiUrl}incidents?sort=creationTime|desc&filter=creatorEmail=${email}`
      : `${apiUrl}incidents?sort=creationTime|desc&filter=creatorEmail=${email}`

  return fetchWithCache(url, storageKey, 'incidents')
}

// Fetch Incidents by POI ID with Cache (Using 'incidents' store)
export const getIncidentsByPoi = async poiId => {
  const campusId = await getCampusId()
  const storageKey = `incidentsByPoi_${campusId}_${poiId}`
  const url = `${await getBaseUrl()}incidents?filter=tags[poiId]=${poiId}`

  return fetchWithCache(url, storageKey, 'incidents')
}

// Create Incident
export const createIncident = async payload => {
  const config = await getConfig()
  const url = `${await getBaseUrl()}incidents`
  try {
    const { data } = await axios.post(url, payload, config)
    return data
  } catch (error) {
    console.error('Error creating incident:', error)
    throw error
  }
}

// Link Incident to Image
export const linkIncidentToImage = async (incidentCategoryId, fileIdsArray) => {
  const config = await getConfig()
  const url = `${await getBaseUrl()}incidentcategories/${incidentCategoryId}/files`
  const payload = {
    files: fileIdsArray.map(fileId => ({ id: fileId, type: 'image' })),
  }

  try {
    const { data } = await axios.post(url, payload, config)
    return data
  } catch (error) {
    console.error('Error linking incident to images:', error)
    throw error
  }
}

// Post Multiple Files (Images)
export const postMultipleFiles = async (filesArray, fileNames = []) => {
  if (!Array.isArray(filesArray) || filesArray.length === 0) {
    throw new Error('Files array must contain at least one file.')
  }

  const formData = new FormData()
  filesArray.forEach((file, index) => {
    const name = fileNames[index] || file.name
    formData.append('files', file, name)
  })

  const config = await getConfig()
  const fileUrl = await getFileUrl()
  try {
    const { data } = await axios.post(fileUrl, formData, config)
    return data
  } catch (error) {
    console.error('Error uploading files:', error)
    throw error
  }
}

// Fetch Floor by ID and Cache the Result
export const getFloorById = async floorId => {
  const storageKey = `floor_${floorId}`
  const url = `${await getBaseUrl()}floors/${floorId}`
  return fetchWithCache(url, storageKey)
}

// Fetch Campuses with Cache
export const getCampuses = async () => {
  const storageKey = 'campusesData'
  const url = `${await getBaseUrl()}campuses`
  return fetchWithCache(url, storageKey)
}

// Fetch File by ID and Cache the Result
export const getFile = async id => {
  const storageKey = `fileData_${id}`
  const fileUrl = `${await getFileUrl()}${id}`
  const fileApiKey = await getFileApiKey()

  const db = await dbPromise
  const cachedData = await db.get('blobs', storageKey)
  if (cachedData) return cachedData

  try {
    const { data: blob } = await axios.get(fileUrl, {
      headers: { 'x-ps-api-key': fileApiKey },
      responseType: 'blob',
    })

    const tx = db.transaction('blobs', 'readwrite')
    tx.store.put(blob, storageKey)
    await tx.done

    return blob
  } catch (error) {
    console.error('Error fetching file:', error)
    throw error
  }
}

export const getFileInfo = async id => {
  const fileUrl = `${await getFileUrl()}${id}/info`
  const fileApiKey = await getFileApiKey()

  try {
    const { data } = await axios.get(fileUrl, {
      headers: { 'x-ps-api-key': fileApiKey },
    })
    return data
  } catch (error) {
    console.error('Error fetching file info:', error)
    throw error
  }
}

// Fetch Incident by ID with Cache
export const getIncidentById = async incidentId => {
  const storageKey = `incident_${incidentId}`
  const url = `${await getBaseUrl()}incidents/${incidentId}`
  return fetchWithCache(url, storageKey, 'incidents')
}

// Update Incident by ID
export const updateIncidentById = async (incidentId, payload) => {
  const config = await getConfig()
  const url = `${await getBaseUrl()}incidents/${incidentId}`

  try {
    const { data } = await axios.put(url, payload, config)
    return data
  } catch (error) {
    console.error('Error updating incident:', error)
    throw error
  }
}

// Delete Incident by ID
export const deleteIncidentById = async incidentId => {
  const config = await getConfig()
  const url = `${await getBaseUrl()}incidents/${incidentId}`

  try {
    const { data } = await axios.delete(url, config)
    return data
  } catch (error) {
    console.error('Error deleting incident:', error)
    throw error
  }
}

// Fetch Buildings with Cache
export const getBuildings = async () => {
  const storageKey = 'buildingsData'
  const campusId = await getCampusId()
  const url = `${await getBaseUrl()}buildings`

  const buildingsData = await fetchWithCache(url, storageKey)
  return buildingsData.filter(building => building.campus?.id === campusId)
}

// Link Incident to Category
export const linkIncidentToCategory = async (incidentId, categoryId) => {
  const config = await getConfig()
  const url = `${await getBaseUrl()}incidents/${incidentId}/incidentcategory`
  try {
    const { data } = await axios.post(url, categoryId, config)
    return data
  } catch (error) {
    console.error('Error linking incident to category:', error)
    throw error
  }
}

export const deleteIncidentFiles = async (incidentId, fileIds) => {
  const config = await getConfig()
  const campusId = await getCampusId()
  config.url = `${await getBaseUrl()}campuses/${campusId}/incidents/${incidentId}/files`
  config.method = 'DELETE'
  config.data = fileIds

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error deleting incident files:', error)
    throw error
  }
}

// Fetch POI Categories with Cache
export const getPoiCategories = async () => {
  const storageKey = 'poiCategoriesData'
  const url = `${await getBaseUrl()}poicategories`
  return fetchWithCache(url, storageKey)
}

// Fetch POIs by Category ID and Cache the Result
export const getPoisByCategoryId = async categoryId => {
  const storageKey = `poisByCategoryId_${categoryId}`
  const url = `${await getBaseUrl()}poicategories/${categoryId}/pois`
  return fetchWithCache(url, storageKey)
}

// Helper function to read data from Blob
export const getDataFromBlob = myBlob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(myBlob)
  })

// Fetch Floors with Cache
export const getFloors = async () => {
  const storageKey = 'floorsData'
  const url = `${await getBaseUrl()}floors`
  return fetchWithCache(url, storageKey)
}

export const getLocations = async () => {
  const storageKey = 'locationsData'
  const url = `${await getBaseUrl()}pois`
  return fetchWithCache(url, storageKey)
}

// Delete all incident-related data from storage
export const deleteIncidentsFull = async () => {
  const db = await dbPromise
  const tx = db.transaction('incidents', 'readwrite')
  const store = tx.store

  const keys = await store.getAllKeys()
  for (const key of keys) {
    await store.delete(key)
    console.log(`Deleted cache entry for key: ${key}`)
  }

  await tx.done
  console.log('All incident-related data deleted from the incidents store.')
}
