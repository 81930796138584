<template>
  <div class="office-analytics">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Office Analytics' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select v-model="selectedFilter" :items="filters" label="Filter" dense outlined class="mb-4"
            style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)"></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="pa-4 mb-4" title="Office Days">
            <apexchart width="100%" height="350" type="bar" :options="officeChartOptions" :series="officeChartData">
            </apexchart>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-4 mb-4" title="Vacation Days">
            <DatePicker v-if="selectedFilter !== 'Current Year'" v-model.range="vacationDatesRange" mode="date"
              :view="selectedFilter === 'Current Week' || selectedFilter === 'Last Week' ? 'weekly' : 'monthly'"
              expanded borderless :color="'red'" />
            <apexchart v-else width="100%" height="350" type="bar" :options="vacationChartOptions"
              :series="vacationChartData"></apexchart>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-4 mb-4" title="Sick Days">
            <apexchart width="100%" height="350" type="donut" :options="sickChartOptions" :series="sickChartData">
            </apexchart>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, defineComponent, watch, onMounted } from 'vue'
import { officeDaysData } from '@/dummydata/officeDays'
import moment from 'moment'
import VueApexCharts from 'vue3-apexcharts'
import { DatePicker } from 'v-calendar'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'

export default defineComponent({
  name: 'OfficeAnalytics',
  components: {
    apexchart: VueApexCharts,
    DatePicker,
  },
  setup() {
    const selectedFilter = ref('Last Month')
    const vacationDatesRange = ref({ start: null, end: null })
    const filters = ['Current Week', 'Current Month', 'Last Week', 'Last Month', 'Current Year']
    const officeChartData = ref([])
    const vacationChartData = ref([])
    const sickChartData = ref([])
    const logo = ref('')

    const officeChartOptions = ref({
      chart: {
        id: 'office-days-chart',
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: 'Hours',
        },
      },
      stroke: {
        curve: 'stepline',
      },

      legend: {
        position: 'top',
      },
    })

    const vacationChartOptions = ref({
      chart: {
        id: 'vacation-days-chart',
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        title: {
          text: 'Month',
        },
      },
      yaxis: {
        title: {
          text: 'Vacation Days',
        },
      },

      legend: {
        position: 'top',
      },
    })

    const sickChartOptions = ref({
      chart: {
        id: 'sick-days-chart',
        toolbar: {
          show: true,
        },
      },
      labels: ['Sick Days', 'Other Days'],

      legend: {
        position: 'top',
      },
    })

    const goBack = () => {
      window.history.back()
    }

    const processData = () => {
      let filteredData
      const endDate = moment()
      let startDate

      switch (selectedFilter.value) {
        case 'Current Week':
          startDate = moment().startOf('week')
          break
        case 'Current Month':
          startDate = moment().startOf('month')
          break
        case 'Last Week':
          startDate = moment().subtract(1, 'week').startOf('week')
          endDate.subtract(1, 'week').endOf('week')
          break
        case 'Last Month':
          startDate = moment().subtract(1, 'month').startOf('month')
          endDate.subtract(1, 'month').endOf('month')
          break
        case 'Current Year':
          startDate = moment().startOf('year')
          break
        default:
          startDate = moment().subtract(2, 'months').startOf('month')
          break
      }

      filteredData = officeDaysData.filter(item => moment(item.date).isBetween(startDate, endDate, null, '[]'))

      const officeSeries = { name: 'Office Hours', data: [] }
      const vacationRanges = []
      const vacationSeries = new Array(12).fill(0) // For months
      const sickSeries = [0, 0]

      let currentVacationRange = null

      for (const item of filteredData) {
        const date = moment(item.date).format('YYYY-MM-DD')
        if (item.status === 'Office') {
          officeSeries.data.push({ x: date, y: item.hours })
        } else if (item.status === 'Vacation') {
          const month = moment(item.date).month()
          vacationSeries[month] += 1

          if (!currentVacationRange) {
            currentVacationRange = { start: date, end: date }
          } else {
            currentVacationRange.end = date
          }
        } else if (item.status === 'Sick') {
          sickSeries[0] += 1
        }
        if (item.status !== 'Sick') {
          sickSeries[1] += 1
        }

        if (item.status !== 'Vacation' && currentVacationRange) {
          vacationRanges.push({ start: new Date(currentVacationRange.start), end: new Date(currentVacationRange.end) })
          currentVacationRange = null
        }
      }

      if (currentVacationRange) {
        vacationRanges.push({ start: new Date(currentVacationRange.start), end: new Date(currentVacationRange.end) })
      }

      officeChartData.value = [officeSeries]
      vacationDatesRange.value = vacationRanges.length ? vacationRanges[0] : { start: null, end: null }
      vacationChartData.value = [{ name: 'Vacation Days', data: vacationSeries }]
      sickChartData.value = sickSeries
    }

    watch(selectedFilter, processData, { immediate: true })

    onMounted(() => {
      processData()
      logo.value = getToolbarLogoImage()
    })

    return {
      selectedFilter,
      filters,
      officeChartOptions,
      vacationChartOptions,
      sickChartOptions,
      officeChartData,
      vacationChartData,
      sickChartData,
      logo,
      vacationDatesRange,
      goBack,
    }
  },
})
</script>

<style scoped>
.office-analytics {
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

@supports (-webkit-touch-callout: none) {
  .custom-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.v-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
</style>
<!-- OfficeAnalytics Component

This component provides a comprehensive view of office analytics, allowing users to visualize and interact with data related to office days, vacation days, and sick days. 

The main features of this component include:

1. A toolbar at the top with a back button and a title "Office Analytics" next to a logo. The back button allows users to navigate to the previous page.

2. A filter dropdown that lets users select different time periods to filter the data. The available filters are Current Week, Current Month, Last Week, Last Month, and Current Year. This filter dynamically updates the displayed data based on the selected time period.

3. Three main sections displaying different types of data:
  - Office Days: A bar chart that shows the number of office hours for the selected time period.
  - Vacation Days: Depending on the selected filter, this section either shows a date picker for selecting a range of vacation dates or a bar chart displaying the number of vacation days per month.
  - Sick Days: A donut chart that visualizes the proportion of sick days compared to other days.

4. Visual feedback is provided through the charts, which update dynamically based on the selected filter. The charts are rendered using the ApexCharts library, ensuring a responsive and interactive experience.

5. The component uses a loader to indicate when data is being processed or loaded, enhancing the user experience by providing visual feedback during data fetching or processing.

Users can leverage this component to gain insights into their office attendance patterns, vacation usage, and sick leave trends. The interactive charts and filters allow for a detailed and customizable view of the data, making it easier to analyze and understand office analytics.

Overall, this component enhances the user experience by providing a clear, interactive, and visually appealing way to view and analyze office-related data. -->
