<template>
  <v-app class="app-background">
    <v-main :style="{
      paddingBottom: showBottomNav ? `${bottomNavHeight}px` : '0px',
    }">
      <BottomNavigation ref="bottomNavRef" v-if="showBottomNav" :selected="selected"
        @update:selected="selected = $event" />
      <router-view v-slot="{ Component }">
        <v-slide-x-transition mode="out-in">
          <component :is="Component" />
        </v-slide-x-transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BottomNavigation from '@/components/general/BottomNavigation'
import { getStoredItem, setStoredItem } from '@/services/utils'
import { getCustomer } from '@/assets/Branding/branding.js'

export default {
  components: {
    BottomNavigation,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const selected = ref(0)
    const bottomNavRef = ref(null)
    const bottomNavHeight = ref(0)

    const showBottomNav = computed(
      () => route.name !== 'Login' && !window.location.href.includes('/oauth2') && !window.location.href.includes('/home')
    )

    onMounted(async () => {
      try {
        console.log('onMounted')
        let storedBuilding = await getStoredItem('selectedBuilding')
        const { getBuildings } = await import('@/controllers/BaseController')
        let buil = await getBuildings()
        if (!storedBuilding) {
          let customerName = await getCustomer()
          const { getBuildings } = await import('@/controllers/BaseController')
          const apiBuildings = await getBuildings()
          if (customerName === 'pia') {
            let building = apiBuildings.find(building => building.id === 'e1e1c326-f8e5-472b-9ea7-673c59550d46')
            if (building) {
              storedBuilding = building.name
            }
          } else {
            storedBuilding = apiBuildings[0]?.name || null
          }
          await setStoredItem('selectedBuilding', storedBuilding)
        }
      } catch (error) {
        console.log(error)
      }

      if (bottomNavRef.value) {
        bottomNavHeight.value = bottomNavRef.value.$el.clientHeight
      }
    })

    watch(
      () => bottomNavRef.value,
      (newVal, oldVal) => {
        if (newVal) {
          bottomNavHeight.value = newVal.$el.clientHeight
        }
      },
      { immediate: true }
    )
    // watch(
    //   () => route.path,
    //   newPath => {
    //     if (newPath)
    //       if (newPath.includes('/infocenter')) {
    //         selected.value = 0
    //       }
    //     if (newPath.includes('/building')) {
    //       selected.value = 1
    //     }
    //     if (newPath.includes('/booking')) {
    //       selected.value = 2
    //     }
    //     if (newPath.includes('/chat')) {
    //       selected.value = 3
    //     }
    //     // if (newPath.includes('/marketing')) {
    //     //   selected.value = 4
    //     // }
    //     if (newPath.includes('/menu')) {
    //       selected.value = 4
    //     }
    //   },
    //   { immediate: true }
    // )

    return {
      selected,
      showBottomNav,
      bottomNavRef,
      bottomNavHeight,
    }
  },
}
</script>

<style scoped>
body {
  margin-top: env(--ion-safe-area-top);
}

.app-background {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
}

.unchecked .btn-title {
  visibility: visible !important;
  margin-top: 2.4rem;
}
</style>

<!-- App.vue Component

This component serves as the main layout for the application. It provides a structured and dynamic user interface that adapts based on the current route and user interactions.

The main features of this component include:

1. Bottom Navigation: A dynamic bottom navigation bar that appears conditionally based on the current route. It is hidden on the 'Login' route and any routes containing '/oauth2' or '/home'. The navigation bar allows users to switch between different sections of the app such as Info Center, Building, Booking, Chat, and Menu. The selected tab is highlighted, providing visual feedback to the user.

2. Router View with Transition: The main content area of the app is managed by Vue Router. The <router-view> component dynamically loads the appropriate component based on the current route. A slide transition effect is applied to enhance the user experience when navigating between different views.

3. Dynamic Padding: The main content area adjusts its bottom padding dynamically based on the height of the bottom navigation bar. This ensures that the content is not obscured by the navigation bar and provides a seamless viewing experience.

4. Data Fetching and State Management: On component mount, the app fetches and stores the selected building information. It uses utility functions to get and set stored items and fetches building data from an API. This ensures that the app has the necessary data to function correctly and provides a personalized experience for the user.

5. Visual Feedback: The component logs important events and errors to the console, helping developers debug and understand the app's behavior. Additionally, the component's background is styled with a gradient, providing a visually appealing interface.

Users can interact with this component by:
- Navigating between different sections using the bottom navigation bar.
- Viewing dynamic content that changes based on the selected route.
- Experiencing smooth transitions between different views.
- Receiving visual feedback through the highlighted navigation tabs and the styled background.

Overall, this component enhances the user experience by providing a structured, dynamic, and visually appealing interface that adapts to user interactions and route changes.
-->
