<template>
  <v-container fluid class="pt-4">
    <v-row>
      <!-- First Column: Profile and News -->
      <v-col cols="12" md="8">
        <!-- Profile Section -->
        <v-row>
          <v-col cols="12">
            <div class="profile-card">
              <div class="profile-content">
                <h2 style="text-transform: capitalize">Hi {{ userProfile?.name }}!</h2>
                <p>{{ userProfile?.job }} {{ customer }}</p>
                <v-btn @click="createPost" class="btn-create-post" color="primary" outlined v-if="canEditCreate">+
                  Create New Post</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- News Section -->
        <v-row>
          <v-col cols="12">
            <router-link to="/create-post"></router-link>
            <div class="news-section">
              <v-row>
                <v-col cols="6">
                  <h3 class="news-title">News</h3>
                </v-col>
                <v-col cols="6" class="text-right" v-if="canEditCreate">
                  <v-btn @click="toggleEditMode" :color="isEditMode ? 'green' : 'black'" outlined>
                    <v-icon>{{ isEditMode ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
                    {{ isEditMode ? 'Done' : 'Edit' }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="newsItems?.length !== 0">
                <v-col v-for="(news, i) in newsItems" :key="i" cols="12" sm="6" md="4">
                  <v-card class="news-card" @click="props.itemClicked(news)">
                    <v-img :src="news.image" height="150" class="news-card-image" cover />
                    <v-card-title class="news-card-title">{{ news.title }}</v-card-title>
                    <v-card-subtitle class="news-card-subtitle">{{ news.description }}</v-card-subtitle>
                    <v-card-actions style="margin-top: -2rem" v-if="isEditMode">
                      <v-btn icon color="green" class="mr-2" @click="editItem(news)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon color="red" class="mr-2" @click="deleteItem(news)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(135deg, #f3e7ff, #e0eafc);
                    padding: 1rem;
                    border-radius: 16px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    color: #2d3748;
                  ">
                  <span>No Upcoming News or Announcements</span>
                </div>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- Second Column: Team Members and Upcoming Events -->
      <v-col cols="12" md="4">
        <!-- Team Members Section -->
        <v-card elevation="4" class="team-card" v-if="teamMembersEnabled">
          <v-card-title class="headline">Team Members</v-card-title>
          <div class="team-members-list">
            <div class="team-member" v-for="(member, i) in teamMembers" :key="i" @click="props.itemClicked(member)">
              <div class="avatar">
                <div class="initials">{{ member.initials }}</div>
              </div>
              <div class="details">
                <div class="name">{{ member.name }}</div>
                <div class="position">{{ member.position }}</div>
              </div>
              <div class="status">
                <v-chip :color="member.status === 'Remote' ? 'light-blue lighten-3' : member.status === 'InOffice' ? 'light-green lighten-3' : 'pink lighten-3'
                  " small>
                  {{ member.status }}
                </v-chip>
              </div>
            </div>
          </div>
        </v-card>

        <!-- Upcoming Events Section -->
        <v-card elevation="4" class="upcoming-card mt-4">
          <v-card-title class="headline">Upcoming Events</v-card-title>
          <div class="upcoming-events-list" v-if="upcomingEvents?.length !== 0">
            <div v-for="(event, i) in upcomingEvents" :key="i">
              <div class="event-item">
                <div class="event-details">
                  <div class="event-title">{{ event.title }}</div>
                  <div class="event-date">{{ event.date }}</div>
                  <div class="event-description">{{ event.description }}</div>
                </div>
                <div class="event-arrow">
                  <v-icon>mdi-chevron-right</v-icon>
                </div>
              </div>
              <v-card-actions v-if="isEditMode" style="
                  margin-top: -0.8rem;
                  background-color: white;
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                  padding: 10px;
                  border-bottom-left-radius: 8px;
                  border-bottom-right-radius: 8px;
                  margin-bottom: 10px;
                ">
                <v-btn icon color="green" class="mr-2" @click="editItem(event)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="red" class="mr-2" @click="deleteItem(event)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </div>
          <div v-else style="
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(135deg, #f3e7ff, #e0eafc);
              padding: 1rem;
              border-radius: 16px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              color: #2d3748;
            ">
            <v-icon style="margin-right: 0.5rem">mdi-calendar-remove</v-icon>
            <span>No upcoming events in future</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getCustomer } from '@/assets/Branding/branding.js'


// Props
const props = defineProps({
  newsItems: Array,
  teamMembers: Array,
  upcomingEvents: Array,
  userProfile: Object,
  itemClicked: Function,
  editItem: Function,
  deleteItem: Function,
  isEditModeParent: Boolean,
  teamMembersEnabled: Boolean,
  canEditCreate: Boolean
})

const router = useRouter()
const emit = defineEmits(['update:isEditModeParent'])
const customer = ref(null)

const isEditMode = ref(props.isEditModeParent)

onMounted(async () => {
  let customerName = await getCustomer()
  if (customerName === 'pia') {
    customer.value = 'München, DE'
  }
  else if (customerName === 'hammerbrooklyn') {
    customer.value = 'Hamburg, DE'
  }
})

const toggleEditMode = () => {
  isEditMode.value = !isEditMode.value
  emit('update:isEditModeParent', isEditMode.value) // Emit the updated state to the parent
}

const createPost = () => {
  router.push({ name: 'CreateEditInfoItem' }).catch(err => console.error(err))
}
</script>

<style scoped>
/* **************DESKTOP VIEW*********************** */

/* Profile Section */
.profile-card {
  background: linear-gradient(90deg, #3b82f6 0%, #9333ea 100%);
  padding: 1.5rem;
  border-radius: 16px;
  color: white;
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;
}

.profile-card::before,
.profile-card::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.profile-card::before {
  width: 200px;
  height: 200px;
  top: -60px;
  left: -80px;
}

.profile-card::after {
  width: 250px;
  height: 250px;
  bottom: -80px;
  right: -100px;
}

.profile-content {
  position: relative;
  z-index: 2;
}

.profile-content h2 {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
}

.profile-content p {
  font-size: 1rem;
  margin: 0.5rem 0;
  font-weight: 500;
}

.btn-create-post {
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 8px;
}

/* News Section */
.news-section {
  margin-bottom: 2rem;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: #f0f2f5;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.news-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.news-card {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.news-card-image {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 16px 16px 0 0;
}

.news-card-title {
  font-weight: bold;
  color: #222;
  font-size: 1.1rem;
  white-space: break-spaces;
  padding: 0.5rem 1rem;
}

.news-card-subtitle {
  font-size: 0.875rem;
  color: #555;
  padding: 0 1rem 1rem;
  flex-grow: 1;
  white-space: break-spaces;
}

/* Team Members Section */
.team-card {
  padding: 20px;
  border-radius: 12px;
}

.team-members-list {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: rgb(224, 231, 255);
}

/* Custom Scrollbar for Webkit Browsers */
.team-members-list::-webkit-scrollbar {
  width: 8px;
}

.team-members-list::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 10px;
}

.team-members-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.team-member {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  color: white;
  margin-right: 1rem;
}

.initials {
  background-color: #5a67d8;
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 1rem;
  color: white;
}

.details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
  color: black;
}

.position {
  font-size: 0.875rem;
  color: #2e65ff;
}

.status {
  display: flex;
  align-items: center;
}

/* Upcoming Events Section */
.upcoming-card {
  padding: 20px;
  border-radius: 12px;
}

.upcoming-events-list {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #b3b3b3 #f1f1f1;
}

.upcoming-events-list::-webkit-scrollbar {
  width: 8px;
}

.upcoming-events-list::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 10px;
}

.upcoming-events-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.event-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(224, 231, 255);
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-details {
  display: flex;
  flex-direction: column;
}

.event-title {
  font-weight: bold;
  font-size: 1rem;
  color: black;
}

.event-date {
  font-size: 0.875rem;
  color: darkred;
}

.event-description {
  font-size: 0.875rem;
  color: #6b7280;
}

.event-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #6b7280;
}
</style>
