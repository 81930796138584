<template>
  <div class="elevator-control">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Elevator Control' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container fluid class="py-6 chat-container">
      <v-row justify="center" class="mb-4">
        <v-col cols="12" class="text-center">
          <h2>Select your floor and call the desired elevator.</h2>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-4">
        <v-col cols="12" sm="6" md="4">
          <v-select v-model="selectedFloor" :items="uniqueFloors" label="Select a floor" @change="filterElevators"
            variant="solo-inverted"></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col v-for="elevator in elevators" :key="elevator.id" cols="12" sm="6" md="4" lg="3">
          <div :class="['custom-card', { 'disabled-card': !elevator.hasSelectedFloor }]"
            @click="elevator.hasSelectedFloor && openModal(elevator)">
            <div class="elevator-name">{{ elevator.name }}</div>
            <img :src="require('@/assets/General/elevator.png')" class="elevator-image" />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="modalVisible" max-width="600px">
      <v-card>
        <v-card-title class="headline">{{ selectedElevator.name }} Entrances</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col v-for="entrance in selectedElevator.entrances" :key="entrance.id" cols="12" sm="6" md="4">
              <div class="entrance-card" :class="{
                'selected-entrance': stripPrefix(entrance.name) === selectedFloor,
              }" @click="stripPrefix(entrance.name) !== selectedFloor && selectEntrance(entrance)">
                <div class="entrance-name">
                  {{ stripPrefix(entrance.name) }}
                </div>
                <v-radio :value="entrance.id" v-model="selectedEntrance" class="entrance-radio" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal">Close</v-btn>
          <v-btn color="primary" text @click="confirmSelection">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, watch, onUnmounted } from 'vue'
import Loader from '@/components/general/Loader.vue'
import { getStoredItem } from '@/services/utils'
import lodash from 'lodash'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { getBuildings, getFloorById } from '@/controllers/BaseController'
import { getElevators, callElevator } from '@/controllers/BackboneAPI'
import mqttService from '@/services/mqtt.js'
import { useOAuthService } from '@/services/OAuthService'
import Swal from 'sweetalert2'



export default defineComponent({
  name: 'Elevator',
  components: {
    Loader,
  },
  setup() {
    const logo = ref('')
    const showLoader = ref(false)
    const elevators = ref([])
    const uniqueFloors = ref([])
    const selectedFloor = ref(null)
    const modalVisible = ref(false)
    const selectedElevator = ref(null)
    const selectedEntrance = ref(null)
    const OAuthService = useOAuthService()
    const userProfile = ref(null)


    onMounted(async () => {
      try {
        showLoader.value = true
        userProfile.value = await OAuthService.getUserProfile()
        await mqttService.connect()
        logo.value = await getToolbarLogoImage()
        const storedBuilding = await getStoredItem('selectedBuilding')
        let buildings = await getBuildings()
        let selectedBuilding = lodash.find(buildings, {
          name: storedBuilding,
        })
        let elevatorData = await getElevators(selectedBuilding?.id)
        console.log(elevatorData)

        // Collect unique floor entrances
        const floorsSet = new Set()
        elevatorData.forEach(elevator => {
          elevator.entrances.forEach(entrance => {
            const floor = stripPrefix(entrance.name)
            floorsSet.add(floor)
          })
        })
        uniqueFloors.value = Array.from(floorsSet).sort((a, b) => {
          return parseInt(a.match(/\d+/g), 10) - parseInt(b.match(/\d+/g), 10)
        })

        elevators.value = elevatorData.map(elevator => ({
          ...elevator,
          // hasSelectedFloor: true,
        }))
        //selectedFloor.value = uniqueFloors.value[0]
      } catch (error) {
        console.error('Failed to retrieve user data:', error)
      } finally {
        showLoader.value = false
      }
    })
    onUnmounted(() => {
      mqttService.disconnect()
    })

    const selectEntrance = entrance => {
      console.log('Selected entrance:', JSON.parse(JSON.stringify(entrance)))
      selectedEntrance.value = entrance.id
    }

    const openModal = elevator => {
      selectedElevator.value = {
        ...elevator,
        entrances: lodash.orderBy(elevator.entrances, entrance => parseInt(entrance.name.match(/\d+/g), 10), ['asc']),
      }
      selectedEntrance.value = null
      modalVisible.value = true
    }

    const closeModal = () => {
      modalVisible.value = false
    }

    const extractActionId = (topic) => {
      const result = /\/([\w|\d|-]*)$/g.exec(topic);
      if (result && result.length > 1) {
        return result[1]; // Return the action ID (last segment of the topic)
      } else {
        return '';
      }
    };

    const confirmSelection = async () => {
      try {
        let entrance = JSON.parse(
          JSON.stringify(
            selectedElevator.value.entrances.find(
              (entrance) => entrance.id === selectedEntrance.value
            )
          )
        );
        let profile = JSON.parse(JSON.stringify(userProfile.value));
        let email = profile.email;
        let floorId = entrance?.location.floorId;
        let destinationFloor = await getFloorById(floorId);
        let destinationFloorNumber = destinationFloor?.number;

        showLoader.value = true;
        modalVisible.value = false;

        const response = await callElevator(
          selectedElevator.value.id,
          entrance.id,
          destinationFloorNumber
        );

        console.log('Elevator call response:', response);

        if (response.status === 'ok' && response.topic) {
          const topic = response.topic; // Extract the topic from the response
          Swal.fire({
            icon: 'success',
            title: 'Elevator Called',
            //text: data.payload, // Display the payload message
            confirmButtonColor: '#d2f8d2',
            confirmButtonText: 'OK',
          });
          // Subscribe to MQTT topic
          // mqttService.subscribe(topic, (message) => {
          //   console.log('Received MQTT message:', message);

          //   try {
          //     const data = JSON.parse(message); // Parse the MQTT message
          //     if (data.status === 'ok' && data.payload) {
          //       Swal.fire({
          //         icon: 'success',
          //         title: 'Elevator Called',
          //         text: data.payload, // Display the payload message
          //         confirmButtonText: 'OK',
          //       });
          //     } else {
          //       Swal.fire({
          //         icon: 'error',
          //         title: 'Elevator Call Failed',
          //         text: 'Unexpected response from the elevator.',
          //         confirmButtonText: 'OK',
          //       });
          //     }
          //   } catch (err) {
          //     console.error('Error parsing MQTT message:', err);
          //     Swal.fire({
          //       icon: 'error',
          //       title: 'Error',
          //       text: 'Failed to parse elevator response.',
          //       confirmButtonText: 'OK',
          //     });
          //   }

          //   mqttService.client.unsubscribe(topic); // Unsubscribe after receiving the message
          //   closeModal(); // Close the modal after confirmation
          showLoader.value = false; // Hide the loader
          //});

          // Timeout handling if no MQTT message is received within 10 seconds
          setTimeout(() => {
            if (showLoader.value) {
              Swal.fire({
                icon: 'error',
                title: 'Timeout',
                text: 'No response from the elevator. Please try again later.',
                confirmButtonText: 'OK',
              });
              mqttService.client.unsubscribe(topic); // Unsubscribe on timeout
              showLoader.value = false; // Hide the loader
            }
          }, 10000); // 10-second timeout
        } else {
          throw new Error('Invalid response from elevator API');
        }
      } catch (error) {
        console.error('Error during confirmSelection:', error);

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to call the elevator. Please try again.',
          confirmButtonText: 'OK',
        });

        showLoader.value = false; // Hide the loader in case of error
      }
    };




    const goBack = () => {
      window.history.back()
    }

    const stripPrefix = text => {
      return text.split('-').pop().trim()
    }

    const filterElevators = () => {
      elevators.value = elevators.value.map(elevator => ({
        ...elevator,
        hasSelectedFloor: elevator.entrances.some(entrance => stripPrefix(entrance.name) === selectedFloor.value),
      }))
    }

    watch(selectedFloor, filterElevators)

    return {
      logo,
      showLoader,
      elevators,
      uniqueFloors,
      selectedFloor,
      modalVisible,
      selectedElevator,
      selectedEntrance,
      openModal,
      closeModal,
      confirmSelection,
      stripPrefix,
      goBack,
      selectEntrance,
      userProfile,
      extractActionId
    }
  },
})
</script>

<style scoped>
.elevator-control {
  padding: 1rem;

}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.custom-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ddd;
}

.custom-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.custom-card.disabled-card {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.elevator-name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.elevator-image {
  width: 100%;
  max-width: 150px;
}

.chat-container {
  padding-top: 20px;
}

h2 {
  font-family: 'Material Design Icons';
  font-size: 20px;
  color: #555;
  margin-bottom: 20px;
}

.entrance-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ddd;
  position: relative;
}

.entrance-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.entrance-card.selected-entrance {
  border: 2px solid green;
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.entrance-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.entrance-radio {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 768px) {
  .custom-card {
    padding: 10px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .elevator-name {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .elevator-image {
    width: 80%;
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .custom-card {
    padding: 8px;
    margin: 6px;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  .elevator-name {
    font-size: 12px;
    margin-bottom: 6px;
  }

  .elevator-image {
    width: 70%;
    max-width: 100px;
  }
}
</style>


<!-- Elevator Component

This component provides a user interface for controlling elevators within a building. Users can interact with various elements to select a floor and call an elevator. The component includes the following functionalities:

1. Loader: Displays a loading spinner while data is being fetched.
2. Toolbar: Contains a back button, a title "Elevator Control", and a logo image.
3. Floor Selection: Users can select a floor from a dropdown menu. The available floors are dynamically populated based on the building's data.
4. Elevator Cards: Displays a list of elevators. Each elevator card shows the elevator's name and an image. If an elevator does not serve the selected floor, its card is disabled.
5. Modal Dialog: When an elevator card is clicked, a modal dialog opens, showing the entrances for the selected elevator. Users can select an entrance and confirm their selection.

Visual Feedback:
- Disabled Elevator Cards: Elevator cards that do not serve the selected floor are visually disabled and cannot be clicked.
- Selected Entrance: The selected entrance in the modal dialog is highlighted with a green border and is disabled to prevent re-selection.
- Loader: A loading spinner is shown while data is being fetched.

Actions and Events:
- Back Button: Clicking the back button navigates the user to the previous page.
- Floor Selection: Changing the selected floor filters the elevators to show only those that serve the selected floor.
- Elevator Card Click: Clicking an enabled elevator card opens the modal dialog for that elevator.
- Modal Close: Clicking the "Close" button in the modal dialog closes the dialog without making a selection.
- Confirm Selection: Clicking the "Confirm" button in the modal dialog confirms the selected entrance and closes the dialog.

This component enhances the user experience by providing a clear and interactive way to control elevators, with visual feedback and dynamic behaviors that guide the user through the process.

-->