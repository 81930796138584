<template>
  <div class="home-view">
    <LandingPageDesktopVue v-if="!isMobileView" />
    <LandingPageMobileVue v-else />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted, onBeforeMount } from 'vue';
import LandingPageDesktopVue from '@/components/general/ChildComponents/LandingPageDesktop.vue';
import LandingPageMobileVue from '@/components/general/ChildComponents/LandingPageMobile.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HomeView',
  components: {
    LandingPageDesktopVue,
    LandingPageMobileVue,
  },
  setup() {
    const isMobileView = ref(window.innerWidth <= 480);
    const router = useRouter();

    const handleResize = () => {
      isMobileView.value = window.innerWidth <= 480
    };
    onBeforeMount(async () => {
      const { getCustomer } = await import('@/assets/Branding/branding.js');
      const customer = await getCustomer();
      if (customer !== 'pia') {
        router.replace({ path: '/infocenter' }); // Redirect and replace history
      }
    });
    onMounted(() => {
      handleResize(); // Ensure it's updated correctly on mount
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    });

    return {
      isMobileView,
      router
    };
  },
});
</script>

<style scoped>
.home-view {
  /* display: flex; */
  flex-direction: column;
  /* min-height: 100vh;
  background: linear-gradient(135deg, #eef2f6 0%, #ffffff 100%); */
}
</style>
