<template>
    <div class="roles-list">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Roles' }}
            </v-toolbar-title>
            <v-btn class="mr-1" icon small @click="refresh">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="CreateEditRole" color="black">Create
                new</v-btn>
        </v-toolbar>

        <v-container class="mt-2 mb-0" style="background-color: transparent; box-shadow: none">
            <v-card-text>
                <v-row align="center" class="mb-2">
                    <v-col cols="auto">
                    </v-col>
                    <v-col>
                        <v-text-field v-model="searchQuery" label="Search" variant="outlined"
                            prepend-inner-icon="mdi-magnify" dense class="mx-4" style="margin-bottom: 0"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-container>
        <AdminRoles :roles="filteredRoles" :onEdit="openEditRole" :onDelete="deleteRole" />
    </div>
</template>

<script>
import { ref, onMounted, defineComponent, computed } from 'vue'
import { debounce } from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'
import AdminRoles from '@/components/general/ChildComponents/AdminRoles.vue';
import { getRoles, updateRole, createRole, deleteRoleById, clearAllStoredRoles } from '@/controllers/BackboneAPI.js';
import { getUserProfiles, getCampusById, getCampuses, storeData } from '@/controllers/BaseController';
import lodash from 'lodash';
import { dbPromise } from '@/services/utils';
import { removeStoredItem } from '@/services/utils';
import Swal from 'sweetalert2';

export default defineComponent({
    name: 'RolesList',
    components: {
        Loader,
        AdminRoles,
    },
    setup() {
        const showLoader = ref(true)
        const router = useRouter()
        const logo = ref('')

        const goBack = () => {
            window.history.back()
        }


        const refresh = async () => {
            // Display Swal loader while refreshing roles
            Swal.fire({
                title: 'Refreshing...',
                text: 'Please wait while we refresh the roles list.',
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                // Call the function to clear all stored roles
                await clearAllStoredRoles();

                // Reload the current page to refresh the roles list
                router.go(0);
            } catch (error) {
                console.error('Error refreshing roles:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Refresh Failed',
                    text: 'There was an issue refreshing the roles. Please try again.',
                });
            }
        };

        const navigateTo = (routeName, params = {}) => {
            router.push({ name: routeName, params })
        }

        const CreateEditRole = () => {
            navigateTo('new-role')
        }

        const roles = ref([])
        const searchQuery = ref('');
        const filteredRoles = computed(() => {
            return roles.value.filter((role) => {
                return role.name.toLowerCase().includes(searchQuery.value.toLowerCase());
            });
        });


        const openEditRole = async (role) => {
            try {
                showLoader.value = true;
                role = JSON.parse(JSON.stringify(role));
                let RoleId = role.id;
                await storeData(RoleId, role);
                navigateTo('edit-role', { id: RoleId });
            } catch (error) {
                console.error('Failed to load data:', error);
                showLoader.value = false;

            }
        };
        const deleteRole = async (role) => {

            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `Do you want to delete the role ${role.name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d2f8d2',
                cancelButtonColor: '#fbe5e3',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (result.isConfirmed) {
                try {
                    showLoader.value = true;
                    await deleteRoleById(role.id);
                    roles.value = roles.value.filter(r => r.id !== role.id);
                    showLoader.value = false;
                    await refresh();
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: `Role ${role.name} has been deleted.`,
                        timer: 2000,
                        showConfirmButton: false,
                    });
                } catch (error) {
                    console.error('Error deleting role:', error);
                    showLoader.value = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was a problem deleting the role. Please try again later.',
                    });
                }
            } else {
                Swal.close(); // Close the Swal dialog if canceled
            }
        };

        const resizeListener = debounce(() => (window.innerWidth), 200)



        onMounted(async () => {
            try {
                showLoader.value = true
                logo.value = await getToolbarLogoImage()
                window.addEventListener('resize', resizeListener)
                resizeListener()
                let allRoles = await getRoles();
                allRoles = lodash.orderBy(allRoles, ['name'], ['asc']);
                roles.value = allRoles;

                console.log('Roles:', allRoles);
                showLoader.value = false
            } catch (error) {
                console.error('Failed to load data:', error)
                showLoader.value = false
            }
        })

        return {
            showLoader,
            logo,
            router,
            goBack,
            navigateTo,
            CreateEditRole,
            roles,
            searchQuery,
            filteredRoles,
            openEditRole,
            deleteRole,
            refresh

        }
    },
})
</script>

<style scoped>
.roles-list {
    padding: 1rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

@supports (-webkit-touch-callout: none) {
    .custom-toolbar {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}
</style>