<template>
  <div class="create-manage-booking">

    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-icon :color="primaryColor" class="toolbar-icon" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title class="custom-toolbar-title">
        {{ isNew ? 'Create Booking' : 'Edit Booking' }}
      </v-toolbar-title>
      <v-btn :class="['submit-button', canSubmit ? 'submit-button-green' : 'submit-button-red']"
        style="margin-right: 20px" small :disabled="!canSubmit" @click="isNew ? submitBooking() : editBooking()">
        Submit
      </v-btn>
    </v-toolbar>


    <Loader :loading="showLoader" />
    <v-container class="form-container" fluid>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <div class="form-card">
            <form ref="form" @submit.prevent>
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="location">
                      <v-icon small class="label-icon">mdi-map-marker</v-icon>
                      Location
                    </label>
                    <input type="text" id="location" v-model="reservation.poi.name" disabled class="highlighted-text" />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="category">
                      <v-icon small class="label-icon">mdi-office-building</v-icon>
                      Category
                    </label>
                    <input type="text" id="category" v-model="reservation.category.name" disabled
                      class="highlighted-text" />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="floor">
                      <v-icon small class="label-icon">mdi-elevator</v-icon>
                      Floor
                    </label>
                    <input type="text" id="floor" v-model="reservation.floor.name" disabled class="highlighted-text" />
                  </div>
                </v-col>
                <v-col cols="12" sm="6" v-if="exisingReservations?.length > 0">
                  <div class="form-group">
                    <v-alert variant="tonal" :text="'Existing reservation for the selected time range.'" type="error">
                      <v-btn variant="outlined" style="margin-left: 0.8rem" size="small" color="red" text
                        @click="showExistingReservations = true">
                        Show
                      </v-btn>
                    </v-alert>
                  </div>
                </v-col>

                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="dateRange">
                      <v-icon small class="label-icon">mdi-calendar</v-icon>
                      Date and Time
                    </label>
                    <div class="date-range" @click="datePickerDialog = true">
                      <input type="text" v-model="formattedRange" readonly />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="form-group">
                    <label for="subject">
                      <v-icon small class="label-icon">mdi-grease-pencil</v-icon>
                      Subject
                    </label>
                    <input type="text" id="subject" v-model="reservation.title" maxlength="50" />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="creatorName">
                      <v-icon small class="label-icon">mdi-account</v-icon>
                      Creator Name
                    </label>
                    <input type="text" id="creatorName" v-model="reservation.creatorName" disabled
                      class="highlighted-text" />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="creatorEmail">
                      <v-icon small class="label-icon">mdi-email</v-icon>
                      Creator Email
                    </label>
                    <input type="email" id="creatorEmail" v-model="reservation.creatorEmail" disabled
                      class="highlighted-text" />
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="form-group">
                    <label for="description">
                      <v-icon small class="label-icon">mdi-text</v-icon>
                      Description
                    </label>
                    <v-textarea id="description" v-model="reservation.description" maxlength="80"></v-textarea>
                  </div>
                </v-col>
              </v-row>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog max-width="800" v-model="showExistingReservations">
      <!-- Increase the max-width -->
      <v-card>
        <v-card-title>Existing Reservations</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="reservation in exisingReservations" :key="reservation.id" cols="auto">
              <v-chip>
                {{ moment(reservation.startTime).format('DD-MM-YYYY HH:mm') }} -
                {{
                  reservation.recurrence
                    ? moment(reservation.recurrence.until).format('DD-MM-YYYY HH:mm')
                    : moment(reservation.endTime).format('DD-MM-YYYY HH:mm')
                }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showExistingReservations = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="datePickerDialog" class="date-picker-dialog">
      <div class="date-picker-container">
        <p v-if="isRangeEqual" class="error-message">Start time cannot be equal to end time.</p>
        <DatePicker is-range mode="dateTime" v-model.range="tempRange" :rules="timeRules" :min-date="new Date()"
          v-if="showDatePicker" :max-date="moment().add(59, 'days').toDate()" is24hr :is-dark="true"
          @update:modelValue="validateTimeRange" />

        <div class="date-picker-actions">
          <v-btn @click="discardRange">Cancel</v-btn>
          <v-btn @click="confirmRange" :disabled="isRangeEqual">Confirm</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Loader from '@/components/general/Loader.vue'
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import { useOAuthService } from '@/services/OAuthService'
import { showToast } from '@/services/utils'
import Swal from 'sweetalert2'
import {
  getReservationsByPoi,
  getPoiCategoryById,
  getFloorById,
  getPoiCategories,
  getAppointmentById,
  getBuildings,
  getPoisByCategoryId,
  getPoiById,
  getReservationById,
  createReservation,
  createAppointment,
  updateReservation,
  updateAppointment,
  deleteReservation,
} from '@/controllers/BaseController'

export default {
  name: 'CreateEditBooking',
  components: {
    Loader,
    DatePicker,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const oAuthService = useOAuthService()
    const isBatchReservation = ref(false)
    const isOutlookError = ref(false)
    const selectedPoi = ref({})
    const selectedFloor = ref({})
    const showDatePicker = ref(true)
    const exisingReservations = ref([])
    const showExistingReservations = ref(false)
    const clickedDateRange = ref('')
    let originalReserveration = ref('')

    const onDayClick = day => {
      let start = day.startDate
      let end = day.endDate
      clickedDateRange.value = { start: start, end: end }
    }
    const onTimeUpdate = time => {
      console.log('Time updated:', time)
    }

    const reservation = ref({
      id: '',
      title: '',
      creatorName: '',
      poi: { id: '', name: '' },
      category: { id: '', name: '' },
      floor: { id: '', name: '' },
      creatorEmail: '',
      personCount: 1,
      startTime: new Date(),
      endTime: new Date(),
      description: '',
      range: { start: new Date(), end: moment().add(1, 'hour').toDate() },
    })
    const poiMinTime = ref('00:00')
    const poiMaxTime = ref('23:59')
    let reservationReseponse = {}
    let appointmentResponse = {}

    const showLoader = ref(false)
    const datePickerDialog = ref(false)
    const tempRange = ref({ ...reservation.value.range })

    const disabledDates = ref([
      {
        repeat: {
          weekdays: [1, 7],
        },
      },
    ])
    const hasChanges = computed(() => {
      return JSON.stringify(reservation.value) !== JSON.stringify(originalReserveration.value)
    })

    watch(
      () => reservation.value.title,
      newTitle => {
        canSubmit.value = newTitle.trim() !== ''
      }
    )
    watch(
      () => tempRange.value,
      newRange => {
        const startMoment = moment(newRange.start)
        const endMoment = moment(newRange.end)
        isBatchReservation.value = !startMoment.isSame(endMoment, 'day')
      },
      { deep: true }
    )

    watch(
      () => tempRange.value.start,
      start => {
        console.log(start)
      },
      { deep: true, immediate: true }
    )

    const timeRules = computed(() => {
      const minHour = 6;
      const maxHour = 20;

      return [
        {
          hours: (hour) => hour >= minHour && hour <= maxHour,
          minutes: (minute, { hours }) => {
            if (hours === maxHour) {
              // If the hour is 20, only allow minutes to be 00
              return minute === 0;
            }
            // For other hours, allow 15-minute intervals
            return minute % 15 === 0;
          },
        },
      ];
    });


    const validateTimeRange = async (range) => {
      const start = moment(range.start);
      const end = moment(range.end);

      let adjustedRange = { ...range };

      // Prevent start time from being 20
      if (start.hour() === 20) {
        console.error("Start time cannot be 20.");
        showToast({
          text: "Start time cannot be 20.",
          duration: 3000,
          type: "error"
        });
        adjustedRange.start = start.clone().set({ hour: 19, minute: 45 }).toDate();
        adjustedRange.end = start.clone().add(15, 'minutes').toDate();
      }

      if (end.isSameOrBefore(start)) {
        adjustedRange.end = start.clone().add(15, 'minutes').toDate();
      } else if (start.isSame(end)) {
        adjustedRange.end = start.clone().add(15, 'minutes').toDate();
      }

      if (end.hour() === 20 && end.minute() > 0) {
        adjustedRange.end = end.clone().startOf('hour').toDate();
      }
      showDatePicker.value = false;
      range.start = adjustedRange.start;
      range.end = adjustedRange.end;
      tempRange.value.start = adjustedRange.start;
      tempRange.value.end = adjustedRange.end;
      await nextTick();
      showDatePicker.value = true;
    };



    const isRangeEqual = computed(() => {
      return moment(tempRange.value.start).isSame(tempRange.value.end, 'minute')
    })

    const isNew = computed(() => {
      let params = JSON.parse(JSON.stringify(route.params))
      return !params?.reservation_id
    })
    const isEdit = computed(() => {
      let params = JSON.parse(JSON.stringify(route.params))
      return params?.reservation_id
    })

    const formattedRange = computed(() => {
      const { start, end } = reservation.value.range
      return `${moment(start).format('DD-MM YYYY HH:mm')} - ${moment(end).format('HH:mm DD-MMM YYYY')}`
    })



    const canSubmit = computed(() => {
      const titleValid = reservation.value.title.trim() !== ''
      const requiredFieldsValid = reservation.value.poi && reservation.value.category && reservation.value.floor
      const isEditableValid = isNew.value ? true : hasChanges.value
      const customCondition = exisingReservations.value.length === 0
      return titleValid && requiredFieldsValid && isEditableValid && customCondition
    })
    const submitBooking = async () => {
      try {
        showLoader.value = true
        let originalEndTime

        let reservationsPayload = {
          title: reservation.value.title,
          poiId: reservation.value.poi.id,
          startTime: tempRange.value.start.toISOString(),
          endTime: tempRange.value.end.toISOString(),
          personCount: reservation.value.personCount,
          creatorEmail: reservation.value.creatorEmail,
          creatorName: reservation.value.creatorName,
          poiName: reservation.value.poi.name,
          poiCategory: reservation.value.category.name,
          floorNumber: reservation.value.floor.number,
          floorName: reservation.value.floor.name,
          buildingName: reservation.value.building.name,
        }

        if (isBatchReservation.value) {
          originalEndTime = tempRange.value.end
          let batchEndDate = moment(tempRange.value.start).hour(moment(originalEndTime).hour()).minute(moment(originalEndTime).minute())
          reservationsPayload['endTime'] = batchEndDate.toISOString()

          originalEndTime = moment(originalEndTime).endOf('day')
          let formattedUntilDate = originalEndTime.toISOString().split('.')[0] // Removing microseconds part

          reservationsPayload['recurrence'] = {
            until: formattedUntilDate,
            frequency: 'WEEKDAY',
          }
        }

        reservationReseponse = await createReservation(reservationsPayload)
        let reservationId = reservationReseponse['id']
        let appointmentsPayload = {
          poiId: reservation.value.poi.id,
          title: reservation.value.title,
          moderatorEmail: reservation.value.creatorEmail,
          startTime: tempRange.value.start.toISOString(),
          endTime: tempRange.value.end.toISOString(),
          reservationId: reservationId,
          customerId: reservationReseponse.customerId,
          creatorEmail: reservation.value.creatorEmail,
          organizerName: reservation.value.creatorName,
          location: reservation.value.poi.name,
          campusId: reservation.value.campusId,
        }

        if (isBatchReservation.value) {
          let originalEndTime = tempRange.value.end
          let batchEndDate = moment(tempRange.value.start).hour(moment(originalEndTime).hour()).minute(moment(originalEndTime).minute())
          appointmentsPayload['endTime'] = batchEndDate.toISOString()

          originalEndTime = moment(originalEndTime).endOf('day')
          let formattedUntilDate = originalEndTime.toISOString().split('.')[0] // Removing microseconds part

          appointmentsPayload['recurrence'] = {
            until: formattedUntilDate,
            frequency: 'WEEKDAY',
          }
          appointmentsPayload['reservationRecurringId'] = reservationReseponse['recurringId']
        }

        if (reservation.value.description !== '') {
          appointmentsPayload['description'] = reservation.value.description
        }

        appointmentResponse = await createAppointment(JSON.parse(JSON.stringify(appointmentsPayload)))
          .then(response => response)
          .catch(e => {
            let errorMessage = e?.response?.data?.errors[0]?.message
            if (errorMessage?.includes('resources') && errorMessage?.includes('time range')) {
              isOutlookError.value = true
            }
            throw new Error(errorMessage)
          })
      } catch (error) {
        console.error(error)
        showLoader.value = false
        Swal.fire({
          icon: 'error',
          title: 'Booking Error',
          text: isOutlookError.value
            ? 'The room is occupied for the selected time range.'
            : 'The booking creation was unsuccessful due to server load. Please try again in a few minutes.',
          allowOutsideClick: false,
          confirmButtonColor: '#03A9F4',
        })
      } finally {
        if (reservationReseponse?.id && appointmentResponse?.id) {
          showLoader.value = false
          Swal.fire({
            icon: 'success',
            title: 'Booking Created',
            text: 'The booking has been successfully created.',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          }).then(() => {
            router.push({ name: 'UserBookings' }).catch(() => { })
          })
        } else if (reservationReseponse && !appointmentResponse) {
          try {
            await deleteReservation(reservationReseponse?.id)
          } catch (error) {
            console.error(error)
          } finally {
            showLoader.value = false
            Swal.fire({
              icon: 'error',
              title: 'Booking Error',
              text: 'The booking creation was unsuccessful due to server load. Please try again in a few minutes.',
              allowOutsideClick: false,
              confirmButtonColor: '#03A9F4',
            })
          }
        } else {
          showLoader.value = false
        }
      }
    }

    const editBooking = async () => {
      // showLoader.value = true
      const maxRetries = 2
      let retryCount = 0
      let successful = false
      let RESERVATION = JSON.parse(JSON.stringify(reservation.value))
      let reservationId = RESERVATION.id

      while (retryCount < maxRetries && !successful) {
        try {
          let currentReservation = await getReservationById(reservationId)
          let reservationsPayload = { ...RESERVATION }
          reservationsPayload = {
            title: RESERVATION.title,
            poiId: RESERVATION.poi.id,
            startTime: moment(RESERVATION.range.start).toISOString(),
            endTime: moment(RESERVATION.range.end).toISOString(),
            personCount: RESERVATION.personCount,
          }

          // Update the reservation
          let updateResponse = await updateReservation(reservationId, reservationsPayload)
          console.log('updateResponse', updateResponse)
          reservationId = updateResponse.id // Assuming the updated reservation ID is returned
          successful = true

          // Prepare payload for updating appointment
          let APPOINTMENT = await getAppointmentById(currentReservation.appointment.id)
          let appointmentsPayload = { ...APPOINTMENT }
          appointmentsPayload = {
            title: RESERVATION.title,
            startTime: moment(RESERVATION.range.start).toISOString(),
            endTime: moment(RESERVATION.range.end).toISOString(),
            reservationId: reservationId,
            description: RESERVATION.description,
            invitees: RESERVATION.invitees || [],
          }

          // Update the appointment
          console.log('Updating appointment', appointmentsPayload)
          await updateAppointment(APPOINTMENT?.id, appointmentsPayload)

          Swal.fire({
            icon: 'success',
            text: 'Booking updated successfully.',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          }).then(() => {
            router.push({ name: 'UserBookings' })
          })
        } catch (error) {
          console.error(error)
          if (error.response?.data?.errors.some(e => e.message.includes('ERR_VERSION_CONFLICT'))) {
            retryCount++
            if (retryCount >= maxRetries) {
              Swal.fire({
                icon: 'error',
                title: 'Error updating booking',
                text: 'There was an error updating the booking.',
                allowOutsideClick: false,
                confirmButtonColor: '#03A9F4',
              })
              break
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error updating booking',
              text: 'There was an error updating the booking.',
              allowOutsideClick: false,
              confirmButtonColor: '#03A9F4',
            })
            break
          }
        }
      }

      showLoader.value = false
    }

    const goBack = () => {
      router.go(-1)
    }

    const discardRange = () => {
      datePickerDialog.value = false
    }

    const confirmRange = async () => {
      let clickDateRange = JSON.parse(JSON.stringify(clickedDateRange.value))
      if (clickDateRange !== '') {
        const clickedEndDate = moment(clickDateRange.end).startOf('day')

        if (!clickedEndDate.isSame(moment(tempRange.value.end).startOf('day'))) {
          tempRange.value.start = moment(tempRange.value.start)
            .set({ year: clickedEndDate.year(), month: clickedEndDate.month(), date: clickedEndDate.date() })
            .toDate()

          tempRange.value.end = moment(tempRange.value.end)
            .set({ year: clickedEndDate.year(), month: clickedEndDate.month(), date: clickedEndDate.date() })
            .toDate()
        }
      }

      reservation.value.range = { ...tempRange.value }
      datePickerDialog.value = false
      let reservations = await checkReservations()
      exisingReservations.value = []
      exisingReservations.value = reservations
      if (reservations.length > 0) {
        canSubmit.value = false
      } else {
        canSubmit.value = true
      }
    }

    const checkReservations = async () => {
      try {
        showLoader.value = true
        let start = moment(reservation.value.range.start).toDate()
        let end = moment(reservation.value.range.end).toDate()
        let poiId = reservation.value.poi.id
        let reservations = await getReservationsByPoi(poiId, start, end)
        return reservations
      } catch (error) {
        console.error(error)
      } finally {
        showLoader.value = false
      }
    }

    const chatbotReservation = (params) => {
      console.log('Chatbot reservation')

    }

    onMounted(async () => {
      try {
        showLoader.value = true
        let user = await oAuthService.getUserProfile()
        reservation.value.creatorName = user.name ? user?.name : user?.displayName
        reservation.value.creatorEmail = user.email
        let params = JSON.parse(JSON.stringify(route.params))
        console.log('Params:', params)
        let poiId = params?.poi_id
        let categoryId = params?.category_id
        let buildingId = params?.building_id
        let buildings = await getBuildings()
        let building = buildings.find(building => building.id === buildingId)
        let campusId = building?.campus?.id
        reservation.value.campusId = campusId

        let poi = await getPoiById(poiId)
        console.log('POI:', poi)
        selectedPoi.value = poi
        let category = await getPoiCategoryById(categoryId)
        let floorId = poi?.floors?.ids[0]
        let floor = await getFloorById(floorId)
        selectedFloor.value = floor
        reservation.value.poi = { id: poiId, name: poi?.name }
        reservation.value.category = {
          id: categoryId,
          name: category?.name,
        }
        reservation.value.building = { id: buildingId, name: building?.name }
        reservation.value.floor = { id: floorId, name: floor?.name }
        reservation.value.title = poi?.name + ' Reservation'
        if (isNew.value === true) {
          let isReservations = await checkReservations()
          exisingReservations.value = []
          exisingReservations.value = isReservations
          if (isReservations.length > 0) {
            canSubmit.value = false
          } else {
            canSubmit.value = true
          }
        } else {
          console.log('IS EDIT')
          canSubmit.value = false
          let userBooking = await getReservationById(route.params.reservation_id)
          let appointmentId = userBooking?.appointment?.id
          if (appointmentId) {
            let appointment = await getAppointmentById(appointmentId)
            reservation.value.title = appointment.title
            reservation.value.description = appointment.description
          }
          reservation.value.id = userBooking.id
          reservation.value.personCount = userBooking.personCount
          let start = moment(userBooking.startTime).toDate()
          let end = moment(userBooking.endTime).toDate()
          reservation.value.range = { start: start, end: end }
          tempRange.value = { ...reservation.value.range }
          originalReserveration.value = { ...reservation.value }
        }
      } catch (error) {
        console.error(error)
      } finally {
        showLoader.value = false
      }
    })

    return {
      reservation,
      showLoader,
      goBack,
      formattedRange,
      datePickerDialog,
      tempRange,
      discardRange,
      confirmRange,
      moment,
      isNew,
      isEdit,
      canSubmit,
      submitBooking,
      editBooking,
      disabledDates,
      timeRules,
      isRangeEqual,
      selectedFloor,
      selectedPoi,
      exisingReservations,
      showExistingReservations,
      onDayClick,
      onTimeUpdate,
      clickedDateRange,
      originalReserveration,
      chatbotReservation,
      validateTimeRange,
      showDatePicker

    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.create-manage-booking {
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

@supports (-webkit-touch-callout: none) {
  .custom-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.form-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: calc(100vh - 56px);
  /* Adjust for toolbar height */
  box-sizing: border-box;
  overflow-y: auto;
}

.form-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-icon {
  font-size: 24px;
  cursor: pointer;
}

.submit-button {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-button:hover {
  background: #218838;
}

.submit-button-green {
  background: #28a745;
}

.submit-button-green:hover {
  background: #218838;
}

.submit-button-red {
  background: #ff5252;
}

.submit-button-red:hover {
  background: #e04747;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #333;
}

.label-icon {
  margin-right: 8px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #80bdff;
  outline: none;
}

.form-group input[disabled],
.form-group textarea[disabled] {
  font-weight: bold;
  color: #333;
  background-color: #f9f9f9;
}

.date-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.date-range input {
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.date-picker-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-picker-container {
  background: #0f172a;
  padding: 20px;
  border-radius: 10px;
}

.date-picker-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.date-picker-actions button {
  padding: 4px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.date-picker-actions button:first-child {
  background: #ff5252;
  color: #fff;
}

.date-picker-actions button:first-child:hover {
  background: #e04747;
}

.date-picker-actions button:last-child {
  background: darkcyan;
  color: #fff;
}

.date-picker-actions button:last-child:hover {
  background: #006f6f;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px;
  }

  .form-card {
    padding: 10px;
  }

  .submit-button {
    margin-right: 10px;
  }

  .v-toolbar-title {
    font-size: 18px;
  }
}
</style>
<!--
CreateEditBooking Component

This component allows users to create or edit a booking. The interface is designed to be user-friendly and provides various functionalities to ensure a smooth booking experience.

Toolbar
The toolbar at the top provides navigation and action buttons. The back icon allows users to navigate to the previous page. The title dynamically changes to "Create Booking" or "Edit Booking" based on the context. The submit button is enabled only when all required fields are filled and there are no conflicting reservations. The button color changes to green when enabled and red when disabled.

Loader
A loader component is displayed when the application is processing data, providing visual feedback to the user.

Form
The form contains several input fields:
- Location: Displays the selected location and is disabled for editing.
- Category: Displays the category of the location and is disabled for editing.
- Floor: Displays the floor of the location and is disabled for editing.
- Date and Time: Allows users to select a date and time range for the booking. Clicking this field opens a date picker dialog.
- Subject: Allows users to enter a title for the booking.
- Creator Name: Displays the name of the booking creator and is disabled for editing.
- Creator Email: Displays the email of the booking creator and is disabled for editing.
- Description: Allows users to enter a description for the booking.

Existing Reservations Alert
If there are existing reservations for the selected time range, an alert is displayed with a button to show the existing reservations.

Date Picker Dialog
The date picker dialog allows users to select a date and time range. It provides visual feedback if the start time is equal to the end time. Users can confirm or cancel their selection.

Existing Reservations Dialog
This dialog displays a list of existing reservations for the selected time range. Users can close the dialog by clicking the close button.

Visual Feedback
The component uses Swal for displaying alerts. Success alerts are shown when a booking is successfully created or updated. Error alerts are shown if there is an issue with the booking process, such as server load or conflicting reservations.

Overall, this component provides a comprehensive interface for managing bookings, ensuring users can easily create or edit bookings while receiving appropriate feedback and guidance throughout the process.
-->
