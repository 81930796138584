<template>
    <v-col class="landing-page-mobile">
        <v-row>
            <v-card class="mx-auto" elevation="0" :image="landingpage_mobile" width="100%">
                <v-card-text>
                    <v-row>
                        <img :src="pia_logo_white" alt="Logo"
                            style="height: 40px; margin-right: 10px; position: absolute; top: 10px; left: 15px" />
                    </v-row>
                    <v-row>

                    </v-row>
                    <v-row justify="end">
                        <v-icon class="mt-3 mr-4" color="white" @click="doLogout">mdi-logout</v-icon>
                    </v-row>
                    <p class="text-h5 text-left mt-10"
                        style="color: white; font-weight: bold; font-family: 'Inter', sans-serif;">
                        Hi, {{ userProfile.name }}
                    </p>
                    <p class="text-left mt-1 ml-1" style="color: white; font-weight: bold;font-size: 13px;">
                        {{ moment(date).format('ddd, DD MMMM YYYY') }}
                    </p>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                    <AnimatedButton />
                </v-card-actions>
                <v-col class="mt-6">
                    <v-card class="mx-auto" elevation="4" width="100%"
                        style="background-color: rgba(255, 255, 255, 0.1); backdrop-filter: blur(8px); align-self: center;">
                        <v-card-text>

                            <v-row>
                                <v-col cols="6" sm="4" md="6">
                                    <div class="text-left" style="color: white; margin-bottom: 10px;">
                                        <v-btn prepend-icon="mdi-map-marker" variant="tonal">
                                            {{ 'Bamberg' }}
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4" md="6">
                                    <div class="text-right" style="color: white; margin-bottom: 10px;">
                                        <v-btn prepend-icon="mdi-clock-time-nine" variant="tonal">
                                            {{ currentTime }}
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider class="mt-2" :thickness="3" color="white"></v-divider>
                            <!-- Weather Information -->

                            <v-row>
                                <!-- Temperature -->
                                <v-col cols="6" sm="4" md="6" class="d-flex justify-center align-center">
                                    <div class="weather-item">
                                        <div class="icon-box" style="margin-left: -1rem;">
                                            <v-icon color="white" class="icon">mdi-thermometer</v-icon>
                                        </div>
                                        <div class="weather-text">
                                            <p class="temperature">{{ weatherTemp }}°C</p>
                                            <p class="description">{{ weatherDesc }}</p>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Humidity -->
                                <v-col cols="6" sm="4" md="6" class="d-flex justify-center align-center">
                                    <div class="weather-item">
                                        <div class="icon-box" style="margin-left: -1rem;">
                                            <v-icon color="white" class="icon">mdi-water-percent</v-icon>
                                        </div>
                                        <div class="weather-text">
                                            <p class="humidity">{{ weatherHumidity }}%</p>
                                            <p class="description">Humidity</p>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <!-- Wind Speed -->
                                <v-col cols="6" sm="4" md="6" class="d-flex justify-center align-center">
                                    <div class="weather-item">
                                        <div class="icon-box">
                                            <v-icon color="white" class="icon">mdi-weather-windy</v-icon>
                                        </div>
                                        <div class="weather-text">
                                            <p class="wind-speed">{{ weatherWind }} m/s</p>
                                            <p class="description">Wind Speed</p>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Rain Chance -->
                                <v-col cols="6" sm="4" md="6" class="d-flex justify-center align-center">
                                    <div class="weather-item">
                                        <div class="icon-box">
                                            <v-icon color="white" class="icon">mdi-weather-rainy</v-icon>
                                        </div>
                                        <div class="weather-text">
                                            <p class="rain-chance">{{ weatherRain }}%</p>
                                            <p class="description">Rain Chance</p>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-card>
            <v-row class="mt-4" no-gutters>
                <!-- Desk Available Card -->
                <v-col cols="12" sm="6" md="3" class="d-flex justify-center align-center">
                    <div class="custom-card">
                        <div class="card-icon">
                            <v-icon class="icon">mdi-desktop-mac</v-icon>
                        </div>
                        <div class="card-text">
                            <p class="card-title">Desks Available</p>
                            <p class="card-description">5 desks free</p>
                        </div>
                    </div>
                </v-col>

                <!-- Colleagues Present Card -->
                <v-col cols="12" sm="6" md="3" class="d-flex justify-center align-center">
                    <div class="custom-card">
                        <div class="card-icon">
                            <v-icon class="icon">mdi-account-group</v-icon>
                        </div>
                        <div class="card-text">
                            <p class="card-title">Colleagues Present</p>
                            <p class="card-description">12 present today</p>
                        </div>
                    </div>
                </v-col>

                <!-- Your Attendance Card -->
                <v-col cols="12" sm="6" md="3" class="d-flex justify-center align-center">
                    <div class="custom-card">
                        <div class="card-icon">
                            <v-icon class="icon">mdi-calendar-check</v-icon>
                        </div>
                        <div class="card-text">
                            <p class="card-title">My Attendance</p>
                            <p class="card-description">8 days this month</p>
                        </div>
                    </div>
                </v-col>

                <!-- Quick Action Card -->
                <v-col cols="12" sm="6" md="3" class="d-flex justify-center align-center">
                    <div class="custom-card">
                        <div class="card-icon">
                            <v-icon style="color: #4578f4" class="icon">mdi-flash-outline</v-icon>
                        </div>
                        <div class="card-text">
                            <p class="card-title" style="color: #4578f4">Quick Action</p>
                            <p class="card-description">Book a desk or chat
                                <span style="color: #4578f4; cursor: pointer; font-weight: bold">&nbsp;></span>
                            </p>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div class="footer-container">
                <p class="footer-text">© 2024 Pinestack. All rights reserved.</p>
                <div class="footer-links">
                    <a href="https://pinestack.io/en/contact/" class="footer-link" target="_blank">Contact Us</a>
                    <a href="https://pinestack.io/en/privacy-policy/" class="footer-link" target="_blank">Privacy
                        Policy</a>
                </div>
            </div>

        </v-row>
    </v-col>

</template>

<script>
//import AnalogClock from '@/components/general/ChildComponents/AnalogClock.vue'
import AnimatedButton from '@/components/general/ChildComponents/AnimatedButton.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useOAuthService } from '@/services/OAuthService'
import moment from 'moment'
import pia_logo_white from '@/assets/Branding/Pia/pia_logo_white.png'
import { doLogout } from '@/controllers/BackboneAPI'
import landingpage_mobile from '@/assets/General/landingpage_mobile.png'

export default defineComponent({
    name: 'LandingPageDesktop',
    components: { AnimatedButton },
    setup() {
        const availableDesks = ref(0)
        const isMobileView = ref(window.innerWidth <= 480)
        const showCards = ref(false) // Control card animation
        const colleaguesPresent = ref(0)
        const attendanceCount = ref(0)
        const weatherTemp = ref(0)
        const weatherDesc = ref('')
        const weatherHumidity = ref(0)
        const weatherWind = ref(0)
        const weatherRain = ref(0)
        const userProfile = ref({ name: 'User' })
        const apiKey = '4ae793a3dd93a074ff16d7e97b06ec5b'
        const city = 'Munich'
        const currentTime = ref(moment().format('HH:mm:ss'))

        const buttons = ref([
            { icon: '🖥️', title: 'Desks Available', description: `${availableDesks.value} desks free` },
            { icon: '👥', title: 'Colleagues Present', description: `${colleaguesPresent.value} present today` },
            { icon: '📅', title: 'Your Attendance', description: `${attendanceCount.value} days this month` },
            { icon: '⚡', title: 'Quick Action', description: 'Book a desk or chat' },
        ])

        const handleResize = () => {
            isMobileView.value = window.innerWidth <= 480
        }

        window.addEventListener('resize', handleResize)

        async function fetchHomeData() {
            try {
                availableDesks.value = 5
                colleaguesPresent.value = 12
                attendanceCount.value = 8
            } catch (error) {
                console.log(error)
            }
        }

        onMounted(async () => {
            try {
                userProfile.value = await useOAuthService().getUserProfile()
                await fetchWeather()
                await fetchHomeData()
            } catch (error) {
                console.log(error)
                showCards.value = true
            } finally {
                showCards.value = true
            }
        })

        async function fetchWeather() {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`)
            const data = await response.json()
            weatherTemp.value = data.main.temp.toFixed(1)
            weatherDesc.value = data.weather[0].description
            weatherHumidity.value = data.main.humidity
            weatherWind.value = data.wind.speed
            weatherRain.value = data.rain ? data.rain['1h'] : 0
        }

        setInterval(() => {
            currentTime.value = moment().format('HH:mm:ss')
        }, 1000)

        return {
            availableDesks,
            colleaguesPresent,
            attendanceCount,
            weatherTemp,
            weatherDesc,
            weatherHumidity,
            weatherWind,
            weatherRain,
            userProfile,
            landingpage_mobile,
            buttons,
            isMobileView,
            moment,
            showCards,
            fetchWeather,
            currentTime,
            pia_logo_white,
            doLogout,
        }
    },
})
</script>

<style scoped>
.landing-page-mobile {
    background-color: #f8f8f8;
}

.weather-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.icon {
    font-size: 24px;
}

.weather-text {
    margin-left: 10px;
    text-align: left;
}

.temperature,
.humidity,
.wind-speed,
.rain-chance {
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.description {
    color: white;
    font-size: 12px;
    margin: 0;
}

.custom-card {
    background-color: white;
    backdrop-filter: blur(8px);
    border-radius: 8px;
    width: 100%;
    max-width: 25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: row;
    /* Horizontal layout */
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin: 10px;
}

.card-icon {
    background-color: #ededed;
    border-radius: 8px;
    padding: 10px;
    margin-right: 10px;
    /* Spacing between icon and text */
}

.card-text {
    color: black;
}

.card-title {
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
    color: #2c3e50;
}

.card-description {
    font-size: 13px;
    margin: 0;
    color: #7f8c8d;
}

.icon {
    font-size: 30px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3px;
    background-color: #f0f0f0;
    color: #718096;
    font-size: 0.7rem;
    border-top: 1px solid #e0e0e0;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.footer-text {
    margin: 0;
}

.footer-links {
    display: flex;
    gap: 10px;
}

.footer-link {
    color: #718096;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}


/* Media Queries for responsiveness */

@media (max-width: 440px) {
    .icon-box {
        width: 45px;
        height: 45px;
    }

    .icon {
        font-size: 20px;
    }

    .temperature,
    .humidity,
    .wind-speed,
    .rain-chance {
        font-size: 14px;
    }

    .description {
        font-size: 10px;
    }

    .custom-card {
        padding: 15px;
    }

    .icon {
        font-size: 24px;
    }

    .card-title {
        font-size: 16px;
    }

    .card-description {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .icon-box {
        width: 40px;
        height: 40px;
    }

    .icon {
        font-size: 18px;
    }

    .temperature,
    .humidity,
    .wind-speed,
    .rain-chance {
        font-size: 14px;
    }

    .description {
        font-size: 10px;
    }

    .custom-card {
        padding: 12px;
    }

    .icon {
        font-size: 20px;
    }

    .card-title {
        font-size: 14px;
    }

    .card-description {
        font-size: 10px;
    }

}
</style>
