<template>
    <div class="create-edit-user">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'User Details' }}
            </v-toolbar-title>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser"
                color="black">Abort</v-btn>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser" color="black">{{ isEdit
                ?
                'Update' : 'Create' }}</v-btn>
        </v-toolbar>

        <!-- User Information Section -->
        <div class="user-section">
            <h2>User Information</h2>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field label="First Name" v-model="user.firstName" variant="outlined" />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field label="Last Name" v-model="user.lastName" variant="outlined" />
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Email" v-model="user.email" variant="outlined" />
                </v-col>
                <v-col cols="12">
                    <v-textarea label="Description" v-model="user.description" variant="outlined" />
                </v-col>
            </v-row>
        </div>

        <!-- Location Section -->
        <div class="location-section">
            <h2>Campus</h2>
            <v-card-text class="tile-description">
                Assign the user the campus where his or her primary workstation is located.
            </v-card-text>
            <v-select :items="locations" label="Campus" v-model="user.location" variant="outlined" />
        </div>

        <!-- Activity Section -->

        <div class="activity-section">
            <h2>Activity</h2>
            <v-card-text class="tile-description">
                A user's activity can be limited to a certain period of time. This affects whether the user can log into
                the user interfaces and apps or not.
            </v-card-text>
            <v-switch label="Active" v-model="user.isActive" inset
                :class="user.isActive ? 'active-switch' : ''"></v-switch>
            <v-row>
                <v-col cols="12" md="5">
                    <v-text-field label="Active From" v-model="user.activeFrom" type="date" variant="outlined"
                        :disabled="!user.isActive" prepend-icon="mdi-calendar" @focus="$event.target.showPicker()" />
                </v-col>
                <v-col cols="12" md="5">
                    <v-text-field label="Active Until" v-model="user.activeUntil" type="date" variant="outlined"
                        :disabled="!user.isActive" prepend-icon="mdi-calendar" @focus="$event.target.showPicker()" />
                </v-col>

                <v-col cols="12" md="2">
                    <v-btn color="white" class="button" @click="resetActiveUntil">Reset</v-btn>
                </v-col>
            </v-row>
        </div>


        <!-- Roles and Groups Section -->
        <div class="roles-groups-section">
            <h2>Roles</h2>
            <v-card-text class="tile-description">
                Use roles to precisely manage a user's software permissions.
            </v-card-text>
            <v-autocomplete clearable chips label="Select Roles" :items="roles" multiple
                variant="outlined"></v-autocomplete>
            <!-- <v-chip-group v-model="user.roles" column>
                <v-chip v-for="role in roles" :key="role" outlined>
                    {{ role }}
                </v-chip>
            </v-chip-group> -->
            <!-- <v-btn outlined color="primary" @click="addRole">Add Role</v-btn> -->

            <!-- <h2>Groups</h2>
            <v-chip-group v-model="user.groups" column>
                <v-chip v-for="group in groups" :key="group" outlined>
                    {{ group }}
                </v-chip>
            </v-chip-group>
            <v-btn outlined color="primary" @click="addGroup">Add Group</v-btn> -->
        </div>

    </div>
</template>

<script>
import { ref, defineComponent, onMounted, reactive, computed } from 'vue';
import { debounce } from 'lodash';
import moment from 'moment';
import lodash from 'lodash';
import Loader from '@/components/general/Loader.vue';
import { useRoute } from 'vue-router';
import { getRoles } from '@/controllers/BackboneAPI';

import { getStoredData, getCampuses, getCampusById, isStaingOrQa } from '@/controllers/BaseController';



export default defineComponent({
    name: 'CreateEditUser',
    components: {
        Loader
    },
    setup() {
        const showLoader = ref(true);


        const role = ref({
            firstName: '',
            lastName: '',
            email: '',
            description: '',
            location: '',
            isActive: false,
            activeFrom: new Date(),
            activeUntil: null,
            roles: [],
            groups: [],
        });
        const locations = ref(['Location 1', 'Location 2', 'Test Location']);
        const roles = ref(['Employee', 'Admin', 'Test Role']);
        const originalRoles = ref([]);
        const groups = ref(['Group 1', 'Group 2', 'Test Group']);
        const originalGroups = ref([]);
        const campuses = ref([]);
        const route = useRoute();
        const isNew = computed(() => !route.params.id);
        const isEdit = computed(() => !!route.params.id);

        const goBack = () => {
            window.history.back();
        };

        const addRole = () => {

        };

        const addGroup = () => {

        };

        const updateUser = () => {

        };
        const user = reactive({
            isActive: false,
            activeFrom: moment(new Date()).format('YYYY-MM-DD'),
            activeUntil: null,
        });

        const datePickers = reactive({
            activeFrom: false,
            activeUntil: false,
        });
        // Method to reset "Active Until"
        const resetActiveUntil = () => {
            user.activeUntil = null;
        };
        const allowedDates = computed(() => {
            return (date) => {
                const today = moment().startOf('day'); // Today's date at midnight
                const selected = moment(date, 'YYYY-MM-DD').startOf('day'); // Selected date at midnight

                return selected.isSameOrAfter(today);
            };
        });


        onMounted(async () => {
            try {
                showLoader.value = false;
                let CAMPUSES = lodash.chain(await getCampuses())
                    .filter({ active: true })
                    .orderBy('name', 'asc')
                    .value();
                if (await isStaingOrQa()) {
                    CAMPUSES = lodash.filter(CAMPUSES, (campus) => campus.name?.toLowerCase() !== 'hamburg');
                }
                campuses.value = CAMPUSES;

                locations.value = CAMPUSES.map(campus => campus.name);
                let ROLES = await getRoles();
                ROLES = lodash.chain(ROLES)
                    .orderBy('name', 'asc')
                    .value();
                console.log(ROLES)
                roles.value = ROLES.map(role => role.name);

                if (isEdit.value) {
                    let USER = await getStoredData(route.params.id);
                    console.log(USER)
                    user.value = { ...USER };
                    user.value.location = USER.campusName;

                }
            } catch (error) {
                console.error('Failed to load data:', error);
            } finally {
                showLoader.value = false;
            }
        });

        return {
            showLoader,
            role,
            user,
            locations,
            roles,
            groups,
            allowedDates,
            goBack,
            addRole,
            addGroup,
            updateUser,
            route,
            isNew,
            isEdit,
            datePickers,
            resetActiveUntil,

        };
    },
});
</script>

<style scoped>
::v-deep(input[type="date"]::-webkit-calendar-picker-indicator) {
    display: none;
    -webkit-appearance: none;
}

/* Optional: Adjust padding for better alignment after hiding the native icon */
::v-deep(input[type="date"]) {
    padding-right: 10px;
}

.create-edit-user {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

@supports (-webkit-touch-callout: none) {
    .custom-toolbar {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}

.user-section,
.location-section,
.activity-section,
.roles-groups-section {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
}

.actions {
    margin-top: 2rem;
}

h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: #333;
}

.active-switch {
    color: white;
    background-color: green !important;
}

.clickable-text-field {
    cursor: pointer;
}

.tile-description {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: #666;
}

.button {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.button.white {
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
}

.button.white:hover {
    background-color: #f0f0f0;
}


@media screen and (max-width: 768px) {
    h2 {
        font-size: 1.15rem;
    }

    .create-edit-user {
        padding: 0.5rem;
    }
}
</style>