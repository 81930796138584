import mqtt from 'mqtt'
import { loadConfig } from '@/configLoader'

class MqttService {
  constructor() {
    this.client = null
    this.isConnected = false
    this.reconnectAttempts = 0
    this.maxReconnectAttempts = 5
    this.reconnectDelay = 5000
    this.shouldReconnect = true
  }

  getClient() {
    if (this.isConnected && this.client) {
      return this.client
    } else {
      console.error('MQTT client is not connected.')
      return null
    }
  }

  async connect() {
    try {
      const config = await loadConfig()
      return new Promise((resolve, reject) => {
        const { host, userName, password } = config.mqtt
        this.client = mqtt.connect(host, {
          username: userName,
          password: password,
          rejectUnauthorized: false,
          clientId: `mqtt_${Math.random().toString(16).substr(2, 8)}`,
          clean: true,
          keepalive: 60,
          reconnectPeriod: 0, // Disable automatic reconnect, handle it manually
        })

        this.client.on('connect', () => {
          this.isConnected = true
          this.reconnectAttempts = 0
          console.log('Connected to MQTT broker')
          resolve()
        })

        this.client.on('error', err => {
          this.isConnected = false
          console.error('Connection error:', err)
          reject(err)
        })

        this.client.on('close', () => {
          this.isConnected = false
          console.log('MQTT connection closed')
          if (this.shouldReconnect) {
            this.handleReconnect()
          }
        })

        this.client.on('offline', () => {
          console.warn('MQTT client went offline')
        })

        this.client.on('reconnect', () => {
          console.log('Attempting to reconnect...')
        })
      })
    } catch (error) {
      console.error('Error loading configuration:', error)
    }
  }

  subscribe(topic, callback) {
    if (this.isConnected) {
      this.client.subscribe(topic, err => {
        if (err) {
          console.error(`Subscription error: ${err}`)
        } else {
          console.log(`Subscribed to topic: ${topic}`)
        }
      })

      this.client.on('message', (msgTopic, message) => {
        if (msgTopic === topic) {
          //console.log(`Received message on topic ${msgTopic}: ${message.toString()}`)
          if (typeof callback === 'function') {
            callback(message.toString()) // Call the provided callback
          } else {
            console.warn('No valid callback function provided for subscription.')
          }
        }
      })
    } else {
      console.error('Client is not connected.')
    }
  }

  unsubscribe(topic) {
    if (this.isConnected) {
      this.client.unsubscribe(topic, err => {
        if (err) {
          console.error(`Unsubscribe error: ${err}`)
        } else {
          console.log(`Unsubscribed from topic: ${topic}`)
        }
      })
    } else {
      console.error('Client is not connected.')
    }
  }

  publish(topic, message) {
    if (this.isConnected) {
      this.client.publish(topic, message, err => {
        if (err) {
          console.error(`Publish error: ${err}`)
        } else {
          console.log(`Message published to topic ${topic}`)
        }
      })
    } else {
      console.error('Client is not connected.')
    }
  }

  disconnect(force = false) {
    if (this.client) {
      this.shouldReconnect = false // Disable reconnection
      this.client.end(force, () => {
        this.isConnected = false
        console.log('Disconnected from MQTT broker')
      })
    }
  }

  isConnected() {
    return this.isConnected
  }

  handleReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++
      console.log(`Reconnect attempt ${this.reconnectAttempts} of ${this.maxReconnectAttempts}`)
      setTimeout(() => {
        this.connect().catch(err => {
          console.error('Reconnect failed:', err)
        })
      }, this.reconnectDelay)
    } else {
      console.error('Max reconnect attempts reached. Giving up.')
    }
  }
}

export default new MqttService()
