// src/router/routerHelper.js
import { getCustomer } from '@/assets/Branding/branding.js'

export async function getBookingPoisListComponent() {
  const customer = await getCustomer()
  if (customer === 'pia') {
    return import('@/components/CustomerComponents/general/BookingPoisList.vue')
  } else if (customer === 'hammerbrooklyn') {
    return import('@/components/CustomerComponents/general/BookingPoisList.vue')
  }
  return import('@/components/general/BookingPoisList.vue')
}

export async function getLandingPageComponent() {
  const customer = await getCustomer()
  if (customer === 'pia') {
    return import('@/views/general/HomeView.vue')
  } else {
    return import('@/views/general/BuildingView.vue')
  }
}
export async function getHomeViewPath() {
  const customer = await getCustomer()
  if (customer === 'pia') {
    return '/home'
  } else {
    return '/building'
  }
}
